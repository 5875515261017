import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-booking-confirmed',
  templateUrl: './booking-confirmed.component.html',
  styleUrls: ['./booking-confirmed.component.scss']
})
export class BookingConfirmedComponent implements OnInit {
  selectedDrAndTimeSlotInfo: any = [];
  constructor(
    @Inject(MAT_DIALOG_DATA) public reqData: any,
    public dialogRef: MatDialogRef<BookingConfirmedComponent>,
  ) { }

  ngOnInit() {
    let timeslots = <string>  localStorage.getItem('selectedDrAndTimeSlotInfo');
    this.selectedDrAndTimeSlotInfo = JSON.parse(timeslots);
    console.log(this.selectedDrAndTimeSlotInfo);
  }
  onClickBackToHome(){
    this.dialogRef.close({status: 1});
  }
}
