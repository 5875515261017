import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dr-edit-timeslot-confirmed',
  templateUrl: './dr-edit-timeslot-confirmed.component.html',
  styleUrls: ['./dr-edit-timeslot-confirmed.component.scss']
})
export class DrEditTimeslotConfirmedComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public reqData: any,
    public dialogRef: MatDialogRef<DrEditTimeslotConfirmedComponent>
  ) { }

  ngOnInit() {
  }
  closeDialog(){
    this.dialogRef.close({status: 1});
    window.location.reload();
  }

}
