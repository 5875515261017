<div class="header">
    OMA Doctors
</div>

<div class="body-content">
    <mat-card>
        <div fxLayout="row" fxLayoutAlign="start center">
            <!-- <section class="example-section" fxFlex="30">
                <mat-checkbox class="example-margin" color="primary" (click)="userFilterCheck('Doctors')"
                    [(ngModel)]="Doctors">Doctors</mat-checkbox>
                <mat-checkbox class="example-margin" color="primary" (click)="userFilterCheck('Patients')"
                    [(ngModel)]="Patients">Patients</mat-checkbox>
            </section> -->
            <section fxLayout="row" fxFlex fxLayoutAlign="space-between center">
                <button mat-raised-button (click)="AddNew()" color="primary">
                    <mat-icon>person_add</mat-icon> Add
                </button>
            </section>
        </div>
        <div class="row">
            <mat-form-field>
                <mat-label>Search in Users</mat-label>
                <input matInput (keyup)="applyFilter($event)" placeholder="search" #input>
            </mat-form-field>
            <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
                <!--- Note that these columns can be defined in any order.
                      The actual rendered columns are set as a property on the row definition" -->
                <!-- Position Column -->
                <ng-container matColumnDef="id">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> # </th>
                    <td mat-cell *matCellDef="let element; let i = index;"> {{i+1}} </td>
                </ng-container>

                <!-- Position Column -->
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                    <td mat-cell *matCellDef="let element"> {{element.full_name}} </td>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="email">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Email Id </th>
                    <td mat-cell *matCellDef="let element"> {{element.email_id}} </td>
                </ng-container>

                <!-- Weight Column -->
                <ng-container matColumnDef="mobile">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Mobile No </th>
                    <td mat-cell *matCellDef="let element"> {{element.mobile_no}} </td>
                </ng-container>

                <!-- Symbol Column -->
                <ng-container matColumnDef="gender">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Gender </th>
                    <td mat-cell *matCellDef="let element"> {{element.gender}} </td>
                </ng-container>

                <!-- user_category_id -->
                <ng-container matColumnDef="category">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> User Category </th>
                    <td mat-cell *matCellDef="let element">
                        <span *ngIf="element.user_category_id==1">Admin</span>
                        <span *ngIf="element.user_category_id==2">Doctor</span>
                        <span *ngIf="element.user_category_id==3">Patient</span>
                    </td>
                </ng-container>
                <ng-container matColumnDef="add_to_partner">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Add To Partner </th>
                    <td mat-cell *matCellDef="let element">
                        <button mat-mini-fab color="primary" title="Edit" (click)="addToPartnerModal(element.id)"
                            aria-label="Example icon button with a menu icon">
                            <mat-icon>add</mat-icon>
                        </button>
                    </td>
                </ng-container>
                <ng-container matColumnDef="edit">
                    <th mat-header-cell *matHeaderCellDef> Action </th>
                    <td mat-cell *matCellDef="let element">
                        <button mat-mini-fab color="primary" title="Edit" (click)="EditUserModal(element.id)"
                            aria-label="Example icon button with a menu icon">
                            <mat-icon>edit</mat-icon>
                        </button>
                        <button mat-mini-fab color="primary" title="Edit"  (click)="ViewUserModal(element.id)"
                        
                            aria-label="Example icon button with a menu icon">
                            <mat-icon>edit</mat-icon>
                        </button>
                        <button mat-mini-fab color="warn" title="Edit"
                            aria-label="Example icon button with a menu icon">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </td>
                </ng-container>
                <!--  -->
                <ng-container matColumnDef="active">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Active </th>
                    <td mat-cell *matCellDef="let element">
                        <mat-slide-toggle color="warn" [disabled]="true" [checked]="element.status"></mat-slide-toggle>
                        <!-- <mat-slide-toggle color="warn" [disabled]="true" *ngIf="element.status!=1"></mat-slide-toggle> -->
                    </td>
                </ng-container>
                <!--  -->
                <!--  -->
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                <!-- Row shown when there is no matching data. -->
                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
                </tr>
            </table>
            <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
        </div>
    </mat-card>
</div>