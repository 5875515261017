import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DoctorService } from 'src/app/services/doctor/doctor.service';


@Component({
  selector: 'app-add-dr-to-partner',
  templateUrl: './add-dr-to-partner.component.html',
  styleUrls: ['./add-dr-to-partner.component.scss']
})
export class AddDrToPartnerComponent implements OnInit {
  partnerAccessTokenLoadedFlag = false;
  partnerAccessTokenErrorMsg = "";
  loaderAccessToken = false;
  //
  addPartnerLoadedFlag = false;
  addPartnerErrorMsg = "";
  loaderAddtoPartner = false;
  // 
  drData = {
    user_id: '',
    CompanyLegalName:"",
    UserEmail:"",
    UserName:"",
    CompanyTaxId:"",
    UserPhone:"",
    CompanyAddress:"",
    CompanyCity:"Lisboa",
    CompanyPostCode:"",
    CompanyCountry:"PT",
    partnerAccessToken: ""
  };

  resData = {
    addPartnerMsg: "",
    addPartnerRequestId: "",
    addPartnerStatus: "",
    body: "",
    statusCode: ""
  };

  constructor(
    private _formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public reqData: any,
    public dialogRef: MatDialogRef<AddDrToPartnerComponent>,
    public drService: DoctorService
  ) { }

  ngOnInit() {
    this.getDrInfo()
  }
  onNoClick(){
    this.dialogRef.close({status: 0});
  }

  getPartnerAccessToken(){
    let data = {
      PartnerTaxId: this.drData.CompanyTaxId
    }
    this.loaderAccessToken = true;
    this.drService.getPartnerAccessToken(data).subscribe((res: any) => {        
      console.log(res);
      this.partnerAccessTokenErrorMsg = "";
      if(res.PartnerAccessTokenStatus=="Success"){
        this.partnerAccessTokenLoadedFlag = true;
        this.drData.partnerAccessToken = res.PartnerAccessToken;
      }else{
        this.partnerAccessTokenErrorMsg = res.PartnerAccessTokenMsg;
      }
      this.loaderAccessToken = false;
    });
  }

  setLoaderFlag(val: boolean){
    this.loaderAddtoPartner = val;
  }

  getDrInfo(){
    let user_id = this.reqData.id;
    this.drService.getDoctorInvoiceDetails(user_id).subscribe((res: any)=>{
      let drInfo = res.userInfo;
      let drProfDet = res.dr_profile_det;      
      
      this.drData.UserEmail = drInfo.email_id;
      this.drData.UserName = drInfo.email_id;
      this.drData.UserPhone = drInfo.mobile_no;
      this.drData.CompanyAddress = drInfo.address +', '+drInfo.landmark;
      this.drData.CompanyCity = 'Lisboa';
      this.drData.CompanyPostCode = drInfo.pincode;
      // this.drData.CompanyCountry = drInfo.company_name;

      this.drData.CompanyLegalName = drProfDet.company_name;
      this.drData.CompanyTaxId = drProfDet.tax_id;

    });
  }

  onSubmitfrm(){
    // console.log(this.myData);
    this.setLoaderFlag(true);
    if(1){
      let data = {
        division_name: '',
        status: 1
      }
      this.addPartnerErrorMsg = "Please wait...";
      this.addPartnerErrorMsg = "";
      let user_id = this.reqData.id;
      this.drData.user_id = user_id;
      this.drService.addToPartner(this.drData).subscribe((res: any) => {        
        console.log(res);
        if(res.addPartnerStatus=="Success"){
          this.resData.addPartnerMsg = res.addPartnerMsg;
          this.resData.addPartnerRequestId = res.addPartnerRequestId;
          this.resData.addPartnerStatus = res.addPartnerStatus;
          this.resData.body = res.body;
          this.resData.statusCode = res.statusCode;
        }else{
          this.addPartnerErrorMsg = res.addPartnerMsg;
        }
      })
      this.setLoaderFlag(false);
      // this.dialogRef.close({status: 1});
    }
  }

}
