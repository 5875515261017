import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-subscriber-join',
  templateUrl: './subscriber-join.component.html',
  styleUrls: ['./subscriber-join.component.scss']
})
export class SubscriberJoinComponent implements AfterViewInit {

  @ViewChild('subscriberDiv') subscriberDiv: ElementRef;
  @Input() session: OT.Session;
  @Input() stream: OT.Stream;

  constructor() { }

  ngAfterViewInit() {
    const subscriber = this.session.subscribe(this.stream, this.subscriberDiv.nativeElement, 
      {
        insertMode: 'append',
        width: '100%',
        height: '90vh'
      }, (err) => {
      if (err) {
        alert(err.message);
      }
    });
  }

}
