import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import config from '../../config';
import { StateService } from '../stateService';
import { CmsService } from '../services/cms/cms.service';
@Component({
  selector: 'app-join-video-call',
  templateUrl: './join-video-call.component.html',
  styleUrls: ['./join-video-call.component.scss']
})
export class JoinVideoCallComponent implements OnInit {
  joinRoomForm: FormGroup = this.formBuilder.group({});
  bookingId: any;
  constructor(
    private cmsService: CmsService,
    private formBuilder: FormBuilder,
    private stateService: StateService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    
  }
  ngOnInit() {
    this.joinRoomForm = this.formBuilder.group({
      roomName: new FormControl({ value: '', disabled: true }, [Validators.required]),
    });
    this.bookingId = this.route.snapshot.paramMap.get('bookingId');
    this.joinRoomForm.patchValue({
      roomName: this.bookingId
    });
  }

  onSubmit() {
    // let get_session_url = config.SAMPLE_SERVER_BASE_URL + '/session/getSession'    
    let data = {
      roomName:  this.joinRoomForm.controls['roomName'].value
    }
    this.cmsService.getSession(data).subscribe(
      (res:any) => {
        // console.log(res);
        //this.stateService.token$ = res['token'];
        //this.stateService.sessionId$ = res['sessionId'];
        //this.stateService.apiKey$ = res['apiKey'];
        this.router.navigate(['/video-call/'+this.bookingId])
      }
    )
  }

}
