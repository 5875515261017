import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalManagerService } from 'src/app/services/modal-manager.service';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-cancellation-confirmation',
  templateUrl: './cancellation-confirmation.component.html',
  styleUrls: ['./cancellation-confirmation.component.scss']
})
export class CancellationConfirmationComponent implements OnInit {
  myUserId : Number = 0;
  constructor(
    @Inject(MAT_DIALOG_DATA) public reqData: any,
    public dialogRef: MatDialogRef<CancellationConfirmationComponent>,
    private userService: UserService,
    private mms: ModalManagerService
  ) { }

  ngOnInit() {
    this.myUserId = Number(localStorage.getItem('pid'));
    console.log(this.reqData); 
  }

  onNoClick(): void {
    this.dialogRef.close({status: 0});
  }

   onSubmit(){
    this.dialogRef.close({status: 0});
    let data = {
      cancellation_reason:"Cancellation Reason",
      booking_id: this.reqData.booking_id,
      user_id: this.myUserId,
      user_type:"Patient"
  }
  this.userService.cancelAppointment(data).subscribe((res: any) => {
    console.log(res);
    if(res.status){
      this.mms.patientBookingCancellationConfirmed(this.reqData).subscribe((res: any) => {
        console.log('cancelled..');
      });
    }
  });
    
  }

}
