import { Injectable } from '@angular/core';
import {DialogPosition, MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { AddDevisionComponent } from '../admin/modal/add-devision/add-devision.component';
import { AddDrToPartnerComponent } from '../admin/modal/add-dr-to-partner/add-dr-to-partner.component';
import { AddUserComponent } from '../admin/modal/add-user/add-user.component';
import { EditUserComponent } from '../admin/modal/edit-user/edit-user.component';
import { ViewUserComponent } from '../admin/users/view-user/view-user.component';
import { AddFamilyMemberComponent } from '../cms/dialog/add-family-member/add-family-member.component';
import { BookingConfirmedComponent } from '../cms/dialog/booking-confirmed/booking-confirmed.component';
import { CancellationConfirmationComponent } from '../cms/dialog/cancellation-confirmation/cancellation-confirmation.component';
import { CancellationConfirmedComponent } from '../cms/dialog/cancellation-confirmed/cancellation-confirmed.component';
import { QuickQueryBookingConfirmedComponent } from '../cms/dialog/quick-query-booking-confirmed/quick-query-booking-confirmed.component';
import { RightSideMenuComponent } from '../cms/right-side-menu/right-side-menu.component';
import { DrEditTimeslotConfirmedComponent } from '../doctor/dialog/dr-edit-timeslot-confirmed/dr-edit-timeslot-confirmed.component';

import { ModalAddTimeslotComponent } from '../doctor/profile/modal-add-timeslot/modal-add-timeslot.component';
import { ModalUpdateProfileComponent } from '../doctor/profile/modal-update-profile/modal-update-profile.component';


export interface DialogData {
  division: string;
  name: string;
}

@Injectable({
  providedIn: 'root'
})
export class ModalManagerService {

  constructor(
    public dialog: MatDialog
    ) { }

  addDivision(data: any = []): Observable<any>{
    var dialogConfig = new MatDialogConfig();

    dialogConfig.panelClass = "panel-class";
    dialogConfig.data = data;
    let dialogRef: MatDialogRef<AddDevisionComponent>;
    let dialogRefRes = this.dialog.open(AddDevisionComponent, dialogConfig);
    return dialogRefRes.afterClosed();
  }

  addUser(data: any = []): Observable<any>{
    var dialogConfig = new MatDialogConfig();

    dialogConfig.panelClass = "panel-class";
    dialogConfig.data = data;
    let dialogRef: MatDialogRef<AddUserComponent>;
    let dialogRefRes = this.dialog.open(AddUserComponent, dialogConfig);
    return dialogRefRes.afterClosed();
  }

  editUser(data: any = []): Observable<any>{
    var dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = "panel-class";
    dialogConfig.data = data;
    dialogConfig.width = "70%";
    let dialogRef: MatDialogRef<EditUserComponent>;
    let dialogRefRes = this.dialog.open(EditUserComponent, dialogConfig);
    return dialogRefRes.afterClosed();
  }
  // add doctor to partner (invoice partner - MagniFinance)
  addToPartnerModal(data: any = []): Observable<any>{
    var dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = "panel-class";
    dialogConfig.data = data;
    dialogConfig.width = "40%";
    let dialogRef: MatDialogRef<AddDrToPartnerComponent>;
    let dialogRefRes = this.dialog.open(AddDrToPartnerComponent, dialogConfig);
    return dialogRefRes.afterClosed();
  }

  // open modal : add new dr time slot
  AddNewDrTimeSlot(data: any = []): Observable<any>{
    var dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = "panel-class";
    dialogConfig.data = data;
    dialogConfig.width = "50%";
    dialogConfig.panelClass = "dialog-responsive";
    let dialogRef: MatDialogRef<ModalAddTimeslotComponent>;
    let dialogRefRes = this.dialog.open(ModalAddTimeslotComponent, dialogConfig);
    return dialogRefRes.afterClosed();
  }

  bookingConfirmed(data: any = []): Observable<any>{
    var dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = "panel-class";
    dialogConfig.data = data;
    dialogConfig.width = "30%";
    dialogConfig.height = "100vh";
    dialogConfig.panelClass = "dialog-responsive";
    dialogConfig.hasBackdrop = true;
    dialogConfig.disableClose = true;
    let dialogRef: MatDialogRef<BookingConfirmedComponent>;
    const dialogPosition: DialogPosition = {
      top: '0px',
      right: '0px'
    };
    dialogConfig.position = dialogPosition;
    let dialogRefRes = this.dialog.open(BookingConfirmedComponent, dialogConfig);
    return dialogRefRes.afterClosed();
  }
  
  drEditAppointmentTimingConfirmed(data: any = []): Observable<any>{
    var dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = "panel-class";
    dialogConfig.data = data;
    dialogConfig.width = "30%";
    dialogConfig.height = "100vh";
    dialogConfig.panelClass = "dialog-responsive";
    dialogConfig.hasBackdrop = true;
    dialogConfig.disableClose = true;
    let dialogRef: MatDialogRef<DrEditTimeslotConfirmedComponent>;
    const dialogPosition: DialogPosition = {
      top: '0px',
      right: '0px'
    };
    dialogConfig.position = dialogPosition;
    let dialogRefRes = this.dialog.open(DrEditTimeslotConfirmedComponent, dialogConfig);
    return dialogRefRes.afterClosed();
  }
  quickQueryBookingConfirmed(data: any = []): Observable<any>{
    var dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = "panel-class";
    dialogConfig.data = data;
    dialogConfig.width = "30%";
    dialogConfig.height = "100vh";
    
    dialogConfig.panelClass = "dialog-responsive";
    dialogConfig.hasBackdrop = true;
    let dialogRef: MatDialogRef<QuickQueryBookingConfirmedComponent>;
    const dialogPosition: DialogPosition = {
      top: '0px',
      right: '0px'
    };
    dialogConfig.position = dialogPosition;
    let dialogRefRes = this.dialog.open(QuickQueryBookingConfirmedComponent, dialogConfig);
    return dialogRefRes.afterClosed();
  }

  updateProfilePhoto(data: any = []): Observable<any>{
    var dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = "panel-class";
    dialogConfig.data = data;
    dialogConfig.width = "30%";
    dialogConfig.disableClose = true;
    dialogConfig.panelClass = "dialog-responsive";
    let dialogRef: MatDialogRef<ModalUpdateProfileComponent>;
    let dialogRefRes = this.dialog.open(ModalUpdateProfileComponent, dialogConfig);
    return dialogRefRes.afterClosed();
  }

// add new famlily member: called from patient module
addNewFamilyMember(data: any = []): Observable<any>{
  var dialogConfig = new MatDialogConfig();
  dialogConfig.panelClass = "panel-class";
  dialogConfig.data = data;
  dialogConfig.width = "40%";
  dialogConfig.disableClose = true;
  dialogConfig.panelClass = "dialog-responsive";
  let dialogRef: MatDialogRef<AddFamilyMemberComponent>;
  let dialogRefRes = this.dialog.open(AddFamilyMemberComponent, dialogConfig);
  return dialogRefRes.afterClosed();
}

// CMS right side menu 
cmsRightSideMenu(data: any = []): Observable<any>{
  var dialogConfig = new MatDialogConfig();
  dialogConfig.panelClass = "panel-class";
  dialogConfig.data = data;
  dialogConfig.width = "20%";
  dialogConfig.height = "100vh";
  dialogConfig.panelClass = "dialog-responsive";
  dialogConfig.hasBackdrop = true;
  // dialogConfig.disableClose = true;
  let dialogRef: MatDialogRef<RightSideMenuComponent>;
  const dialogPosition: DialogPosition = {
    top: '0px',
    right: '0px'
  };
  dialogConfig.position = dialogPosition;
  let dialogRefRes = this.dialog.open(RightSideMenuComponent, dialogConfig);
  return dialogRefRes.afterClosed();
}

patientBookingCancellationConfirmation(data: any = []): Observable<any>{
  var dialogConfig = new MatDialogConfig();
  dialogConfig.panelClass = "panel-class";
  dialogConfig.data = data;
  dialogConfig.width = "40%";
  dialogConfig.disableClose = true;
  dialogConfig.panelClass = "dialog-responsive";
  let dialogRef: MatDialogRef<CancellationConfirmationComponent>;
  let dialogRefRes = this.dialog.open(CancellationConfirmationComponent, dialogConfig);
  return dialogRefRes.afterClosed();
}

patientBookingCancellationConfirmed(data: any = []): Observable<any>{
  var dialogConfig = new MatDialogConfig();
  dialogConfig.panelClass = "panel-class";
  dialogConfig.data = data;
  dialogConfig.width = "30%";
  dialogConfig.height = "100vh";
  dialogConfig.panelClass = "dialog-responsive";
  dialogConfig.hasBackdrop = true;
  dialogConfig.disableClose = true;
  let dialogRef: MatDialogRef<CancellationConfirmedComponent>;
  const dialogPosition: DialogPosition = {
    top: '0px',
    right: '0px'
  };
  dialogConfig.position = dialogPosition;
  let dialogRefRes = this.dialog.open(CancellationConfirmedComponent, dialogConfig);
  return dialogRefRes.afterClosed();
}

viewUser(data: any = []): Observable<any>{
  var dialogConfig = new MatDialogConfig();
  dialogConfig.panelClass = "panel-class";
  dialogConfig.data = data;
  dialogConfig.width = "70%";
  let dialogRef: MatDialogRef<ViewUserComponent>;
  let dialogRefRes = this.dialog.open(ViewUserComponent, dialogConfig);
  return dialogRefRes.afterClosed();
}

}
