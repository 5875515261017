<div class="oma-wrapper">
    <h2 class="oma-page-title">Doctor Consultation</h2>
    <div class="video-call" fxLayoutAlign="center center">
        <form class="joinForm" [formGroup]="joinRoomForm" (ngSubmit)="onSubmit()"
            fxLayout="column" fxLayoutGap="20px" >
            <div>
                <input placeholder="Meeting Id" id="roomName" type="text" formControlName="roomName">
            </div>
            <button class="oma-btn oma-btn-primary" [disabled]="joinRoomForm.invalid" mat-raised-button type="submit">Join Now</button>
        </form>
    </div>
</div>