import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DivServiceService } from 'src/app/services/div-service.service';
//https://www.youtube.com/watch?v=ZL0d3M3uoRQ
@Component({
  selector: 'app-add-devision',
  templateUrl: './add-devision.component.html',
  styleUrls: ['./add-devision.component.scss']
})
export class AddDevisionComponent implements OnInit {

  public myData: FormGroup = this._fb.group({division_name: ['']});

  constructor(public dialogRef: MatDialogRef<AddDevisionComponent>,
    private _fb: FormBuilder,
      @Inject(MAT_DIALOG_DATA) public editData: any,
      private divService: DivServiceService
    ) { }

  ngOnInit() {
    console.log(this.editData);
    this.myData = this._fb.group({
      division_name: new FormControl('',[
        Validators.required
      ])
    });
  }

  get f(){
    return this.myData.controls;
  }

  onSubmit(){
    // console.log(this.myData);
    if(this.myData.valid){
      let data = {
        division_name: this.myData.controls['division_name'].value,
        status: 1
      }
      this.divService.create(data).subscribe(res => {        
        console.log(res);
      })

      this.dialogRef.close(this.myData.value);
    }
  }

}
