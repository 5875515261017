<div fxLayout="row" class="wrapper" fxFlex>
    <div fxHide fxShow.gt-sm class="login-image" fxFlex="35">
        <img src="../../../assets/images/oma-doctor-login-side-image.png" width="100%" alt="">
    </div>
    <div class="login-div" fxFlex fxFlex.gt-sm="65" fxLayout="column" fxLayoutGap="15px">
        <div class="login-header" fxLayout="row" fxLayoutAlign="start" fxLayoutAlign="space-between center"
            fxLayoutGap="15px">
            <div>
                <img title="OMA HEALTH LOGO" [routerLink]="['']" ngStyle.md="width: 180px; height: 50px;"
                    src="../../../assets/images/oma-health-logo.svg">
            </div>
            <div class="login-menu" fxLayout="row" fxLayoutGap="20px">
                <a [routerLink]="[ '/doctor-login']">Log In</a>
                <a [routerLink]="[ '/doctor-signup']" class="active">Sign Up</a>
            </div>
        </div>
        <div class="login-form" *ngIf="step != 5">
           
                <mat-accordion class="example-headers-align">
                    <form [formGroup]="personalDetailForm" (ngSubmit)="personalDetailFormSubmit()" >
                    <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)" >
                        <mat-expansion-panel-header>
                            <mat-panel-title class="form-header">
                                Personal Details
                            </mat-panel-title>
                            <mat-panel-description>
                                
                            </mat-panel-description>
                        </mat-expansion-panel-header>

                        <!--  -->
                        
                    <div class="oma-wrapper-content" fxLayout="column" fxLayoutGap="20px"
                        ngStyle.gt-sm="width:60%;">

                        <div fxLayout="column">
                            <div class="label">Name :</div>
                            <input type="text" matInput formControlName="full_name" required>
                            <mat-error *ngIf="personalDetailForm.get('full_name')?.hasError('required')">Full name is required.</mat-error>
                        </div>

                    <!--  -->
                    <div fxLayout="column">
                        <div class="label">Email id :</div>
                        <input type="text" matInput formControlName="email" (change)="onChangeSettingEmail()" required>
                        <mat-error *ngIf="personalDetailForm.get('email')?.hasError('required')">Email Id is required.</mat-error>
                        <mat-error *ngIf="personalDetailForm.get('email')?.hasError('emailExist')">Mail Id Already Exists.</mat-error>
                        
                    </div>
                               
                    <div fxLayout="column">
                        <div class="label">D.O.B :</div>
                        <!-- <input type="text" matInput formControlName="dob" required> -->
                        <div fxLayout="row">
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <input type="text" [matDatepicker]="picker" class="form-control" id="inputDOB"
                                formControlName="dob" aria-describedby="inputDOBH" placeholder="Type Here">

                            <mat-datepicker #picker></mat-datepicker>
                        </div>
                        <mat-error *ngIf="personalDetailForm.get('dob')?.hasError('required')">DOB is required.</mat-error>
                    </div>
                   
                              <!--  -->
                              <div fxLayout="column">
                                <div class="label">Identification Card :</div>
                                
                                <div class="upload-doc" >
                                    
                                    <input fxFlex type="file" (change)="onFileSelectIdentification($event)"
                                    formControlName="identification_card">
                                </div>
                                <mat-error *ngIf="personalDetailForm.get('identification_card')?.hasError('required')">Identification Card is required.</mat-error>
                             </div> 

                             
                              <!--  -->
                             <div fxLayout="column">
                                <div class="label-h3" >Education Details</div>
                             </div>
                             <!--  -->
                             <div fxLayout="column">
                                <div class="label">UG :</div>
                                <input type="text" matInput formControlName="ug" required>
                                <mat-error *ngIf="personalDetailForm.get('ug')?.hasError('required')">UG is required.</mat-error>
                             </div> 
                             <!--  -->
                             <div fxLayout="column">
                                <div class="label">Major :</div>
                                <input type="text" matInput formControlName="major" required>
                                <mat-error *ngIf="personalDetailForm.get('major')?.hasError('required')">Major is required.</mat-error>
                             </div> 
                               
                             <!--  -->
                             <div fxLayout="column">
                                <div class="label">Year :</div>
                                <input type="text" matInput formControlName="passout_year" required>
                                <mat-error *ngIf="personalDetailForm.get('passout_year')?.hasError('required')">Year is required.</mat-error>
                             </div> 
                             <!--  -->
                             <div fxLayout="column">
                                <div class="label">University :</div>
                                <input type="text" matInput formControlName="univercity" required>
                                <mat-error *ngIf="personalDetailForm.get('univercity')?.hasError('required')">University is required.</mat-error>
                             </div>
                              <!--  -->
                              <div fxLayout="column">
                                <div class="label">Certificate :</div>
                                
                               
                                <div class="upload-doc" >
                                    
                                    <input fxFlex type="file" (change)="onFileSelectedu_certificate($event)"
                                    formControlName="edu_certificate">
                                </div>
                                <mat-error *ngIf="personalDetailForm.get('edu_certificate')?.hasError('required')">Certificate is required.</mat-error>
                             </div> 
                              <!--  -->
                              <div fxLayout="column">
                                <div class="label-h3" >Billing Information</div>
                             </div>
                             <div fxLayout="column">
                            <mat-radio-group aria-label="address_type" color="primary" fxFlex formControlName="address_type"
                            fxLayout="row" fxLayoutGap="25px"> 
                            <mat-radio-button value="Individual">Individual</mat-radio-button>
                            <mat-radio-button value="Company" class="m-l-10">Company</mat-radio-button>
                            
                        </mat-radio-group>
                    </div>
                        <mat-error *ngIf="personalDetailForm.get('address_type')?.hasError('required')">Please select Adderss Type.</mat-error>
                             <div fxLayout="column">
                                <div class="label">Name of Company :</div>
                                <input type="text" matInput formControlName="name_of_company" required>
                                <mat-error *ngIf="personalDetailForm.get('name_of_company')?.hasError('required')">Name of company required.</mat-error>
                             </div>
                             <div fxLayout="column">
                                <div class="label">NIPC :</div>
                                <input type="text" matInput formControlName="nipc" required>
                                <mat-error *ngIf="personalDetailForm.get('nipc')?.hasError('required')">NIPC required.</mat-error>
                             </div>
                             <div fxLayout="column">
                                <div class="label">Billing Address :</div>
                                
                                <textarea matInput formControlName="billing_address" required>
                                    </textarea>
                                    <mat-error *ngIf="personalDetailForm.get('billing_address')?.hasError('required')">Billing Address required.</mat-error>
                             </div>
                             <div fxLayout="column">
                                <div class="label">IBAN :</div>
                                <input type="text" matInput formControlName="iban" required>
                                <mat-error *ngIf="personalDetailForm.get('iban')?.hasError('required')">IBAN required.</mat-error>
                             </div>
                              <!--  -->
                              <div fxLayout="column">
                                <div class="label">Certificate :</div>
                                
                                <div class="upload-doc" >
                                    
                                    <input fxFlex type="file" (change)="onFileSelectCertificate($event)"
                                    formControlName="billing_certificate">
                                </div>
                                <mat-error *ngIf="personalDetailForm.get('billing_certificate')?.hasError('required')">Certificate is required.</mat-error>
                             </div> 
                                <!--  -->
                                <div *ngIf="errMsg" class="error-message">
                                    {{errMsg}}
                                </div>
                                <div *ngIf="successMsg" class="success-message">{{successMsg}}</div>
                                <div fxLayout="row">
                                    
                                        <button mat-raised-button type="submit" class="oma-btn-primary oma-btn"
                                        style="width: 133px;">Next</button>
                                </div>

                                <!--  -->

                                
 
 

                            </div>
                       
                        <!--  -->
                    </mat-expansion-panel>
                </form>
                    <form [formGroup]="professionalDetailForm" (ngSubmit)="professionalDetailFormSubmit()">
                    <mat-expansion-panel [expanded]="step === 1" (opened)="setStep(1)">
                        <mat-expansion-panel-header>
                            <mat-panel-title class="form-header">
                                Professional Details
                            </mat-panel-title>
                            <mat-panel-description>
                            </mat-panel-description>
                        </mat-expansion-panel-header>

                        <!-- 
                            Form
                        -->
                        <div class="oma-wrapper-content" fxLayout="column" fxLayoutGap="20px"
                        ngStyle.gt-sm="width:60%;">

                        <div fxLayout="column">
                            <div class="label-h3" >Work Experience</div>
                         </div>
                         <!--  -->
                         <div fxLayout="column">
                            <div class="label">License No. :</div>
                            <input type="text" matInput  formControlName="lic_no">
                            <mat-error *ngIf="professionalDetailForm.get('lic_no')?.hasError('required')">License no required.</mat-error>
                         </div> 
                          <!--  -->
                          <div fxLayout="column">
                            <div class="label">Years of Experience :</div>
                            <input type="text" matInput  formControlName="total_exp">
                            <mat-error *ngIf="professionalDetailForm.get('total_exp')?.hasError('required')">Year of Experiance required.</mat-error>
                         </div> 
                        <!--  -->
                        <div fxLayout="column">
                            <div class="label">Working Position :</div>
                            <input type="text" matInput  formControlName="work_pos">
                            <mat-error *ngIf="professionalDetailForm.get('work_pos')?.hasError('required')">Working position required.</mat-error>
                         </div> 
                          <!--  -->
                          <div fxLayout="column">
                            <div class="label">Medical License doc :</div>
                            
                            
                            <div class="upload-doc" >
                                    
                                <input fxFlex type="file" (change)="onFileSelectmed_lic_doc($event)"
                                formControlName="med_lic_doc">
                            </div>
                            <mat-error *ngIf="professionalDetailForm.get('med_lic_doc')?.hasError('required')">Medical License doc is required.</mat-error>
                         </div> 
                          <!--  -->
                          <div fxLayout="column">
                            <div class="label">Speciality License doc :</div>
                            
                            <div class="upload-doc" >
                                    
                                <input fxFlex type="file" (change)="onFileSelectspec_lic_doc($event)"
                                formControlName="spec_lic">
                            </div>
                            <mat-error *ngIf="professionalDetailForm.get('spec_lic')?.hasError('required')">Speciality License doc is required.</mat-error>
                         </div> 
                          <!--  -->
                          <div fxLayout="column">
                            <div class="label">CV :</div>
                            
                            <div class="upload-doc" >
                                    
                                <input fxFlex type="file" (change)="onFileSelectcv_doc($event)"
                                formControlName="cv_doc">
                            </div>
                            <mat-error *ngIf="professionalDetailForm.get('cv_doc')?.hasError('required')">CV is required.</mat-error>
                         </div> 
                          <!--  -->
                          <div fxLayout="column">
                            <div class="label">ID Card :</div>
                            
                            <div class="upload-doc" >
                                    
                                <input fxFlex type="file" (change)="onFileSelectid_card($event)"
                                formControlName="id_card">
                            </div>
                            <mat-error *ngIf="professionalDetailForm.get('id_card')?.hasError('required')">ID Card is required.</mat-error>
                         </div> 
                        <!--  -->
                        <div fxLayout="column">
                            <div class="label-h3" >Profile Details</div>
                         </div>
                         <!--  -->
                         <div fxLayout="column">
                            <div class="label">Bio :</div>
                            <input type="text" matInput  formControlName="bio">
                            <mat-error *ngIf="professionalDetailForm.get('bio')?.hasError('required')">Bio required.</mat-error>
                         </div> 
                         <!--  -->
                         <div fxLayout="column">
                            <div class="label">Languages :</div>
                            <input type="text" matInput  formControlName="languages">
                            <mat-error *ngIf="professionalDetailForm.get('languages')?.hasError('required')">Language required.</mat-error>

                         </div>
                          <!--  -->
                        

                         
                    <div fxLayout="column">
                        <label class="label">Consultation mode:</label>
                        <div >
                            <section class="example-section" >            
                                <p><mat-checkbox class="example-margin" formControlName="cm_virtual_app" value="1" color="primary">Virtual Appointments</mat-checkbox></p>
                                <p><mat-checkbox class="example-margin" formControlName="cm_instant_virtual_app" value="1" color="primary">Instant Virtual Appointments</mat-checkbox></p>
                                <p><mat-checkbox class="example-margin" formControlName="cm_quick_query_que" value="1" color="primary">Quick Query Questions</mat-checkbox></p>
                                <p><mat-checkbox class="example-margin" formControlName="cm_in_person_app" value="1" color="primary">In Person Appointments</mat-checkbox></p>
                            </section>
                            <p>Note: The platform will show your availabitity of the selected modes of consultations to your patients.</p>
                        </div>
                    </div>
                         <!--  -->
                         <div fxLayout="column">
                            <div class="label">Signature :</div>
                            
                          
                            <div class="upload-doc" >
                                    
                                <input fxFlex type="file" (change)="onFileSelectsignature($event)"
                                formControlName="signature">
                            </div>
                            <mat-error *ngIf="professionalDetailForm.get('signature')?.hasError('required')">Signature is required.</mat-error>
                         </div> 
                         <div fxLayout="column">
                            <div class="label-h3" >Profile Details</div>
                         </div>
                         <!--  -->
                         <div fxLayout="column">
                            <div class="label">PLI policy :</div>
                            <input type="text" matInput  formControlName="pli_policy">
                            <mat-error *ngIf="professionalDetailForm.get('pli_policy')?.hasError('required')">PLI policy required.</mat-error>
                         </div> 
                         <!--  -->
                         <div fxLayout="column">
                            <div class="label">Insurance Company :</div>
                            <input type="text" matInput  formControlName="ins_company">
                            <mat-error *ngIf="professionalDetailForm.get('ins_company')?.hasError('required')">Insurance Company required.</mat-error>
                         </div> 
                           <!--  -->
                           <div fxLayout="column">
                            <div class="label">Registration number :</div>
                            <input type="text" matInput  formControlName="reg_no">
                            <mat-error *ngIf="professionalDetailForm.get('reg_no')?.hasError('required')">Registration number required.</mat-error>
                         </div> 
                        <!--  -->
                        <div fxLayout="column">
                            <div class="label">Insurance Documents :</div>
                            
                            <div class="upload-doc" >
                                    
                                <input fxFlex type="file" (change)="onFileSelectind_doc($event)"
                                formControlName="ins_doc">
                            </div>
                            <mat-error *ngIf="professionalDetailForm.get('ins_doc')?.hasError('required')">Insurance Documents is required.</mat-error>
                         </div> 
                          <!--  -->
                          <div fxLayout="column">
                            <div class="label">Practice Address :</div>
                            <input type="text" matInput  formControlName="practice_address">
                            <mat-error *ngIf="professionalDetailForm.get('practice_address')?.hasError('required')">Practice Address required.</mat-error>
                         </div> 
                          <!--  -->
                          <div fxLayout="column">
                            <div class="label">Website  :</div>
                            <input type="text" matInput  formControlName="website">
                            <mat-error *ngIf="professionalDetailForm.get('website')?.hasError('required')">Website required.</mat-error>
                         </div> 
                        <div *ngIf="errMsg" class="error-message">
                            {{errMsg}}
                        </div>
                        <div *ngIf="successMsg" class="success-message">{{successMsg}}</div>
                       
                        <!--  -->
                    </div>

                        <div fxLayout="row" fxLayoutGap="20px">
                            <br>
                            <button mat-raised-button type="button" class="oma-btn"
                                        style="width: 133px;" (click)="prevStep()">Previous</button>
                            <button mat-raised-button type="submit" class="oma-btn-primary oma-btn"
                                            style="width: 133px;">Next</button>
                                  
                                        
                        </div>
                        
                    </mat-expansion-panel>
                    </form>
                    <!--  -->
                    <form [formGroup]="accountDetailForm" (ngSubmit)="accountDetailFormSubmit()">
                    <mat-expansion-panel [expanded]="step === 2" (opened)="setStep(2)">
                        <mat-expansion-panel-header>
                        <mat-panel-title class="form-header">
                            Account Details
                        </mat-panel-title>
                        <mat-panel-description>
                        </mat-panel-description>
                        </mat-expansion-panel-header>
                    
                        <div class="oma-wrapper-content" fxLayout="column" fxLayoutGap="20px"
                                ngStyle.gt-sm="width:60%;">

                                <div fxLayout="column">
                                    <div class="label">Username :</div>
                                    <input type="text" matInput formControlName="username" 
                                    (change)="onChangeSettingUsername()" required>
                        <mat-error *ngIf="accountDetailForm.get('email')?.hasError('required')">Email Id is required.</mat-error>

                        <mat-error *ngIf="accountDetailForm.get('username')?.hasError('usernameExist')">User Name Already Exists.</mat-error>
                                   
                                </div>
                                <!--  -->
                                <div fxLayout="column">
                                    <div class="label">Password :</div>
                                    <input type="password" matInput formControlName="password" required>
                                    <mat-error *ngIf="accountDetailForm.get('password')?.hasError('required')">Password required.</mat-error>
                                </div>
                                  <!--  -->
                                <div fxLayout="column">
                                    <div class="label">Confirm Password :</div>
                                    <input type="password" matInput formControlName="confirmPassword" required>
                                </div>
                             
                                <!--  -->
                                <div *ngIf="errMsg" class="error-message">
                                    {{errMsg}}
                                </div>
                                <div *ngIf="successMsg" class="success-message">{{successMsg}}</div>
                                <div fxLayout="row">
                                    <button mat-button color="warn" type="button" (click)="prevStep()">Previous</button>
                                    <!-- <button mat-button type="submit" color="primary" (click)="nextStep()">Save Details</button> -->
                                    <button mat-raised-button type="submit" class="oma-btn-primary oma-btn"
                                        style="width: 133px;">Save Details</button>
                                </div>
                                <!--  -->
                            </div>
                    </mat-expansion-panel>
                </form>

                </mat-accordion>
            </div>
                <div  class="footer-links" *ngIf="step === 5">
                    <!--  -->
                    <br>
                    <div class="success-img" fxLayoutAlign="center center"
                    >
                        <img src="../../../assets/images/oma-booking-confirmed-img.png" alt="">
                    </div>
                    <br>
                    <br>
                    <div class="form-header" >
                        Conratulations! Your account has been created Successfully.
                    </div>
                    <div>
                        <a href="#" [routerLink]="['/doctor-login']">Click here</a> to login your account.
                    </div>
                </div>
            
            <!-- <div class="form-header">
                
            </div> -->

    </div>
</div>