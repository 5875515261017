import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
HttpClient
@Injectable({
  providedIn: 'root'
})
export class DivServiceService {
  url = "";
  constructor(private http: HttpClient, private config: ConfigService) { 
    this.url = config.getApiUrl();
  }

  getDivision(){
    return this.http.get(this.url+'div');
  }
  create(data: any){
    return this.http.post(this.url+'div', data);
  }

}
