import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { isEmpty } from 'rxjs/operators';
import { DoctorService } from 'src/app/services/doctor/doctor.service';

@Component({
  selector: 'app-modal-add-timeslot',
  templateUrl: './modal-add-timeslot.component.html',
  styleUrls: ['./modal-add-timeslot.component.scss']
})
export class ModalAddTimeslotComponent implements OnInit {

  selectedDate: Date;
  typeOfConsultation: String = "";
  timeSlotsArr: any = [];
  timeSlotColor: string ="";
  timeslotForm: FormGroup = this._formBuilder.group({});
  objDrWorkExp: any = [];
  clickedOnGenerateTimeSlot: boolean = false;
  CheckedAllTimeSlot: boolean = false;
  objDrProfDetails: any = [];
  constructor(
    @Inject(MAT_DIALOG_DATA) public reqData: any,
    public dialogRef: MatDialogRef<ModalAddTimeslotComponent>,
    private _formBuilder: FormBuilder,
    private doctorService: DoctorService,
  ) { }

  ngOnInit(): void {
    // console.log(this.reqData.myUserId);
    this.loadMyProfileData();
    this.createTimeSlotForm();

  }
  loadMyProfileData(){
    this.doctorService.getMyProfDetails(this.reqData.myUserId).subscribe((res: any) => {
      this.objDrWorkExp = res['dr_work_exp'][0];
      this.objDrProfDetails = res['dr_profile_det'][0];
    });
  }
  setAvgTimePerCons(ConsType: String){
    this.timeSlotsArr = [];
    if(ConsType==="Virtual Consultation"){
      this.typeOfConsultation = "Virtual Consultation";
      this.timeslotForm.patchValue({
        avg: this.objDrProfDetails['va_avg_time_of_consultation']
      });
      this.timeSlotColor = "primary";
    }else if(ConsType==="In Person Appointment"){     
      this.typeOfConsultation = "In Person Appointment"; 
      this.timeslotForm.patchValue({
        avg: this.objDrProfDetails['ipa_avg_time_of_consultation']
      });
      this.timeSlotColor = "accent";
    }
    console.log(this.typeOfConsultation);
  }
  onNoClick(): void {
    this.dialogRef.close({status: 0});
  }
  async onSubmit() {
    let result = "";
    // {
    //   'selected_date': this.selectedDate,
    //   'avg_cons_time': this.timeslotForm.controls['avg'].value,
    //   'timeslots': this.timeSlotsArr,
    //   'type_of_consultation': this.typeOfConsultation
    // }
    let data = {
      'user_id': this.reqData.myUserId,
      'week_no': this.reqData.week_no,
      '_date': this.reqData.for_date,
      '_year': this.selectedDate.getFullYear(),
      'consultation_type': this.typeOfConsultation,
      'timeslots': this.timeSlotsArr
    };
    await this.doctorService.add_dr_timeslot(data).subscribe((res: any) => {
      result = res;
      // console.log(res);
    })
    this.dialogRef.close(result);
  }
  createTimeSlotForm(){
    this.timeslotForm = new FormGroup({
      avg: new FormControl('', [Validators.required]),
      openTimeHH: new FormControl('01', [Validators.required]),
      openTimeMM: new FormControl('00', [Validators.required]),
      closeTimeHH: new FormControl('02', [Validators.required]),
      closeTimeMM: new FormControl('00', [Validators.required]),
      check_all: new FormControl(false)
    });
  }
  getDay(date: Date){
    var days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat'];
    var d = new Date(date);
    this.selectedDate = d;
    return days[d.getDay()];
  }
  timeToMinutes(time: any) {
    var hours = (new Date(time)).getHours() * 60;
    var minutes = (new Date(time)).getMinutes();
    time = hours + minutes;
    
    return time;
  }
  generateTimeSlots(){
    this.clickedOnGenerateTimeSlot = true;
    this.timeSlotsArr = [];
    this.timeslotForm.patchValue({
      check_all: false
    });
    var start_time = this.timeslotForm.controls['openTimeHH'].value +':'+this.timeslotForm.controls['openTimeMM'].value+':00';
    var close_time = this.timeslotForm.controls['closeTimeHH'].value +':'+this.timeslotForm.controls['closeTimeMM'].value+':00';
    var avg_cons_time = this.timeslotForm.controls['avg'].value;
    if(avg_cons_time){
      var dt = new Date("2021/08/23 " + start_time);
      var endtime_dt = new Date("2021/08/23 " + start_time);
      var closedt = new Date("2021/08/29 " + close_time);
      var start_dt_min = this.timeToMinutes(dt);
      var end_dt_min = this.timeToMinutes(closedt);
      var mindiff = end_dt_min - start_dt_min; 
      
      var no_of_slot = mindiff / avg_cons_time;
      // console.log(no_of_slot);
      //this.timeSlotsArr.push({ start_time: '09:00 - 10:00', checked: true });
      for (let i = 1; i <= no_of_slot; i++) {
        // let time = (hr+i).toString() + "."+m.toString()+ dt.getHours()+1;
        if (i > 1) {
          dt.setTime(dt.getTime() + avg_cons_time * 60 * 1000);          
        }
        // endtime_dt = dt;
        endtime_dt.setTime(dt.getTime() + (avg_cons_time) * 60 * 1000);
        let time = (dt.getHours() < 10 ? '0' : '') + dt.getHours() + ":" + dt.getMinutes() + (dt.getMinutes() < 1 ? '0' : '');
        let end_time = (endtime_dt.getHours() < 10 ? '0' : '') + endtime_dt.getHours() + ":" + endtime_dt.getMinutes() + (endtime_dt.getMinutes() < 1 ? '0' : '');
        let data = {
          start_time: time.toString(),
          end_time: end_time.toString(),
          value: time.toString(),
          checked: false,
          is_booked: 0,
          is_available: 1
        }
        this.timeSlotsArr.push(data);
      }
    }else{
      
    }
  }
  checkedOne(index: number, event: MatCheckboxChange){        
    this.timeSlotsArr[index].checked = event.checked;
    console.log(this.timeSlotsArr);    
    this.timeslotForm.patchValue({
      check_all: false
    });
    
  }
  CheckAllTimeSlot(event: MatCheckboxChange){    
    if(event.checked){      
      this.timeSlotsArr.forEach((item: any , index: any ) => {
        item.checked = true;
        this.timeSlotsArr[index] = item;
      });
      this.timeslotForm.patchValue({
        check_all: true
      });
    }
    else{
      this.timeSlotsArr.forEach((item: any , index: any ) => {
        item.checked = false;
        this.timeSlotsArr[index] = item;
      });
      this.timeslotForm.patchValue({
        check_all: false
      });
    }
  }

}
