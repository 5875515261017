<!-- <div class="header">
    Dashboard
</div> -->
<h3 mat-dialog-title>View Doctor</h3>
<div mat-dialog-content class="dialog-content-body">
    <mat-card>
        <!-- <mat-card-title>View Doctor</mat-card-title> -->
        <mat-tab-group mat-align-tabs="start">
            <mat-tab label="Personal Details" class="">

                <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign="start start" fxLayoutGap="20px">
                    <div fxFlex="80" fxLayout="column" fxLayoutGap="20px">

                        <div fxLayout="row">
                            <label fxFlex="20">Name :</label>
                            <label class="labeldsc" fxFlex="30">{{full_name}}</label>
                            <label fxFlex="20">Email :</label>
                            <label class="labeldsc" fxFlex="30">{{email_id}}</label>
                        </div>
                        <div fxLayout="row">
                            <label fxFlex="20">ID Card :</label>

                            <a fxFlex="30" class="hrefcls" target="_blank"
                                href="{{base_url}}api/user/assets/{{objDrWorkExp.id_card}}">
                                {{getFileName(objDrWorkExp.id_card)}}
                            </a>
                            <label fxFlex="20">D.O.B</label>
                            <label class="labeldsc" fxFlex="30"> {{dob | date: 'dd'}}/{{dob | date:'MM'}}/{{dob | date:
                                'yyyy'}}</label>
                        </div>
                        <div fxLayout="row">
                            <div class="label-h3">Education Details</div>
                        </div>
                        <div *ngFor="let eduDet of objDrEduDetails">
                            <div fxLayout="row">
                                <label fxFlex="20">Qualification :</label>
                                <label class="labeldsc" fxFlex="30">{{eduDet.ug}}</label>
                                <label fxFlex="20">University :</label>
                                <label class="labeldsc" fxFlex="30">{{eduDet.univercity}}</label>
                            </div>
                            <div fxLayout="row">
                                <label fxFlex="20">Year :</label>
                                <label class="labeldsc" fxFlex="30">{{eduDet.passout_year}}</label>
                                <label fxFlex="20">Major :</label>
                                <label class="labeldsc" fxFlex="30">{{eduDet.major}}</label>
                            </div>
                            <div fxLayout="row">
                                <label fxFlex="20">Certificate :</label>

                                <a fxFlex="30" class="hrefcls" target="_blank"
                                    href="{{base_url}}api/user/assets/uploads/doctor/{{eduDet.certificate}}">
                                    {{getFileName(eduDet.certificate)}}
                                </a>

                            </div>
                        </div>
                        <div fxLayout="row">
                            <div class="label-h3">Billing Details</div>
                        </div>
                        <div fxLayout="row">
                            <label fxFlex="20">Name of
                                Company :</label>
                            <label class="labeldsc" fxFlex="30">Major</label>
                            <label fxFlex="20">Billing
                                Address :</label>
                            <label class="labeldsc" fxFlex="30">Major</label>
                        </div>
                        <div fxLayout="row">
                            <label fxFlex="20">IBAN :</label>
                            <label class="labeldsc" fxFlex="30">Major</label>
                            <label fxFlex="20">Certificate :</label>
                            <label class="labeldsc" fxFlex="30">Major</label>
                        </div>

                    </div>
                </div>

            </mat-tab>
            <mat-tab label="Professional details">
                <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign="start start" fxLayoutGap="20px">
                    <div fxFlex="80" fxLayout="column" fxLayoutGap="20px">
                        <div fxLayout="row">
                            <div class="label-h3">Work Experience</div>
                        </div>
                        <div fxLayout="row">
                            <label fxFlex="20">License No. :</label>
                            <label class="labeldsc" fxFlex="30">{{objDrWorkExp.lic_no}}</label>
                            <label fxFlex="20">Years of Experience :</label>
                            <label class="labeldsc" fxFlex="30">{{objDrWorkExp.total_exp}}</label>
                        </div>
                        <div fxLayout="row">
                            <label fxFlex="20">Working Position :</label>
                            <label class="labeldsc" fxFlex="30"></label>
                            <label fxFlex="20">Major :</label>
                            <label class="labeldsc" fxFlex="30">Major</label>
                        </div>
                        <div fxLayout="row">
                            <label fxFlex="20">Medical license :</label>

                            <a fxFlex="30" class="hrefcls" target="_blank"
                                href="{{base_url}}api/user/assets/uploads/doctor/{{objDrWorkExp.med_lic_doc}}">
                                {{getFileName(objDrWorkExp.med_lic_doc)}}
                            </a>
                            <label fxFlex="20">Speciality License</label>

                            <a fxFlex="30" class="hrefcls" target="_blank"
                                href="{{base_url}}api/user/assets/uploads/doctor/{{objDrWorkExp.spec_lic_doc}}">
                                {{getFileName(objDrWorkExp.spec_lic_doc)}}
                            </a>
                        </div>
                        <div fxLayout="row">
                            <label fxFlex="20">CV :</label>

                            <a fxFlex="30" class="hrefcls" target="_blank"
                                href="{{base_url}}api/user/assets/uploads/doctor/{{objDrWorkExp.plip_proof_doc}}">
                                {{getFileName(objDrWorkExp.plip_proof_doc)}}
                            </a>
                            <label fxFlex="20">ID Card :</label>

                            <a fxFlex="30" class="hrefcls" target="_blank"
                                href="{{base_url}}api/user/assets/uploads/doctor/{{objDrWorkExp.id_card}}">
                                {{getFileName(objDrWorkExp.id_card)}}
                            </a>
                        </div>
                        <div fxLayout="row">
                            <div class="label-h3">Work Experience</div>
                        </div>
                        <div fxLayout="row">
                            <label fxFlex="20">Bio :</label>
                            <label class="labeldsc" fxFlex="80">{{objDrProfDetails.bio}}</label>

                        </div>
                        <div fxLayout="row">
                            <label fxFlex="20">Languages :</label>
                            <label class="labeldsc" fxFlex="30">{{objDrProfDetails.languages}}</label>
                            <label fxFlex="20">Signature :</label>

                            <a fxFlex="30" class="hrefcls" target="_blank"
                                href="{{base_url}}api/user/assets/uploads/doctor/{{objDrProfDetails.signature}}">
                                {{getFileName(objDrProfDetails.signature)}}
                            </a>

                        </div>
                        <div fxLayout="row">
                            <label fxFlex="20">Consultation mode :</label>
                            <label class="labeldsc" fxFlex="80">{{consultatinMode}}</label>

                        </div>
                        <div fxLayout="row">
                            <div class="label-h3">Virtual Appointment</div>
                        </div>
                        <div fxLayout="row">
                            <label fxFlex="20">Consultation Rate :</label>
                            <label class="labeldsc" fxFlex="30">{{objDrProfDetails.va_rate}}</label>
                            <label fxFlex="20">Avg. Time :</label>
                            <label class="labeldsc" fxFlex="30">{{objDrProfDetails.va_avg_time_of_consultation}}</label>

                        </div>
                        <div fxLayout="row">
                            <div class="label-h3">In Person Appointments</div>
                        </div>
                        <div fxLayout="row">
                            <label fxFlex="20">Consultation Rate :</label>
                            <label class="labeldsc" fxFlex="30">{{objDrProfDetails.ipa_rate}}</label>
                            <label fxFlex="20">Avg. Time :</label>
                            <label class="labeldsc"
                                fxFlex="30">{{objDrProfDetails.ipa_avg_time_of_consultation}}</label>

                        </div>
                        <div fxLayout="row">
                            <label fxFlex="20">Practice Address :</label>
                            <label class="labeldsc" fxFlex="80">{{objDrProfDetails.practice_address}}</label>

                        </div>
                        <div fxLayout="row">
                            <label fxFlex="20">Website :</label>
                            <label class="labeldsc" fxFlex="80">{{objDrProfDetails.website}}</label>

                        </div>
                        <div fxLayout="row">
                            <div class="label-h3">Insurance Details</div>
                        </div>
                        <div fxLayout="row">
                            <label fxFlex="20">PLI policy : </label>
                            <label class="labeldsc" fxFlex="30">{{ins_det.pli_policy}}</label>
                            <label fxFlex="20">Insurance Company :</label>
                            <label class="labeldsc" fxFlex="30">{{ins_det.ins_company}}</label>

                        </div>
                        <div fxLayout="row">
                            <label fxFlex="20">Registration number : </label>
                            <label class="labeldsc" fxFlex="30">{{ins_det.reg_no}}</label>
                            <label fxFlex="20">Insurance Documents :</label>

                            <a fxFlex="30" class="hrefcls" target="_blank"
                                href="{{base_url}}api/user/assets/uploads/doctor/{{ins_det.ins_doc}}">
                                {{getFileName(ins_det.ins_doce)}}
                            </a>
                        </div>

                    </div>
                </div>
            </mat-tab>
            <mat-tab label="Action" class="">
                <br><br>
                    <form [formGroup]="userActionFrm" (ngSubmit)="onSubmit()" #formone="ngForm">
                        <div fxLayout="column" fxLayoutGap="20px">
                            <div fxLayout="row" fxLayoutGap="20px">
                                <div fxFlex="22">
                                    Status:
                                </div>
                                <div fxFlex>
                                    <mat-slide-toggle formControlName="status" color="warn"></mat-slide-toggle>
                                </div>
                            </div>
                            <!--  -->
                            <div fxLayout="row" fxLayoutGap="20px">
                                <div fxFlex="22">
                                    Admin approved:
                                </div>
                                <div fxFlex>
                                    <mat-slide-toggle formControlName="admin_approved" color="warn"></mat-slide-toggle>
                                </div>
                            </div>
                            <div fxLayout="row" fxLayoutGap="20px">
                                <div fxFlex="22">
                                    Remark:
                                </div>
                                <div fxFlex>
                                    <textarea matInput placeholder="Remark" formControlName="remark"
                                    required class="form-control"></textarea>
                                </div>
                            </div>
                            <br>
                            <div fxLayout="row" fxLayoutGap="20px">
                                <div fxFlex="22">
                                    
                                </div>
                                <div fxFlex>
                                    <button mat-raised-button type="submit" color="primary" [disabled]="userActionFrm.invalid"> 
                                        <mat-icon>east</mat-icon> Update </button>
                                </div>
                            </div>

                        </div>
                        
                        

                    </form>
                
            </mat-tab>



            <!--<mat-tab label="My Account">
                <p></p>
                <button mat-raised-button color="primary">Save Changes</button>
            </mat-tab>-->
        </mat-tab-group>


    </mat-card>

</div>