import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserService } from 'src/app/services/user/user.service';
import { MustMatch } from 'src/app/_helpers/must-match.validator';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss']
})
export class AddUserComponent implements OnInit {
  submitted = false;
  emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";
  public myData: FormGroup = this._fb.group({
    first_name: [''],
    last_name: [''],
    email_id: [''],
    mobile_no: [''],
    gender: [''],
    password: [''],
    user_cat: ['']
  });
  constructor(
    public dialogRef: MatDialogRef<AddUserComponent>,
    private _fb: FormBuilder,
    private UserService: UserService,
    private _snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.createForm();
  }

  createForm(){
    
    this.myData = this._fb.group({
      first_name: new FormControl('',[Validators.required]),
      last_name: new FormControl('',[Validators.required]),
      email_id: new FormControl('',[Validators.required, Validators.pattern(this.emailPattern) ]),
      mobile_no: new FormControl('',[Validators.required, Validators.minLength(10),Validators.maxLength(10)]),
      gender: new FormControl('',[Validators.required]),
      password: new FormControl('123456',[Validators.required,Validators.minLength(6),Validators.maxLength(20)]),
      confirmPassword: new FormControl('123456', [Validators.required]),
      user_cat: new FormControl('', [Validators.required])
    },{
      validator: MustMatch('password', 'confirmPassword')
    });
  }
  // convenience getter for easy access to form fields
  get f() { return this.myData.controls; }

  onSubmit(){
    this.submitted = true;
    if(this.myData.valid){
      let data = {
        first_name: this.myData.controls['first_name'].value,
        last_name: this.myData.controls['last_name'].value,
        email: this.myData.controls['email_id'].value,
        mobile: this.myData.controls['mobile_no'].value,
        gender: this.myData.controls['gender'].value,
        password: this.myData.controls['password'].value,
        username: this.myData.controls['email_id'].value,
        user_cat: this.myData.controls['user_cat'].value,
        country_code: "+91",
        created_by: 0,
        status: 0
      }
      this.UserService.createUser(data).subscribe((res: any) => {        
        console.log(res);
        if(res['errors']){          
          const err = res['errors'];
          err.forEach((ele: any) => {
            console.log(ele['param']+" : "+ele['msg']);
            // console.log(ele['param']);
            if(ele['param']=="email"){
              const matchingControl = this.myData.controls['email_id'];
              matchingControl.setErrors({ emailExist: true, message: ele['msg'] });
            }else if(ele['param']=="mobile"){
              const matchingControl = this.myData.controls['mobile_no'];
              matchingControl.setErrors({ mobileExist: true, message: ele['msg'] });
            }           

          });
        }else{
          if(res['status']){
            // console.log(res['message']);
            this.openSnackBar(res['message']);
            this.dialogRef.close(this.myData.value);
          }
        }
      }, (err: any) => {
        // console.log(err['Result']);                
      }, () => {
        // console.log('completed');
      })
      
    }else{
      return;
    }
  }
  openSnackBar(message: string) {
    this._snackBar.open(message, 'OK', {
      duration: 2000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
    });
  }

}
