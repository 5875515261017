<section class="section-7">
    <!-- <div class="section-title">Oma Health Articles</div> -->
    <div fxHide fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign="space-between center" fxLayoutGap="30px">
        <mat-card fxFlex="30"  class="hassle-free-consultations-card" fxLayout="column">
            <div class="oma-card-header" fxLayout="column" fxLayoutAlign="center center">
                <p>200+</p>
                <div>Happy Patients</div>
            </div>                
        </mat-card>
        <mat-card fxFlex="30"  class="hassle-free-consultations-card"  fxLayout="column">
            <div class="oma-card-header" fxLayout="column" fxLayoutAlign="center center">
                <p>200+</p>
                <div>Service Providers</div>
            </div>                
        </mat-card>
        <mat-card fxFlex="30"  class="hassle-free-consultations-card" fxLayout="column">
            <div class="oma-card-header" fxLayout="column" fxLayoutAlign="center center">
                <p>200+</p>
                <div>Successful Appts.</div>
            </div>                
        </mat-card>
        
    </div>
    <div class="contact-form" fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="15px">
        <div class="contact-info" fxFlex="100" fxFlex.gt-sm="50">
            <div class="contact-form-title">
                Contact Now
            </div>
            <div fxLayout="column">
                <div fxLayout="row" class="contact-row">
                    <div class="row-icon"  fxLayoutAlign="center center"><img src="../../../assets/images/oma-message-ison.png" alt=""> </div>
                    <div class="row-info">Lorem ipsum dolor sit amet</div>
                </div>
                <!--  -->
                <div fxLayout="row" class="contact-row">
                    <div class="row-icon"  fxLayoutAlign="center center"><img src="../../../assets/images/oma-phone-icon.png" alt=""> </div>
                    <div class="row-info">Lorem ipsum dolor sit amet</div>
                </div>
                <!--  -->
                <div fxLayout="row" class="contact-row">
                    <div class="row-icon" fxLayoutAlign="center center"><img src="../../../assets/images/oma-map-icon.svg" alt=""> </div>
                    <div class="row-info">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id nisl laoreet tellus habitasse. Suspendisse vel blandit eget dui tellus.</div>
                </div>
            </div>
        </div>
        <div class="form-contact" fxFlex="100" fxFlex.gt-sm="50" fxLayout="column" fxLayoutGap="15px">
            <div >
                <input type="text" class="form-control" placeholder="Enter Name">
            </div>
            <!--  -->
            <div >
                <input type="text" class="form-control" placeholder="Enter Email">
            </div>
            <!--  -->
            <div >
                <textarea name="" id="" class="form-control" placeholder="Share your thoughts" rows="5"></textarea>
            </div>
            <!--  -->
            <div>
                <button mat-raised-button class="oma-contact-btn">Send Message</button>
            </div>
        </div>
    </div>
    <div fxLayout="column" class="footer" fxLayoutAlign="center center" fxLayoutGap="20px">
        
        <div class="social-media-links" fxLayout="row" fxLayoutGap="20px">
            <a href=""><img src="../../../assets/images/facebook-icon.png" alt=""></a>
            <a href=""><img src="../../../assets/images/twitter-icon.png" alt=""></a>
            <a href=""><img src="../../../assets/images/instagram-icon.png" alt=""></a>
            <a href=""><img src="../../../assets/images/linkedin-icon.png" alt=""></a>
            <a href=""><img src="../../../assets/images/youtube-icon.png" alt=""></a>
            
        </div>
        <div class="oma-terms" fxLayoutGap="20px">
            <a href="#">Privacy Policy</a>
            <span>|</span>
            <a href="#"> Terms of Conditions</a>
        </div>
    </div>
</section>