import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AuthClass } from 'src/app/helper/auth-class';
import { HelperClass } from 'src/app/helper/helper-class';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  webtoken: string = "";
  constructor(
    private TitleService: Title,
    private router: Router
  ) {
    
   }

  ngOnInit() {  
    this.TitleService.setTitle("Admin Dashboard");
    const auth = new AuthClass(this.router);  
    auth.checkTokenExpired();    
  }  
}