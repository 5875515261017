<div class="header">
    Clinic
</div>
<div class="body-content">
    <button mat-raised-button (click)="AddNew()">Add</button>
    <div class="row">
        <div class="col-md-4">
            <ul class="list-group">
                <li class="list-group-item" *ngFor="let item of lists; index as i" (click)="removeItem(i)">{{item}}</li>
            </ul>
        </div>
    </div>

      
</div>