import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user/user.service';
import { MustMatch } from 'src/app/_helpers/must-match.validator';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {
  submitted = false;
  confirmOTPdiv = false;
  btnRegisterDisable = true;
  accountCreated = false;
  mobileVerified = false;
  User: any = [{ id: 2, cat: 'Doctor' }, { id: 3, cat: 'Patient' }];
  errMsg: string = "";
  txtOtp = "";
  generatedOtp = "";
  successMsg: string = "";
  // loginForm: FormGroup = this._fb.group({});
  emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";
  public myData: FormGroup = this._fb.group({});
  constructor(
    private TitleService: Title,
    private _fb: FormBuilder,
    private UserService: UserService,
    private router: Router,
    private _snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.TitleService.setTitle("OMA - Sign Up");
    this.createForm();
  }

  createForm() {

    this.myData = this._fb.group({
      first_name: new FormControl('', [Validators.required]),
      last_name: new FormControl('', [Validators.required]),
      email_id: new FormControl('', [Validators.required, Validators.pattern(this.emailPattern)]),
      mobile_no: new FormControl('', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]),
      gender: new FormControl('', [Validators.required]),
      password: new FormControl('123456', [Validators.required, Validators.minLength(6), Validators.maxLength(20)]),
      confirmPassword: new FormControl('123456', [Validators.required]),
      user_cat: new FormControl('', [Validators.required]),
      country_code: new FormControl('', [Validators.required]),
      txtOtp: new FormControl('', [])
    }, {
      validator: MustMatch('password', 'confirmPassword')
    });
  }
  // convenience getter for easy access to form fields
  get f() { return this.myData.controls; }
  onSubmit() {
    this.submitted = true;
    this.btnRegisterDisable = false;
    if (this.myData.valid) {
      let data = {
        first_name: this.myData.controls['first_name'].value,
        last_name: this.myData.controls['last_name'].value,
        full_name: this.myData.controls['first_name'].value + " " + this.myData.controls['last_name'].value,
        email: this.myData.controls['email_id'].value,
        mobile: this.myData.controls['mobile_no'].value,
        gender: this.myData.controls['gender'].value,
        password: this.myData.controls['password'].value,
        username: this.myData.controls['email_id'].value,
        user_cat: this.myData.controls['user_cat'].value,
        country_code: this.myData.controls['country_code'].value,
        created_by: 0,
        status: 1
      }
      if (this.mobileVerified) {
        this.UserService.createUser(data).subscribe((res: any) => {
          // console.log(res);
          if (res['errors']) {
            const err = res['errors'];
            err.forEach((ele: any) => {
              // console.log(ele['param']+" : "+ele['msg']);
              // console.log(ele['param']);
              if (ele['param'] == "email") {
                const matchingControl = this.myData.controls['email_id'];
                matchingControl.setErrors({ emailExist: true, message: ele['msg'] });
              } else if (ele['param'] == "mobile") {
                const matchingControl = this.myData.controls['mobile_no'];
                matchingControl.setErrors({ mobileExist: true, message: ele['msg'] });
              }

            });
            this.btnRegisterDisable = true;
            this.accountCreated = false;
          } else {
            if (res['status']) {

              this.accountCreated = true;
              this.openSnackBar(res['message']);
              // this.dialogRef.close(this.myData.value);
              // this.btnRegisterDisable = false;            
            }
          }
        }, (err: any) => {
          // console.log(err['Result']);                
        }, () => {
          // console.log('completed');
        })
      } else {
        const matchingControl = this.myData.controls['mobile_no'];
        matchingControl.setErrors({ mobileNotVerified: true, message: 'Mobile no is not verified.' });
        this.myData.controls['mobile_no'].markAsTouched();
        this.btnRegisterDisable = true;
        this.accountCreated = false;
        return;
      }
    } else {
      this.btnRegisterDisable = true;
      return;
    }
  }
  openSnackBar(message: string) {
    this._snackBar.open(message, 'OK', {
      duration: 2000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
    });
  }

  verifyMobileNo() {
    let data = {
      mobile: this.myData.controls['mobile_no'].value,
      country_code: this.myData.controls['country_code'].value
    }
    if (this.myData.controls['mobile_no'].value != "") {


      this.UserService.sendOtp(data).subscribe((res: any) => {

        if (res['status']) {
          this.confirmOTPdiv = true;
          this.generatedOtp = res['otp'];
        } else {
          this.confirmOTPdiv = false;
          const matchingControl = this.myData.controls['mobile_no'];
          matchingControl.setErrors({ mobileExist: true, message: res['message'] });
          this.myData.controls['mobile_no'].markAsTouched();
          return;
        }

      });
    } else {
      this.confirmOTPdiv = false;
      // alert("Please enter mobile no.");

      const matchingControl = this.myData.controls['mobile_no'];
      matchingControl.setErrors({ mobileRequired: true, message: 'Please enter mobile no.' });
      this.myData.controls['mobile_no'].markAsTouched();
      return;
    }
  }
  // 
  confirmMobileNo() {
    this.txtOtp = this.myData.controls['txtOtp'].value;
    if (this.txtOtp) {
      if (this.generatedOtp === this.txtOtp) {
        this.mobileVerified = true;
        this.confirmOTPdiv = false;
        const matchingControl = this.myData.controls['mobile_no'];
        matchingControl.clearValidators();
        matchingControl.updateValueAndValidity();
      } else {
        // alert('Please enter valid OTP.');
        const matchingControl = this.myData.controls['txtOtp'];
        matchingControl.setErrors({ otpRequired: true, message: 'Please enter OTP.' });
        this.myData.controls['txtOtp'].markAsTouched();
      }
    } else {
      const matchingControl = this.myData.controls['txtOtp'];
      matchingControl.setErrors({ otpRequired: true, message: 'Please enter OTP.' });
      this.myData.controls['txtOtp'].markAsTouched();
      return;
    }

  }
}
