<div fxLayout="row" class="wrapper" fxFlex>
    <div fxHide fxShow.gt-sm class="login-image" fxFlex="35">
        <img src="../../../assets/images/oma-doctor-login-side-image.png" width="100%" alt="">
    </div>
    <div class="login-div" fxFlex  fxFlex.gt-sm="65" 
    fxLayout="column"  
    fxLayoutGap="15px"
    >
        <div class="login-header" fxLayout="row" 
        fxLayoutAlign="start" fxLayoutAlign="space-between center" fxLayoutGap="15px">
            <div>
                <img title="OMA HEALTH LOGO" [routerLink]="['']"  ngStyle.md="width: 180px; height: 50px;" 
                src="../../../assets/images/oma-health-logo.svg" >
            </div>
            <div class="login-menu" fxLayout="row" fxLayoutGap="20px">
                <a [routerLink]="[ '/doctor-login']" class="active">Log In</a>
                <a [routerLink]="[ '/doctor-signup']">Sign Up</a>
            </div>
        </div>
        <div class="login-form">
            <div class="form-header">
                Welcome Doctor
            </div>
            <form [formGroup]="loginForm" (ngSubmit)="loginFormSubmit()">
            <div class="oma-wrapper-content" fxLayout="column" fxLayoutGap="20px"
            ngStyle.gt-sm="width:60%;" >
            
                <div fxLayout="column">
                    <div class="label">Name :</div>
                    <input type="text" matInput formControlName="full_name">
                </div>
                <!--  -->
                <div fxLayout="column">
                    <div class="label">Doctor Number :</div>
                    <input type="text" matInput formControlName="doctor_license_no">
                </div>
                <!--  -->
                <div fxLayout="column">
                    <div class="label">Password :</div>
                    <input type="password" matInput formControlName="password">
                </div>
                <!--  -->
                <div *ngIf="errMsg" class="error-message">
                    {{errMsg}}
                </div>
                <div *ngIf="successMsg" class="success-message">{{successMsg}}</div>
                <div fxLayout="row">
                    <button mat-raised-button type="submit" class="oma-btn-primary oma-btn" style="width: 133px;">Log In</button>
                </div>
                <!--  -->
                <div fxLayout="column" class="footer-links">
                    <div>
                        Don’t have an account? <a href="#" [routerLink]="['/doctor-signup']">Sign Up </a>
                    </div>
                    <div>
                        Forgot Password? <a href="#" [routerLink]="['/doctor-forgot']"> Change Now</a>
                    </div>
                </div>
                
            </div>
        </form>
        </div>
    </div>
</div>

