import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-add-family-member',
  templateUrl: './add-family-member.component.html',
  styleUrls: ['./add-family-member.component.scss']
})
export class AddFamilyMemberComponent implements OnInit {
  memberForm: FormGroup = this._formBuilder.group({});
  selectedMemberPhoto: string = "";
  public imagePath: string = "";
  imgURL: any;
  user_id: any;
  public message: string;
  constructor(
    @Inject(MAT_DIALOG_DATA) public reqData: any,
    public dialogRef: MatDialogRef<AddFamilyMemberComponent>,
    private _formBuilder: FormBuilder,
    private userService: UserService,
  ) { }

  ngOnInit() {
    this.user_id = localStorage.getItem('pid');
    this.createMemberForm();
  }

  onNoClick(): void {
    this.dialogRef.close({status: 0});
  }
  async onSubmit() {
    if (this.memberForm.valid) {
      // let data = {
      //   name: this.memberForm.controls['memberName'].value,
      //   age: this.memberForm.controls['memberAge'].value,
      //   relation: this.memberForm.controls['relation'].value,
      //   user_id: localStorage.getItem('pid')
      // };
      let message = "";
      var frm = new FormData();
      frm.append('user_id', this.user_id);
      frm.append('name', this.memberForm.controls['memberName'].value);
      frm.append('relation', this.memberForm.controls['relation'].value);
      frm.append('gender', this.memberForm.controls['gender'].value);
      frm.append('dob', this.memberForm.controls['dob'].value);
      frm.append('photo', this.selectedMemberPhoto);

      await this.userService.addNewFamilyMember(frm).subscribe((res: any) => {
        if(res['status']){
          message = res['message'];
        }else{
          message = res['message'];
        }
      });
      
      this.dialogRef.close({status: 1, message: message});
    }else{

    }
    
  }
  createMemberForm(){
    this.memberForm = new FormGroup({
      memberName: new FormControl('', [Validators.required]),
      memberAge: new FormControl('', [Validators.required]),
      relation: new FormControl('', [Validators.required]),      
      dob: new FormControl('', [Validators.required]),      
      gender: new FormControl('', [Validators.required])      
    });
  }
  onSelectSignatureFile(event: any) {
    if (event.target.files.length > 0) {
      this.selectedMemberPhoto = event.target.files[0];
    }
    if (event.target.files.length === 0)
      return;
 
    var mimeType = event.target.files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.message = "Only images are supported.";
      return;
    }
 
    var reader = new FileReader();
    this.imagePath = event.target.files;
    reader.readAsDataURL(event.target.files[0]); 
    reader.onload = (_event) => { 
      this.imgURL = reader.result; 
    }
  }

}
