import { Component, OnInit, Inject, AfterViewInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
//import { MustMatch } from '../../_helpers/must-match.validator';
import { Title } from '@angular/platform-browser';
import { DivServiceService } from 'src/app/services/div-service.service';
import { ModalManagerService } from 'src/app/services/modal-manager.service';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';

export interface DivData {
  id: string;
  name: string;
}

@Component({
  selector: 'app-organization',
  templateUrl: './organization.component.html',
  styleUrls: ['./organization.component.scss']
})
export class OrganizationComponent implements OnInit {

  dialog_res: string = "";
  divData: any;
  listName: String = '';
  lists = <any>[];
  submitted = false;

  constructor(
    private TitleService: Title,
    private fb: FormBuilder,
    private mms: ModalManagerService,
    private divService: DivServiceService
  ) { 
  }

  ngOnInit() {
    this.TitleService.setTitle("OMA Organization");
  }

  // loadData(){
  //   this.divService.getDivision().subscribe(res => {
  //     this.dataSource = new MatTableDataSource(res);
  //     console.log(this.divData);
  //   })
  // }
 
  // get f() { return this.registerForm.controls; }
  AddNew() {
    //this.lists.push(this.listName);
    this.mms.addDivision({name: 'Rahul'}).subscribe(res => {
      console.log(res);
    })
  }
  removeItem(i: any) {
    console.log(i);
    this.lists.splice(i, 1);
  }

  frmSubmit() {
    this.submitted = true;
  }
  onReset() {
        this.submitted = false;
        // this.registerForm.reset();
    }
}
