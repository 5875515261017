import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { HelperClass } from 'src/app/helper/helper-class';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-doctor-login',
  templateUrl: './doctor-login.component.html',
  styleUrls: ['./doctor-login.component.scss']
})
export class DoctorLoginComponent implements OnInit {
  errMsg: string = "";
  successMsg: string = "";
  loginForm: FormGroup = this._fb.group({});
  redirectTo: any;
  constructor(
    private TitleService: Title,
    private _fb: FormBuilder,
    private UserService: UserService,
    private router: Router,
    private Activatedroute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.TitleService.setTitle("OMA Login");
     this.redirectTo = <String> this.Activatedroute.snapshot.queryParamMap.get('url');
    this.createForm();
  }

  createForm(){
    this.loginForm = this._fb.group({
      full_name: new FormControl('', [Validators.required]),
      doctor_license_no: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required])
    });
  }

  loginFormSubmit(){
    if(this.loginForm.valid){      
      let data = {
        full_name: this.loginForm.controls['full_name'].value,
        doctor_license_no: this.loginForm.controls['doctor_license_no'].value,
        password: this.loginForm.controls['password'].value
      }
      this.errMsg = "";
      this.successMsg = "";
      this.UserService.DrLogin(data).subscribe( (res: any) => {
        // console.log(res);  
        if(res['status']){
          let udata = res['data'];
          // console.log(udata);
          this.successMsg = "Login successful.";
          if(udata.id>1){
            // localStorage.setItem('webtoken', res['token']);            
            if(udata.user_category_id=="2"){
              localStorage.setItem('uid', udata.id);          
              localStorage.setItem('user_cat_id', udata.user_category_id);
              localStorage.setItem('full_name', udata.full_name);
              localStorage.setItem('photo', udata.photo);
              
              const helper = new HelperClass();
              // 1 min = 60000
              helper.setWithExpiry('drtoken', res['token'], 3600000);
              
              this.router.navigate(['doctor']);
            }            
            
          }         
          
        }else{
          this.errMsg = res['message'];
          return;
        }
      })
    }else{
      this.errMsg = "Please enter valid login details.";
      return;
    }    
  }
}
