<h1 mat-dialog-title>
    <mat-icon>edit</mat-icon> Update User <span *ngIf="user_category_id==='2'">( Profile: Doctor )</span>
</h1>
<div mat-dialog-content>
    <!--  -->
    <mat-horizontal-stepper #stepper>
        <mat-step [stepControl]="personalForm" errorMessage="* fields is required.">
            <form [formGroup]="personalForm" (ngSubmit)="onSubmit(stepper)" #formone="ngForm">
                <ng-template matStepLabel>Personal Details</ng-template>
                <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign="start start" fxLayoutGap="40px">

                    <div fxFlex="50" fxLayout="column" fxLayoutGap="20px">
                        <!-- <mat-list>
                            <mat-list-item>Item 1</mat-list-item>
                            <mat-divider></mat-divider>
                        </mat-list> -->
                        <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign="space-between center"
                            fxLayoutGap="20px">
                            <mat-form-field>
                                <mat-label>Name</mat-label>
                                <input matInput placeholder="First name" formControlName="first_name" required>
                            </mat-form-field>
                            <mat-form-field fxHide>
                                <mat-label>Last Name</mat-label>
                                <input matInput placeholder="Last name" formControlName="last_name">
                            </mat-form-field>
                        </div>
                        <div fxLayout="column" fxLayoutAlign="space-between center" fxLayoutGap="20px">
                            <mat-form-field appearance="legacy">
                                <mat-label>Email Id</mat-label>
                                <input matInput placeholder="Email Id" formControlName="email_id" required readonly>
                            </mat-form-field>

                        </div>

                        <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign="space-between center"
                            fxLayoutGap="0px">
                            <mat-form-field fxFlex="50">
                                <mat-label>Mobile No</mat-label>
                                <input matInput placeholder="Mobile No" formControlName="mobile_no" required readonly>
                            </mat-form-field>
                            <div fxLayout="column">
                                <p></p>
                                <mat-label>Gender</mat-label>
                                <mat-radio-group aria-label="Gender" color="primary" fxFlex formControlName="gender">
                                    <mat-radio-button value="Male">Male</mat-radio-button>
                                    <mat-radio-button value="Female" class="m-l-10">Female</mat-radio-button>
                                </mat-radio-group>

                            </div>
                        </div>
                        <!--  -->
                        <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px">
                            <mat-form-field>
                                <mat-label>Address</mat-label>
                                <textarea matInput placeholder="Address" formControlName="address" required></textarea>
                            </mat-form-field>
                            <mat-form-field>
                                <mat-label>Landmark</mat-label>
                                <textarea matInput placeholder="Landmark" formControlName="landmark"
                                    required class="form-control"></textarea>
                            </mat-form-field>
                        </div>
                        <!--  -->
                        <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px">
                            <mat-form-field>
                                <mat-label>Pincode</mat-label>
                                <input matInput placeholder="Pincode" formControlName="pincode" required>
                            </mat-form-field>
                            <div>
                                <mat-label>Status</mat-label>
                                <p></p>
                                <mat-slide-toggle formControlName="status" color="warn">Active</mat-slide-toggle>
                                <mat-slide-toggle formControlName="admin_approved" color="warn">Admin approved</mat-slide-toggle>
                            </div>

                        </div>

                    </div>
                    <div fxFlex="50" fxLayout="column" fxLayoutGap="20px">
                        <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px">
                            <mat-label>Photo</mat-label>
                            <img [src]="url" height="160px" width="140px"> <br />
                            <input type='file' #fileInput (change)="onSelectFile($event)" name="user_photo">
                            <span mat-raised-button color="primary" (click)="uploadFile()">Upload</span>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px">
                            <mat-form-field fxFlex="50">
                                <mat-label>Date of Birth (YYYY-MM-DD)</mat-label>
                                <input matInput [matDatepicker]="picker" formControlName="dob" required>
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px">
                            <mat-form-field>
                                <mat-label>Weight (cm)</mat-label>
                                <input matInput placeholder="Weight (cm)" formControlName="weight" required>
                            </mat-form-field>
                            <mat-form-field>
                                <mat-label>Height (kg)</mat-label>
                                <input matInput placeholder="Height (kg)" formControlName="height" required>
                            </mat-form-field>
                        </div>
                    </div>
                </div>

                <div>
                    <!--matStepperNext <p>Go to a different step to see the error state</p> -->
                    <p></p>
                    <button mat-raised-button type="submit" color="primary" [disabled]="personalForm.invalid"> Save & Next
                        <mat-icon>east</mat-icon> </button>
                </div>
            </form>
        </mat-step>
        <mat-step [stepControl]="secondFormGroup" errorMessage="Address is required.">
            <form [formGroup]="secondFormGroup">
                <ng-template matStepLabel>Clinic Details</ng-template>
                <mat-form-field>
                    <mat-label>Address</mat-label>
                    <input matInput placeholder="Ex. 1 Main St, New York, NY" formControlName="secondCtrl" required>
                </mat-form-field>
                <div>
                    <p>Go to a different step to see the error state</p>
                    <button mat-button matStepperPrevious>Back</button>
                    <button mat-button matStepperNext>Next</button>
                </div>
            </form>
        </mat-step>
        <mat-step>
            <ng-template matStepLabel>Doctor Settings</ng-template>
            <!--  -->

            <!--  -->
            <p>You are now done.</p>
            <div>
                <button mat-button matStepperPrevious>Back</button>
                <button mat-button (click)="stepper.reset()">Reset</button>
            </div>
        </mat-step>
    </mat-horizontal-stepper>

    <!--  -->

</div>
<div mat-dialog-actions>
    <!-- <button mat-raised-button type="submit" color="primary">
        <mat-icon>check</mat-icon> Update
    </button> -->
    <button mat-raised-button mat-dialog-close>Cancel</button>
</div>