<div mat-dialog-title class="timeslot-title">
    Update Profile Photo
</div>
<div mat-dialog-content class="modal-body">

    <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="20px">
        <div>
            <img class="mat-avatar"  [src]="photoUrl">
        </div>
    
        <div>
            <input fxFlex type="file" #fileInput
            accept="image/png, image/gif, image/jpeg"
            (change)="onSelectFile($event)">
        </div>
    </div>
</div>
<div mat-dialog-actions fxLayout="row" fxLayoutGap="20px">
    <br>
    <button mat-raised-button class="oma-gray-btn" color="primary" (click)="onNoClick()">No Thanks</button>
    <button mat-raised-button class="oma-default-btn" color="primary" (click)="onSubmit()" cdkFocusInitial>Save Changes</button>
  </div>