<div mat-dialog-title class="timeslot-title">Add Timeslot - {{getDay(reqData.for_date)}} : {{reqData.for_date | date: 'yyyy-MM-dd'}}</div>
<div mat-dialog-content class="modal-body" [formGroup]="timeslotForm">
  <br>
  <div>
  <div class="row">
    <mat-radio-group aria-label="Select an option" fxLayoutGap="20px">
      <mat-radio-button color="primary" (click)="setAvgTimePerCons('Virtual Consultation')" class="rbd-virtual-consultation" value="1">Virtual Consultation</mat-radio-button>
      <mat-radio-button color="accent" (click)="setAvgTimePerCons('In Person Appointment')" class="rbd-in-person-appointment" value="2">In Person Appointment</mat-radio-button>
    </mat-radio-group>
  </div>
  <br>
  <!--  -->
  <div fxLayout="column" fxLayoutGap="20px">
    <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign="start start" fxLayoutGap="20px">      
        <label fxFlex="40">Avg time of Consultation<span class="required-star">*</span> :</label>
        <input fxFlex="40"  class="form-control" formControlName="avg" readonly  placeholder="Avg time of Consultation" required>(Min)      
    </div>
    <div>
      <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign="start start" fxLayoutGap="20px">
        <label fxFlex="40">Open Time<span class="required-star">*</span> :</label>
        <!-- <input fxFlex="20"  class="form-control"  placeholder="HH:MM" required> -->
        <select fxFlex="20" formControlName="openTimeHH" class="form-control" placeholder="HH">
          <option value="">HH</option>
          <option *ngFor="let hh of ['00','01','02','03','04','05','06','07','08','09',10,11,12,13,14,15,16,17,18,19,20,21,22,23,24]">{{hh}}</option>
        </select>
        <select fxFlex="20" formControlName="openTimeMM" class="form-control" placeholder="MM">
          <option value="">MM</option>
          <option *ngFor="let mm of ['00','05',10,15,20,25,30,35,40,45,50,55,60]">{{mm}}</option>
        </select>
        <!-- <input [ngxTimepicker]="picker"> -->
        <!-- <ngx-material-timepicker #picker></ngx-material-timepicker> -->
        
      </div>
    </div>
    <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign="start start" fxLayoutGap="20px">
      <label fxFlex="40">Close Time<span class="required-star">*</span> :</label>
        <!-- <input fxFlex="20"  class="form-control"  placeholder="HH:MM" required> -->
        <select fxFlex="20" formControlName="closeTimeHH" class="form-control" placeholder="HH">
          <option value="">HH</option>
          <option *ngFor="let hh of ['00','01','02','03','04','05','06','07','08','09',10,11,12,13,14,15,16,17,18,19,20,21,22,23,24]">{{hh}}</option>
        </select>
        <select fxFlex="20" formControlName="closeTimeMM" class="form-control" placeholder="MM">
          <option value="">MM</option>
          <option *ngFor="let mm of ['00','05',10,15,20,25,30,35,40,45,50,55,60]">{{mm}}</option>
        </select>
    </div>
    <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign="start start" fxLayoutGap="20px">
      <label fxFlex="40"></label>
      <button mat-raised-button color="primary" class="oma-default-btn" (click)="generateTimeSlots()" >Generate Timeslots</button>
    </div>
  </div>
  <div mat-dialog-title class="timeslot-title" fxLayout="row" fxLayoutAlign="space-between center">
    <span>Time Slots:</span>
    <mat-checkbox class="example-margin" (change)="CheckAllTimeSlot($event)" [checked]="CheckedAllTimeSlot" formControlName="check_all" color="primary">Check All</mat-checkbox> 
  </div>
  <div class="timeslot-view" fxLayout="row wrap" fxLayoutAlign="strat start" fxLayoutGap="20px">
    <button mat-stroked-button [color]="timeSlotColor" *ngFor="let timeslot of timeSlotsArr; let i = index;">
      <mat-checkbox class="example-margin" [color]="timeSlotColor" (change)="checkedOne(i, $event)" [checked]="timeslot.checked">{{timeslot.start_time}} - {{timeslot.end_time}}</mat-checkbox> 
    </button>
    <p *ngIf="timeSlotsArr.length==0 && clickedOnGenerateTimeSlot">Please select valid time.</p>
  </div>
  </div>
</div>
<div mat-dialog-actions fxLayout="row" fxLayoutGap="20px">
  <br>
  <button mat-raised-button class="oma-gray-btn" color="primary" (click)="onNoClick()">No Thanks</button>
  <button mat-raised-button class="oma-default-btn" color="primary" (click)="onSubmit()" cdkFocusInitial>Save Changes</button>
</div>