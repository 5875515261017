import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalManagerService } from 'src/app/services/modal-manager.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { MatSidenav } from '@angular/material/sidenav';
import { CmsService } from 'src/app/services/cms/cms.service';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit {
  loggedUserName: any = null;
  user_photo = "";
  myUserId: any = 0;
  temporaryDisabled: boolean = false;
  @ViewChild('drawer') public drawer: MatSidenav;
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );
  constructor(
    private breakpointObserver: BreakpointObserver,
    private mms: ModalManagerService,
    private cms: CmsService
    ) { }

  ngOnInit() {
    this.myUserId = 0;
    if(localStorage.getItem('pid')){
      this.myUserId = localStorage.getItem('pid');
    }    
    // console.log(this.myUserId);    
  }
  onHeaderLoad(){
    this.myUserId = 0;
    if(localStorage.getItem('pid')){
      this.myUserId = localStorage.getItem('pid');
    }    
    console.log(this.myUserId);
  }
  openSideNav(){
    this.drawer.toggle();
  }
  
  openSideMenu(){
    this.cms.sideNavToggleSubject.subscribe(()=>{
      this.drawer.toggle();
    });
    // this.mms.cmsRightSideMenu({data: ''}).subscribe((res: any) => {
      
    // });
  }
  

}
