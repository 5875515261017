<div class="dialog-booking-confirmed" fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="20px">
    <div>
        <img src="../../../../assets/images/oma-Quick-Query-Booking-Confirmed.png" alt="">
    </div>
    <div class="oma-card-header">Your Question has been posted to doctor.</div>
    <div fxLayout="row" fxLayoutGap="8px">
        <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="center center">
            
            <span class="text-center">Please wait for a while till the doctor replies to your question.</span>
        </div>
        
    </div>
    <div fxLayout="column" class="text-center">
        <br>
        <br>
        <button mat-raised-button (click)="onClickBackToHome()"  class="oma-btn-primary oma-btn">Back To Home</button>
        
    </div>
    
</div>