import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthClass } from 'src/app/helper/auth-class';
import { HelperClass } from 'src/app/helper/helper-class';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
    this.logout();
  }

  logout(){
    const auth = new AuthClass(this.router);
    auth.logout();
  }

}
