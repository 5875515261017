<div fxLayout="row" class="wrapper" fxFlex>
    <div fxHide fxShow.gt-sm class="login-image" fxFlex="55" fxLayoutAlign="center center">
        <img src="../../../assets/images/OMA-patient-login-page-image.png" alt="">
    </div>
    <div class="login-div" fxFlex fxFlex.gt-sm="45" fxLayout="column" fxLayoutGap="15px">
        <div class="login-header" fxLayout="row" fxLayoutAlign="start" fxLayoutAlign="space-between center"
            fxLayoutGap="15px" *ngIf="activeStep==1">
            <div>
                <img title="OMA HEALTH LOGO" [routerLink]="['/']" ngStyle.md="width: 180px; height: 50px;"
                    src="../../../assets/images/oma-health-logo.svg">
            </div>
            <div class="login-menu" fxLayout="row" fxLayoutGap="20px">
                <a [routerLink]="[ '/login']">Log In</a>
                <a [routerLink]="[ '/patient-signup']" class="active">Sign Up</a>
            </div>
        </div>
        <div class="login-form">
            <div class="form-header" *ngIf="activeStep==1">
                Welcome Back
            </div>
            <form [formGroup]="signUpForm" (ngSubmit)="signUpFormSubmit()" *ngIf="activeStep==1">
                <div class="oma-wrapper-content" fxLayout="column" fxLayoutGap="20px" ngStyle.gt-sm="width:80%;">

                    <div fxLayout="column">
                        <div class="label">Email :</div>
                        <input type="text" matInput formControlName="email_id" required>
                        <mat-error *ngIf="signUpForm.get('email_id')?.hasError('emailExist')">Email Id already exist.</mat-error>
                    </div>
                    <!--  -->
                    <div fxLayout="column">
                        <div class="label">Password :</div>
                        <input type="password" matInput formControlName="password" required>
                    </div>
                    <!--  -->
                    <div fxLayout="column">
                        <div class="label">Confirm Password :</div>
                        <input type="password" matInput formControlName="confirmPassword" required>
                    </div>
                    <!--  -->
                    <div *ngIf="errMsg" class="error-message">
                        {{errMsg}}
                    </div>
                    <span *ngIf="doctorLoginLink">For Doctor login <a [routerLink]="[ '/doctor-login',]">Click
                            here</a></span>
                    <div *ngIf="successMsg" class="success-message">{{successMsg}}</div>
                    <div fxLayout="row" class="t-and-c" fxLayoutGap="15px">
                        <div>
                            <mat-checkbox class="example-margin" color="primary" 
                            formControlName="t_and_c" (change)="onChecktAndc($event.checked)"></mat-checkbox>
                        </div>
                        <div>
                            I hereby accept the <a href="#" [routerLink]="[ '/patient-signup' ]">Terms and Conditions</a><br> and <a href="#" [routerLink]="[ '/patient-signup' ]">Privacy Policy</a> of Oma Health.
                        </div>
                        <mat-error *ngIf="signUpForm.get('t_and_c')?.hasError('isChecked')">Please check terms and conditions.</mat-error>
                    </div>
                    <div fxLayout="row">
                        <button mat-raised-button type="submit" class="oma-btn-primary oma-btn"
                            [disabled]="signUpForm.invalid">Proceed</button>
                    </div>
                    <!--  -->
                    <div fxLayout="column" class="footer-links">
                        <div>
                            Already have an account <a href="#" [routerLink]="[ '/login' ]"> Click here </a>
                        </div>
                    </div>

                </div>
            </form>
            <!--  -->
            <form [formGroup]="aboutPatientForm" (ngSubmit)="aboutPatientFormSubmit()" 
            *ngIf="activeStep==2">
                <div class="oma-wrapper-content" fxLayout="column" fxLayoutGap="20px" ngStyle.gt-sm="width:80%;">
                    <div fxLayoutAlign="center center" fxLayout="column">
                        <div class="patient-photo">
                            <img [src]="photoUrl" alt="">
                        </div>
                        <button type="button" mat-icon-button class="camera-icon" fxLayoutAlign="center center"
                        (click)="selectPhoto()">
                            <img src="../../../assets/images/OMA-Camera-icon.png" alt="">
                            <input type="file" id="userPhotoFile" style="background: transparent;position: absolute;left: 0;height: 40px;"
                            (change)="onSelectUserPhoto($event)" #fileInput>
                        </button>
                    </div>
                    <div fxLayout="column">
                        <div class="label">Name :</div>
                        <input type="text" matInput formControlName="full_name" required>
                        <mat-error *ngIf="aboutPatientForm.get('full_name')?.hasError('required')">Full name is required.</mat-error>
                    </div>
                    <!--  -->
                    <div fxLayout="column">
                        <div class="label">Fiscal Number :</div>
                        <input type="text" matInput formControlName="fiscal_number" required>
                        <mat-error *ngIf="aboutPatientForm.get('fiscal_number')?.hasError('required')">Fiscal number is required.</mat-error>
                    </div>
                    <!--  -->
                    <div fxLayout="column">
                        <div class="label">Health Number :</div>
                        <input type="text" matInput formControlName="health_number" required>
                        <mat-error *ngIf="aboutPatientForm.get('health_number')?.hasError('required')">Health number is required.</mat-error>
                    </div>
                    <!--  -->
                    <div fxLayout="column">
                        <div class="label">DOB :</div>
                        <!-- <input type="text" matInput formControlName="dob" required> -->
                        <div fxLayout="row">
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <input type="text" [matDatepicker]="picker" class="form-control" id="inputDOB"
                                formControlName="dob" aria-describedby="inputDOBH" placeholder="Type Here">

                            <mat-datepicker #picker></mat-datepicker>
                        </div>
                        <mat-error *ngIf="aboutPatientForm.get('dob')?.hasError('required')">DOB is required.</mat-error>
                    </div>
                    <!--  -->
                    <div fxLayout="column">
                        <div class="label">Phone Number :</div>
                        <input type="text" matInput formControlName="phone_no" required>
                        <mat-error *ngIf="aboutPatientForm.get('phone_no')?.hasError('required')">Phone No is required.</mat-error>
                        <mat-error *ngIf="aboutPatientForm.get('phone_no')?.hasError('minlength') || aboutPatientForm.get('phone_no')?.hasError('maxlength')">Phone No is should be 10 digits.</mat-error>
                        <mat-error *ngIf="aboutPatientForm.get('phone_no')?.hasError('mobileExist')">Phone No already exist.</mat-error>
                        <mat-error *ngIf="aboutPatientForm.get('phone_no')?.hasError('mobileRequired')">Please enter phone no.</mat-error>
                        <mat-error *ngIf="aboutPatientForm.get('phone_no')?.hasError('pattern')">Please enter valid phone no.</mat-error>
                    </div>
                    <!--  -->
                    <div fxLayout="column">
                        <div class="label">Gender :</div>
                        <mat-radio-group aria-label="Gender" color="primary" fxFlex formControlName="gender"
                            fxLayout="row" fxLayoutGap="25px">
                            <mat-radio-button value="Male">Male</mat-radio-button>
                            <mat-radio-button value="Female" class="m-l-10">Female</mat-radio-button>
                            <mat-radio-button value="Other" class="m-l-10">Other</mat-radio-button>
                        </mat-radio-group>
                        <mat-error *ngIf="aboutPatientForm.get('gender')?.hasError('required')">Please select gender.</mat-error>
                    </div>
                    <!--  -->
                    
                    <!--  -->
                    <div *ngIf="errMsg" class="error-message">
                        {{errMsg}}
                    </div>
                    <span *ngIf="doctorLoginLink">For Doctor login <a [routerLink]="[ '/doctor-login',]">Click
                            here</a></span>
                    <div *ngIf="successMsg" class="success-message">{{successMsg}}</div>
                    
                    <div fxLayout="row">
                        <button mat-raised-button type="submit" class="oma-btn-primary oma-btn"
                            [disabled]="aboutPatientForm.invalid">Proceed</button>
                    </div>
                    <br>
                    <br>
                </div>
            </form>
            <!--  -->
            <!-- {{userSignUpData | json}} -->
            <form [formGroup]="personalDetailsForm" (ngSubmit)="personalDetailsFormSubmit()" 
            fxLayout="column" fxLayoutGap="20px"
            *ngIf="activeStep==3">
            <!-- *ngIf="activeStep==1"     -->
                <div class="form-header">
                    Persoanl Details
                </div>
                <div class="oma-wrapper-content" fxLayout="column" fxLayoutGap="20px" 
                ngStyle.gt-sm="width:80%;">
                    <div fxLayout="row" fxLayoutGap="20px" fxFlex=100>
                        <div fxLayout="column" fxFlex=50>
                            <div class="label">Height (inch) :</div>
                            <input type="text" matInput formControlName="height" required>
                            <mat-error *ngIf="personalDetailsForm.get('phone_no')?.hasError('pattern')">Please enter height in inch. only.</mat-error>
                        </div>
                        <!--  -->
                        <div fxLayout="column" fxFlex=50>
                            <div class="label">Weight (kg) :</div>
                            <input type="text" matInput formControlName="weight" required>
                            <mat-error *ngIf="personalDetailsForm.get('phone_no')?.hasError('pattern')">Please enter weight in KG only.</mat-error>
                        </div>
                    </div>
                </div>
                <div fxLayout="row">
                    <button mat-raised-button type="submit" class="oma-btn-primary oma-btn"
                     [disabled]="personalDetailsForm.invalid"
                        >Proceed</button>
                </div>
            </form>
            <div *ngIf="activeStep==4">
                <!--  -->
                <form [formGroup]="lifeStyleDetailsForm" (ngSubmit)="lifeStyleDetailsFormSubmit()" 
            fxLayout="column" fxLayoutGap="20px"
            >
            <!-- *ngIf="activeStep==1"     -->
                <div class="form-header">
                    Lifestyle Details
                </div>
                <div class="oma-wrapper-content" fxLayout="column" fxLayoutGap="20px" 
                ngStyle.gt-sm="width:80%;">
                    <div fxLayout="row" fxLayoutGap="20px" fxFlex=100>
                        <div fxLayout="column">
                            <div class="label">Smoking Habits :</div>
                            <div fxLayout="row wrap" fxLayoutGap="10px">
                                <button type="button" mat-raised-button class="oma-habit-btn oma-btn-rounded oma-btn"
                                    [ngClass]="{'oma-btn-default': selectedSmokingHabits == item.habit_name}"
                                    (click)="onSelectSmokingHabits(item.habit_name)"
                                    *ngFor="let item of SmokingHabits">{{item.habit_name}}</button>
                            </div>
                            <div class="text-danger" *ngIf="!selectedSmokingHabits">Please select smoking habits.</div>
                        </div>
                    </div>
                    <div fxLayout="row" fxLayoutGap="20px" fxFlex=100>
                        <div fxLayout="column">
                            <div class="label">Drinking Habits :</div>
                            <div fxLayout="row" fxLayoutGap="10px">
                                <button type="button" mat-raised-button class="oma-btn-rounded oma-btn"
                                    [ngClass]="{'oma-btn-default': selectedDrinkingHabits == item.habit_name}"
                                    (click)="onSelectDrinkingHabits(item.habit_name)"
                                    *ngFor="let item of DrinkingHabits">{{item.habit_name}}</button>
    
                            </div>
                            <div class="text-danger" *ngIf="!selectedDrinkingHabits">Please select drinking habits.</div>
                        </div>
                    </div>
                    <!--  -->
                    <div fxLayout="row" fxLayoutGap="20px" fxFlex=100>
                        <div fxLayout="column">
                            <div class="label">Physical Activity :</div>
                            <div fxLayout="row" fxLayoutGap="10px">
                                <button type="button" mat-raised-button class="oma-btn-rounded oma-btn"
                                    [ngClass]="{'oma-btn-default': selectedPhysicalActivity == item.habit_name}"
                                    (click)="onSelectPhysicalActivity(item.habit_name)"
                                    *ngFor="let item of PhysicalActivity">{{item.habit_name}}</button>
    
                            </div>
                            <div class="text-danger" *ngIf="!selectedPhysicalActivity">Please select physical activity.
                            </div>
                        </div>
                    </div>
                    <!--  -->
                    <div fxLayout="row" fxLayoutGap="20px" fxFlex=100>
                        <div fxLayout="column">
                            <div class="label">Diet :</div>
                            <div fxLayout="row" fxLayoutGap="10px">
                                <button type="button" mat-raised-button class="oma-btn-rounded oma-btn"
                                    [ngClass]="{'oma-btn-default': selectedDiet == item.habit_name}" 
                                    (click)="onSelectDiet(item.habit_name)"
                                    *ngFor="let item of Diet">{{item.habit_name}}</button>
    
                            </div>
                            <div class="text-danger" *ngIf="!selectedDiet">Please select diet.</div>
                        </div>
                    </div>
                </div>
                <div fxLayout="row">                    
                    <p *ngIf="errMsg" class="text-danger">{{errMsg}}</p>
                    <button mat-raised-button type="submit" class="oma-btn-primary oma-btn"
                     [disabled]="!habbitsFilled"
                        >Proceed</button>
                </div>
                <br>
                    <br>
            </form>
            </div>
            <div  class="footer-links" *ngIf="activeStep==5">
                <!--  -->
                <br>
                <div class="success-img" fxLayoutAlign="center center"
                >
                    <img src="../../../assets/images/oma-booking-confirmed-img.png" alt="">
                </div>
                <br>
                <br>
                <div class="form-header" >
                    Conratulations! Your account has been created Successfully.
                </div>
                <div>
                    <a href="#" [routerLink]="['/login']">Click here</a> to login your account.
                </div>
            </div>
        </div>
    </div>
</div>