import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './admin/dashboard/dashboard.component';
import { OrganizationComponent } from './admin/organization/organization.component';
import { UsersComponent } from './admin/users/users.component';
import { DoctorForgotComponent } from './auth/doctor-forgot/doctor-forgot.component';
import { DoctorLoginComponent } from './auth/doctor-login/doctor-login.component';
import { DoctorSignUpComponent } from './auth/doctor-sign-up/doctor-sign-up.component';
import { PatientForgotComponent } from './auth/patient-forgot/patient-forgot.component';
import { PatientLoginComponent } from './auth/patient-login/patient-login.component';
import { PatientSignUpComponent } from './auth/patient-sign-up/patient-sign-up.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { HeaderComponent } from './cms/header/header.component';
import { HomeComponent } from './cms/home/home.component';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { CmsLayoutComponent } from './layouts/cms-layout/cms-layout.component';
import { DoctorLayoutComponent } from './layouts/doctor-layout/doctor-layout.component';
import { ADMIN_ROUTES } from './routes/admin-layout-routes';
import { DEFAULT_ROUTES } from './routes/default-layout-routes';
import { DOCTOR_ROUTES } from './routes/doctor-layout-routes';

const routes: Routes = [
  { 
    path: '',    
    component: CmsLayoutComponent,
    children: DEFAULT_ROUTES
  },
  {
    path: 'login', component: PatientLoginComponent,
    data: {
      title: 'OMA Login'
    }
  },
  {
    path: 'patient-signup', component: PatientSignUpComponent,
    data: {
      title: 'OMA : Patient Sign Up'
    }
  },
  {
    path: 'doctor-login', component: DoctorLoginComponent,
    data: {
      title: 'OMA Doctor Login'
    }
  },
  {
    path: 'doctor-signup', component: DoctorSignUpComponent,
    data: {
      title: 'OMA : Doctor Sign Up'
    }
  },
  {
    path: 'admin',
    component: AdminLayoutComponent,
    data: {
      title: 'Admin'
    },
    children: ADMIN_ROUTES
    
  },
  { 
    path: 'doctor',    
    component: DoctorLayoutComponent,
    children: DOCTOR_ROUTES
  },
  {
    path: 'doctor-forgot', component: DoctorForgotComponent,
    data: {
      title: 'OMA : Doctor Forgot Password'
    }
  },
  {
    path: 'patient-forgot', component: PatientForgotComponent,
    data: {
      title: 'OMA : Patient Forgot Password'
    }
  },
  {
    path: 'reset-password/:id', component: ResetPasswordComponent
  },
  {
    path: '**',
    redirectTo: '404'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
