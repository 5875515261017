import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  apiBaseUrl: string = "";
  constructor(
    private config: ConfigService,
    private http: HttpClient
  ) {
    this.apiBaseUrl = config.getApiUrl();
  }

  getSpeciality(){
    return this.http.get(this.apiBaseUrl+'cms/get_speciality_master');
  }

  addSpeciality(data: any){
    return this.http.post(this.apiBaseUrl+'admin/add_speciality', data);
  }
  get_speciality_name(id: number){
    return this.http.get(this.apiBaseUrl+'admin/get_speciality_name/'+id);
  }

  updateSpeciality(data: any){
    return this.http.put(this.apiBaseUrl+'admin/update_speciality/', data);
  }

  updateProfileStatusByAdmin(data: any) {
    return this.http.put(this.apiBaseUrl + 'admin/update_dr_profile_status', data);
  }
}
