<div fxLayout="row" class="wrapper" fxFlex>
    <div fxHide fxShow.gt-sm class="login-image" fxFlex="35">
        <img src="../../../assets/images/oma-doctor-login-side-image.png" width="100%" alt="">
    </div>
    <div class="login-div" fxFlex  fxFlex.gt-sm="65" 
    fxLayout="column"  
    fxLayoutGap="15px"
    >
        <div class="login-header" fxLayout="row" 
        fxLayoutAlign="start" fxLayoutAlign="space-between center" fxLayoutGap="15px">
            <div>
                <img title="OMA HEALTH LOGO" [routerLink]="['']"  ngStyle.md="width: 180px; height: 50px;" 
                src="../../../assets/images/oma-health-logo.svg" >
            </div>
            <div class="login-menu" fxLayout="row" fxLayoutGap="20px">
                <a [routerLink]="[ '/doctor-login']" class="active">Log In</a>
                <a [routerLink]="[ '/doctor-signup']">Sign Up</a>
            </div>
        </div>
        <div class="login-form" *ngIf="!sucessfull">
            <div class="form-header">
                Forgot Password
            </div>
            <form  [formGroup]="forgotForm" (ngSubmit)="forgotFormSubmit()" >
            <div class="oma-wrapper-content" fxLayout="column" fxLayoutGap="20px"
            ngStyle.gt-sm="width:60%;" >

            <div fxLayout="column">
                <div class="label">Email id :</div>
                <input type="text" matInput formControlName="email"  required>
                
                
            </div>
            <!--  -->
            <div *ngIf="errMsg" class="error-message">
                {{errMsg}}
            </div>
            <div *ngIf="successMsg" class="success-message">{{successMsg}}</div>
            <div fxLayout="row">
                <button mat-raised-button type="submit" class="oma-btn-primary oma-btn" style="width: 133px;">Reset Password</button>
            </div>
               </div>
        </form>
        </div>
        <div  class="footer-links" *ngIf="sucessfull">
            <!--  -->
            <br>
            <div class="success-img" fxLayoutAlign="center center"
            >
                <img src="../../../assets/images/oma-booking-confirmed-img.png" alt="">
            </div>
            <br>
            <br>
            <div fxLayout="column" fxLayoutAlign="center center">
            <div class="form-header-footer" >
                Password Reset link sent on your Email ID.
            </div>
            <div class="form-header-footer">
                <a href="#" [routerLink]="['']">Click here</a> to Home Page.
            </div>
        </div>
        </div>
    </div> 
</div>

