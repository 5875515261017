import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, FormArray } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthClass } from 'src/app/helper/auth-class';
import { UserService } from 'src/app/services/user/user.service';
import { UserInfo } from 'src/app/_helpers/user-info';
import { delay, map, startWith } from 'rxjs/operators';
import { MasterService } from 'src/app/services/master.service';
import {MatAccordion} from '@angular/material/expansion';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DoctorService } from 'src/app/services/doctor/doctor.service';
import { FlexAlignStyleBuilder } from '@angular/flex-layout';

import { DatePipe } from '@angular/common';
import { ModalManagerService } from 'src/app/services/modal-manager.service';
import { Title } from '@angular/platform-browser';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AdminService } from 'src/app/services/admin.service';

@Component({
  selector: 'app-view-user',
  templateUrl: './view-user.component.html',
  styleUrls: ['./view-user.component.scss']
})
export class ViewUserComponent implements OnInit {
  userObj: any = []; 
  full_name:string="";
  base_url: any;
  email_id:string="";
  DrEduDet:any={
    ug:"",
    passout_year:"",
    univercity:"",
    major:"",
    certificate:""
  }
  objDrEduDetails:any = [];
  objDrWorkExp:any=[];
  objDrProfDetails:any=[];
  ins_det:any=[];
  dob:Date=new Date();
  identification_card:string="";
  consultatinMode:string="";
  userActionFrm: FormGroup = this._formBuilder.group({});
  constructor(@Inject(MAT_DIALOG_DATA) public reqData: any,
  public DoctorService: DoctorService,
  public adminService: AdminService,
  private _formBuilder: FormBuilder,
  private _snackBar: MatSnackBar) { }

  ngOnInit() {
    this.createPersonalForm();
    this.initilizePersonalDetValue();
    this.initilizeProffDetValue();
    
  }
  async initilizePersonalDetValue(){
    await this.DoctorService.getMyData(this.reqData.id).subscribe((res: any) => {
      this.userObj = res['data'][0];
       console.log(this.userObj);
      this.base_url = res['base_url'];
       this.full_name=this.userObj['full_name'];
       this.email_id=this.userObj['email_id'];
       this.dob=this.userObj['dob'];
       this.identification_card=this.userObj['identification_card'];
       this.objDrEduDetails=res['dr_edu_det'];
       this.ins_det=res['ins_det'][0];
      //  console.log(this.objDrEduDetails);

      this.userActionFrm.patchValue({
        'status': this.userObj.status,
        'admin_approved': this.userObj.admin_approved,
        'remark': this.userObj.remark,
      });
    });
  }

  createPersonalForm() {
    this.userActionFrm = this._formBuilder.group({      
      status: new FormControl('', [Validators.required]),
      admin_approved: new FormControl('', [Validators.required]),
      remark: new FormControl('', [Validators.required]),
      
    });
    
    
  }

  onSubmit(){
    if(this.userActionFrm.valid){
      let data = {
        status: this.userActionFrm.controls['status'].value? 1 : 0 ,
        admin_approved: this.userActionFrm.controls['admin_approved'].value? 1 : 0,
        remark: this.userActionFrm.controls['remark'].value,
        user_id: this.reqData.id
      }

      this.adminService.updateProfileStatusByAdmin(data).subscribe((res: any) => {
        if(res['status']){
          this.openSnackBar(res['message']);
        }else{
          this.openSnackBar(res['message']);
        }
      }); 

    }else{
      return;
    }
  }

  getFileName(path: String){
    if(path){
      let name = path.split('/');
    return name[2];
    }else{
      return "Not uploaded.";
    }
    
  }

  async initilizeProffDetValue() {
    await this.DoctorService.getMyProfDetails(this.reqData.id).subscribe((res: any) => {
      this.objDrWorkExp = res['dr_work_exp'][0];
      this.objDrProfDetails = res['dr_profile_det'][0];
      this.consultatinMode="";
      if(res['dr_profile_det'][0]['cm_virtual_app']==true)
        {
          if(this.consultatinMode=="")
          this.consultatinMode="Virtual Appointments";
          else 
          this.consultatinMode=this.consultatinMode+","+"Virtual Appointments";
        }
        if(res['dr_profile_det'][0]['cm_instant_virtual_app']==true)
        {
          if(this.consultatinMode=="")
          this.consultatinMode="Instant Virtual Appointments";
          else 
          this.consultatinMode=this.consultatinMode+","+"Instant Virtual Appointments";
        }
        if(res['dr_profile_det'][0]['cm_quick_query_que']==true)
        {
          if(this.consultatinMode=="")
          this.consultatinMode="Quick Query Questions";
          else 
          this.consultatinMode=this.consultatinMode+","+"Quick Query Questions";
        }
        if(res['dr_profile_det'][0]['cm_in_person_app']==true)
        {
          if(this.consultatinMode=="")
          this.consultatinMode="In Person Appointments";
          else 
          this.consultatinMode=this.consultatinMode+","+"In Person Appointments";
        }
     
    });
  }

  openSnackBar(message: string) {
    this._snackBar.open(message, 'OK', {
      duration: 3000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }
}
