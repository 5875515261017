import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { ConfigService } from './../config.service';

@Injectable({
  providedIn: 'root'
})
export class CmsService {
  public sideNavToggleSubject: BehaviorSubject<any> = new BehaviorSubject(null);
  url: string = "";
  myJsonToken: any;
  constructor(
    private config: ConfigService,
    private http: HttpClient,
    private router: Router,
    // private sideNav: MatSidenav
  ) { 
    this.url = config.getApiUrl();
  }

  public toggle(): void {
    // this.sideNav.toggle();
    return this.sideNavToggleSubject.next(null);
  }
  
  // get weekly Consultation with dr available timeslots
  getServerUrl(){
    return this.url;
  }
  getConsultation(health_issues: String, languages: String, fees: String, specs: String, patientId: number){    
    return this.http.get(this.url + 'cms/getConsultation/'+health_issues+"/"+languages+"/"+fees+"/"+specs+"/"+patientId);
  }
  getHabitMaster(){
    return this.http.get(this.url + 'cms/habits_master');
  }
  getConsultationSlots() {
    return this.http.get(this.url + 'cms/consultation_slots');
  }
  getDrInfo(id: Number){
    return this.http.get(this.url + 'cms/get_dr_info/'+id);
  }
  getTimeSlotsByDrId(id: Number){
    return this.http.get(this.url + 'cms/get_timeslots_by_drid/'+id);
  }
  get_dr_timeslots_by_date(id: Number, _date: String, _mode: String, puid: Number){
    return this.http.get(this.url + 'cms/get_dr_timeslots_by_date/'+id+'/'+_date+'/'+_mode+"?puid="+puid);
  }
  
  get_family_members_by_patient(patientId: Number){
    return this.http.get(this.url + 'cms/get_family_members_by_patient/'+patientId);
  }

  get_health_issues(){
    return this.http.get(this.url + 'cms/get_health_issues');
  }
  // get_dr_languages
  get_dr_languages(){
    return this.http.get(this.url + 'cms/get_dr_languages');
  }
  get_dr_details(id: Number, patientId: Number){
    return this.http.get(this.url + 'cms/get_dr_details/'+id+'?puid='+patientId);
  }

  get_dr_favourites_list(id: Number){
    return this.http.get(this.url + 'cms/get_dr_favourites_list/'+id);
  }
  book_timeslot(data: any){
    return this.http.post(this.url + 'cms/book_timeslot', data);
  }
  
  // get patient bookings
  get_mybookings(id: Number){
    return this.http.get(this.url + 'cms/get_mybookings/'+id);
  }

  get_session_data(id: Number){
    return this.http.get(this.url + 'patient/get_session_data/'+id);
  }

  getAppointmentDetails(booking_id: Number){
      return this.http.get(this.url + 'cms/get_booking_details/'+booking_id);    
  }
  getSession(data: any){
    return this.http.post(this.url + 'session/getSession', data);
  }
  // session/getSession
  
  //Generate Invoice
  createDocument(data: any){
    return this.http.post(this.url + 'invoice/createDocument', data);
  }

  //addToDrFavouritesList
  addToDrFavouritesList(data: any){
    return this.http.post(this.url + 'cms/addToDrFavouritesList', data);
  }

  removeDrFromFavouritesList(data: any){
    return this.http.post(this.url + 'cms/removeDrFromFavouritesList', data);
  }
}
