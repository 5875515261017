import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserService } from 'src/app/services/user/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatStepper } from '@angular/material/stepper';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss'],
  providers: [{
    provide: STEPPER_GLOBAL_OPTIONS, useValue: { showError: true }
  }]
})
export class EditUserComponent implements OnInit {
  step = 0;
  url: string = "";
  user_category_id: string = "";
  selectedFile: null;
  @ViewChild("fileInput") fileInput: any;

  userObj: any; 
  emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";
  personalForm: FormGroup = this._formBuilder.group({});
  secondFormGroup: FormGroup = this._formBuilder.group({});

  constructor(
    private _formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public reqData: any,
    public UserService: UserService,
    private _snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.createPersonalForm();
    this.secondFormGroup = this._formBuilder.group({
      secondCtrl: ['', Validators.required]
    });
    // console.log(this.reqData);
  }

  createPersonalForm() {

    this.personalForm = this._formBuilder.group({
      first_name: new FormControl('', [Validators.required]),
      last_name: new FormControl('', []),
      email_id: new FormControl('', [Validators.required, Validators.pattern(this.emailPattern)]),
      mobile_no: new FormControl('', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]),
      gender: new FormControl('', [Validators.required]),
      dob: new FormControl('', [Validators.required]),
      weight: new FormControl('', [Validators.required]),
      height: new FormControl('', [Validators.required]),
      address: new FormControl('', [Validators.required]),
      landmark: new FormControl('', [Validators.required]),
      pincode: new FormControl('', [Validators.required]),
      status: new FormControl('', [Validators.required]),
      admin_approved: new FormControl('', [Validators.required]),
      
    });

    this.UserService.getUser(this.reqData.id).subscribe((res: any) => {
      this.userObj = res['data'][0];
      // console.log(this.userObj['photo']);
      this.url = "https://omabackend.aqdsoft.com/api/user/files/"+this.userObj['photo'];
      this.user_category_id = this.userObj['user_category_id'];
      this.personalForm.patchValue({
        first_name: this.userObj['full_name'],
        last_name: '',
        email_id: this.userObj['email_id'],
        mobile_no: this.userObj['mobile_no'],
        gender: this.userObj['gender'],
        dob: this.userObj['dob'],
        weight: this.userObj['weight'],
        height: this.userObj['height'],
        address: this.userObj['address'],
        landmark: this.userObj['landmark'],
        pincode: this.userObj['pincode'],
        status: this.userObj['status'],
        admin_approved: this.userObj['admin_approved'] ,
      })
    });
  }

  onSelectFile(event: any) { // called each time file input changes
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      // this.selectedFile = (event.target.files[0]);
      reader.readAsDataURL(event.target.files[0]); // read file as data url

      reader.onload = (event: any) => { // called once readAsDataURL is completed
        this.url = event.target.result;
      }      
      
      // const formData = new FormData();
      // formData.append('file', this.selectedFile, this.selectedFile.name);
      // formData.append('id', this.userObj['id']);
      // this.UserService.uploadUserPhoto(formData).subscribe((res: any) => {        
      //   console.log(res);
      //   this.openSnackBar(res['message']);
      // });

    }
  }

  uploadFile(){
    let fi = this.fileInput.nativeElement;
    if (fi.files && fi.files[0]) {
      let fileToUpload = fi.files[0];
      this.UserService
          .upload(fileToUpload)
          .subscribe((res: any) => {
              // console.log(res);
              if(res['status']){
                let data = {
                  id: this.userObj['id'],
                  photo: res['fileName'],
                  photo_url: 'E:/RahulK/node/tcapi/assets/uploads/'+res['fileName']
                };
                this.UserService.updateUserPhoto(data).subscribe((res : any ) => {

                });

                this.openSnackBar('Profile photo uploaded.');
              }else{
                alert(res['status']);
              }
          });
        }
  }
  onSubmit(stepper: MatStepper){
    if(this.personalForm.valid){
      const formData = new FormData();
      //formData.append('file', this.myForm.get('fileSource').value);
      // let status :number = 0;
      // if(this.personalForm.controls['status']){
      //   status = <number> 1;
      // }
      // let admin_approved: number = 0;
      // if(this.personalForm.controls['admin_approved']){
      //   admin_approved = <number> 1;
      // }
      let data = {
        id: this.userObj['id'],
        first_name: this.personalForm.controls['first_name'].value,
        last_name: this.personalForm.controls['last_name'].value,
        email: this.personalForm.controls['email_id'].value,
        mobile: this.personalForm.controls['mobile_no'].value,
        gender: this.personalForm.controls['gender'].value,
        dob: this.personalForm.controls['dob'].value,
        weight: this.personalForm.controls['weight'].value,
        height: this.personalForm.controls['height'].value,
        address: this.personalForm.controls['address'].value,
        landmark: this.personalForm.controls['landmark'].value,
        pincode: this.personalForm.controls['pincode'].value,
        status: this.personalForm.controls['status'].value ? 1 : 0,
        admin_approved: this.personalForm.controls['admin_approved'].value ? 1 : 0,
      };
      // console.log(data);
      this.UserService.update(data).subscribe((res: any) => {        
        // console.log(res);
        // this.openSnackBar(res['message']);
        this.openSnackBar("Personal details updated successfuly.");
        stepper.next();
      });
      
    }else{
      console.log();
    }
  }


  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }
  openSnackBar(message: string) {
    this._snackBar.open(message, 'OK', {
      duration: 3000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }

}
