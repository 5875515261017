import { Component, ElementRef, AfterViewInit, ViewChild, Input, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OpentokService } from '../../services/opentok.service';
import { VideoCallComponent } from '../video-call.component';
@Component({
  selector: 'app-publisher',
  templateUrl: './publisher.component.html',
  styleUrls: ['./publisher.component.scss']
})
export class PublisherComponent implements OnInit {

  @ViewChild('publisherDiv') publisherDiv: ElementRef;
  @Input() session: OT.Session;
  @Input() streams: Array<OT.Stream> = [];
  publisher: OT.Publisher;
  publishing: Boolean;
  changeDetectorRef: ChangeDetectorRef;
  bookingId: any;
  camera: boolean = true;
  mic: boolean = true;
  constructor(
    private opentokService: OpentokService, private ref: ChangeDetectorRef,
    private VidCall: VideoCallComponent,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.publishing = false;
  }
  ngOnInit() {
    this.bookingId = this.route.snapshot.paramMap.get('bookingId');
  }
  ngAfterViewInit() {
    let pubisherName: any;
    var publisherOptions: any;
    pubisherName = localStorage.getItem('full_name')?.toString();
    publisherOptions = {
      insertMode: 'append',
      width: '300',
      height: '200',
      style: {
        buttonDisplayMode: 'off'
      }, name: pubisherName
    };

    //const OT = this.opentokService.getOT();
    const OT = this.VidCall.getOT();
    this.publisher = OT.initPublisher(this.publisherDiv.nativeElement, publisherOptions);


    if (this.session) {
      /*if (this.session['isConnected']()) {
        this.publish();
      }*/
      console.log("sessionConnected");
      this.session.on('sessionConnected', () => this.publish());
    }
  }

  publish() {
    this.session.publish(this.publisher, (err) => {
      if (err) {
        alert(err.message);
      } else {
        this.publishing = true;
        console.log("connected");
      }
    });
  }

  dropCall() {
    this.session.unpublish(this.publisher);
    this.session.on("sessionDisconnected", function (event) {
      alert("The session disconnected. " + event.reason);
    });
    this.session.disconnect();
    this.router.navigate(['/doctor/view-appointment/' + this.bookingId+'?status=Completed']);

  }
  cameraoff() {
    this.camera = false;
    this.publisher.publishVideo(false);

  }
  cameraon() {
    this.camera = true;
    this.publisher.publishVideo(true);

  }
  micoff() {
    this.mic = false;
    this.publisher.publishAudio(false);

  }
  micon() {
    this.mic = true;
    this.publisher.publishAudio(true);

  }

}
