import { ViewContainerRef, ChangeDetectorRef, Component, ElementRef, AfterViewInit, ViewChild, EventEmitter , ComponentFactoryResolver, OnInit, Output } from '@angular/core';
import * as OT from '@opentok/client';
import { OpentokService } from './../services/opentok.service';
import { StateService } from '../stateService';
import { ActivatedRoute, Router } from '@angular/router';
// import { VideoVirtualConsultationComponent } from '../cms/patient/virtual-consultation/virtual-consultation.component';
import { CmsService } from '../services/cms/cms.service';
@Component({
  selector: 'app-video-call',
  templateUrl: './video-call.component.html',
  styleUrls: ['./video-call.component.scss'],
  providers: [ OpentokService ]
})
export class VideoCallComponent implements AfterViewInit, OnInit {
  session: OT.Session;
  streams: Array<OT.Stream> = [];
  changeDetectorRef: ChangeDetectorRef;

  // @ViewChild('publisherDiv', { static: false }) publisherDiv: ElementRef;
  // @ViewChild('subscriberHost', { read: ViewContainerRef, static: true }) subscriberHost: ViewContainerRef;
  // @Output() messageEvent = new EventEmitter<string>();  
  publisher: OT.Publisher;
  publishing: boolean = false;
  apiKey: string;
  token: string;
  sessionId: string;
  bookingId: any;
  constructor(
    private ref: ChangeDetectorRef, private opentokService: OpentokService,
    // private componentFactoryResolver: ComponentFactoryResolver,
    private stateService: StateService,
    private router: Router,
    private route: ActivatedRoute,
    private cmsService: CmsService
  ) {
    this.changeDetectorRef = ref;
   }

   getOT() {
    return OT;
  }
  ngOnInit() {
    this.bookingId = this.route.snapshot.paramMap.get('bookingId');
    // this.messageEvent.emit("Emit");
    // if (!this.stateService.apiKey$ || !this.stateService.token$ || !this.stateService.sessionId$) {
    //   this.router.navigate(['/join-video-call']);
    // }
    // this.apiKey = this.stateService.apiKey$;
    // this.token = this.stateService.token$;
    // this.sessionId = this.stateService.sessionId$;

    this.cmsService.get_session_data(this.bookingId).subscribe((res: any)=>{
      var data = res['data'][0];
      // console.log(data);
      this.apiKey = data.api_key;
      this.token = data.token;
      this.sessionId = data.session_id;
      // console.log(this.apiKey);
        this.session = this.getOT().initSession(this.apiKey, this.sessionId);
        this.token = this.token;
        //return Promise.resolve(this.session);

        this.session.on('streamCreated', (event) => {
          this.streams.push(event.stream);
          this.changeDetectorRef.detectChanges();
        });
        this.session.on('streamDestroyed', (event) => {
          const idx = this.streams.indexOf(event.stream);
          if (idx > -1) {
            this.streams.splice(idx, 1);
            this.changeDetectorRef.detectChanges();
          }
        });

        this.connect();
    });

    /*
    this.opentokService.initSession(this.bookingId).then((session: OT.Session) => {
      this.session = session;
      console.log("streamCreated:"+session);
      this.session.on('streamCreated', (event) => {
        this.streams.push(event.stream);
        this.changeDetectorRef.detectChanges();
      });
      this.session.on('streamDestroyed', (event) => {
        const idx = this.streams.indexOf(event.stream);
        if (idx > -1) {
          this.streams.splice(idx, 1);
          this.changeDetectorRef.detectChanges();
        }
      });
    })
    .then(() => this.opentokService.connect())
    .catch((err) => {
      console.error(err);
      alert('Unable to connect. Make sure you have updated the config.ts file with your OpenTok details.');
    });

    */    
    //console.log("datta"+this.streams);
  }

  stopVideo(){



    // this.opentokService.initSession(this.bookingId).then((session: OT.Session) => {
    //   this.session = session;
      
     
    // })
    }

    connect() {
      return new Promise((resolve, reject) => {
        this.session.connect(this.token, (err) => {
          if (err) {
            reject(err);
          } else {
            resolve(this.session);
          }
        });
      });
    }

  publish() {
    let that = this;
    this.session.publish(this.publisher, (err) => {
      if (err) {
        console.log(err)
      }
      else {
        this.publishing = true;
        console.log("published");
      }
    }).on("streamCreated", function(event){      
      // that.onStreamCreated(event.stream);
    });
  }
  ngAfterViewInit(){

  }
  /*
  onStreamCreated(stream: any) {
    console.log('streamCreated');
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(VideoVirtualConsultationComponent);
    const viewContainerRef = this.subscriberHost;
    const componentRef = viewContainerRef.createComponent(componentFactory);
    (<VideoVirtualConsultationComponent>componentRef.instance).stream = stream;
    (<VideoVirtualConsultationComponent>componentRef.instance).session = this.session;
    (<VideoVirtualConsultationComponent>componentRef.instance).subscribe();
  }
  ngAfterViewInit(): void {
    this.publisher = OT.initPublisher
      (
        this.publisherDiv.nativeElement, {
        height: "100%",
        width: "100%;",
        insertMode: 'append',
        name: "John",
        style: { 
          nameDisplayMode: "on",
          buttonDisplayMode: 'on'
         },
         showControls: true
      });
    this.session = OT.initSession(this.apiKey, this.sessionId);
    this.session.connect(this.token, (err) => {
      if (err) {
        console.log(err);
      }
      else {
        console.log("connected");
        this.publish();
        
        // let that = this;
        // this.session.on("streamCreated", function (event) {
        //   console.log('streamCreated');
        //   that.onStreamCreated(event.stream);
          
        // });
      }
    })
  }

  */
}
