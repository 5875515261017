import { Route, Routes } from '@angular/router';
import { LogoutComponent } from '../auth/logout/logout.component';

export const ADMIN_ROUTES: Routes = [
    {
        path: '',
        loadChildren: () => import('../admin/admin.module').then(m => m.AdminModule)
    },
    {
        path: 'dashboard',
        loadChildren: () => import('../admin/dashboard/dashboard.module').then(m => m.DashboardModule)
    },
    {
        path: 'org',
        loadChildren: () => import('../admin/organization/organization.module').then(m => m.OrganizationModule)
    },
    {
        path: 'users',
        loadChildren: () => import('../admin/users/user/user.module').then(m => m.UserModule)
    },
    
    { path: 'logout', component: LogoutComponent}
];