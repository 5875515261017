import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-doctor-forgot',
  templateUrl: './doctor-forgot.component.html',
  styleUrls: ['./doctor-forgot.component.scss']
})
export class DoctorForgotComponent implements OnInit {
  errMsg: string = "";
  successMsg: string = "";
  sucessfull:boolean=false;
  forgotForm: FormGroup = this._fb.group({});
  emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";

  dataEmail: any = {
    email: ""
  }
  constructor( private TitleService: Title,
    private _fb: FormBuilder,
    private UserService: UserService,
    private router: Router,
    private Activatedroute: ActivatedRoute) { }
    
  ngOnInit(): void {
    this.createForm();
  }
  createForm()
  {
    this.forgotForm = this._fb.group({
      email: new FormControl('', [Validators.required, Validators.pattern(this.emailPattern)]),
    });
  }
  forgotFormSubmit(){ 
    
    if(this.forgotForm.valid){      
      let data = {
        email: this.forgotForm.controls['email'].value
      }
      //console.log(this.forgotForm.controls['email'].value);
      this.dataEmail.email = this.forgotForm.controls['email'].value;
      this.errMsg = "";
      this.successMsg = "";
      
      this.UserService.DrPetientForgot(this.dataEmail).subscribe( (res: any) => {
        //  console.log(res);  
        if(res['status']){
          
          this.successMsg = res['message'];
          this.sucessfull=true;
          //this.router.navigate(['']);
        }else{
          this.errMsg = res['message'];
          return;
        }
      })
    }else{
      this.errMsg = "Please enter email id";
      return;
    }    
  }
}
