import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { HelperClass } from 'src/app/helper/helper-class';
import { UserService } from 'src/app/services/user/user.service';
import { MustMatch } from 'src/app/_helpers/must-match.validator';
@Component({
  selector: 'app-doctor-sign-up',
  templateUrl: './doctor-sign-up.component.html',
  styleUrls: ['./doctor-sign-up.component.scss']
})
export class DoctorSignUpComponent implements OnInit {

  errMsg: string = "";
  successMsg: string = "";
  emailId: string = "";
  usernameId: string = "";
  personalDetailForm: FormGroup = this._fb.group({});
  professionalDetailForm: FormGroup = this._fb.group({});
  accountDetailForm: FormGroup = this._fb.group({});
  frm = new FormData();

  redirectTo: any;
  step = 0;
  dataEmail: any = {
    email: ""
  }
  dataUserName: any = {
    username: ""
  }
  doctorSignUpData: any = {
    full_name: "",
    email: "",
    username: "",
    mobile: "",
    password: "",
    fiscal_number: "",
    health_number: "",
    dob: "",
    gender: "",
    height: "",
    weight: "1",
    user_cat: "",
    country_code: "",
    status: "",
    identification_card: "",
    address_type: "",
    name_of_company: "",
    billing_address: "",
    iban: "",
    nipc: "",
    billing_certificate: "",
    edu_certificate: "",
    passout_year: "",
    univercity: "",
    major: "",
    ug: "",
    lic_no: "",
    year: "",
    total_exp: "",
    work_pos: "",
    med_lic_doc: "",
    spec_lic_doc: "",
    cv_doc: "",
    id_card: "",
    bio: "",
    languages: "",
    signature: "",
    practice_address: "",
    website: "",
    cm_virtual_app: "",
    cm_instant_virtual_app: "",
    cm_quick_query_que: "",
    cm_in_person_app: "",
    company_name: "",
    tax_id: "",
    pli_policy: "",
    ins_company: "",
    reg_no: "",
    ins_doc: ""
  };

  emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";
  selectedidentification_card: any;
  selectedonFileSelectCertificate: any;
  selectedonFileSelectmed_lic_doc: any;
  selectedonFileSelectspec_lic_doc: any;
  selectedonFileSelectcv_doc: any;
  selectedonFileSelectid_card: any;
  selectedonFileSelectins_doc: any;
  selectedonFileSelectsignature: any;
  selectedonFileSelectedu_certificate: any;








  constructor(
    private TitleService: Title,
    private _fb: FormBuilder,
    private UserService: UserService,
    private router: Router,
    private Activatedroute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.TitleService.setTitle("OMA Login");
    this.redirectTo = <String>this.Activatedroute.snapshot.queryParamMap.get('url');
    this.createForm();
  }
  get f() { return this.personalDetailForm.controls; }


  onChangeSettingEmail() {

    this.dataEmail.email = this.personalDetailForm.controls['email'].value;

    this.UserService.doctorSignUpCheckMailId(this.dataEmail).subscribe((res: any) => {

      if (res['status'] == "0") {
        let udata = res['data'];
        const matchingControl = this.personalDetailForm.controls['email'];
        matchingControl.setErrors({ emailExist: true, message: res['msg'] });

      } else {
        this.errMsg = "";
        return;
      }
    })

  }

  onChangeSettingUsername() {

    this.dataUserName.username = this.accountDetailForm.controls['username'].value;

    this.UserService.doctorSignUpCheckUserName(this.dataUserName).subscribe((res: any) => {

      if (res['status'] == "0") {
        let udata = res['data'];
        const matchingControl = this.accountDetailForm.controls['username'];
        matchingControl.setErrors({ usernameExist: true, message: res['msg'] });

      } else {
        this.errMsg = "";
        return;
      }
    })

  }
  createForm() {
    this.personalDetailForm = this._fb.group({
      full_name: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.pattern(this.emailPattern)]),
      dob: new FormControl('', [Validators.required]),
      address_type: new FormControl('', [Validators.required]),
      ug: new FormControl('', [Validators.required]),
      passout_year: new FormControl('', [Validators.required]),
      major: new FormControl('', [Validators.required]),
      univercity: new FormControl('', [Validators.required]),
      name_of_company: new FormControl('', [Validators.required]),
      nipc: new FormControl('', [Validators.required]),
      billing_address: new FormControl('', [Validators.required]),
      iban: new FormControl('', [Validators.required]),
      identification_card: new FormControl('', [Validators.required]),
      billing_certificate: new FormControl('', [Validators.required]),
      edu_certificate: new FormControl('', [Validators.required])


    });


    this.professionalDetailForm = this._fb.group({

      lic_no: new FormControl('', [Validators.required]),
      total_exp: new FormControl('', [Validators.required]),
      work_pos: new FormControl('', [Validators.required]),
      bio: new FormControl('', [Validators.required]),
      languages: new FormControl('', [Validators.required]),
      cm_virtual_app: new FormControl(''),
      cm_instant_virtual_app: new FormControl(''),
      cm_quick_query_que: new FormControl(''),
      cm_in_person_app: new FormControl(''),
      pli_policy: new FormControl('', [Validators.required]),
      ins_company: new FormControl('', [Validators.required]),
      reg_no: new FormControl('', [Validators.required]),
      practice_address: new FormControl('', [Validators.required]),
      website: new FormControl('', [Validators.required]),
      med_lic_doc: new FormControl('', [Validators.required]),
      spec_lic: new FormControl('', [Validators.required]),
      cv_doc: new FormControl('', [Validators.required]),
      id_card: new FormControl('', Validators.required),
      ins_doc: new FormControl('', [Validators.required]),
      signature: new FormControl('', [Validators.required])



    });
    this.accountDetailForm = this._fb.group({
      username: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(20)]),
      confirmPassword: new FormControl('', [Validators.required]),


    }, {
      validator: MustMatch('password', 'confirmPassword')
    });
  }

  personalDetailFormSubmit() {

    if (this.personalDetailForm.valid) {
      let data = {
        full_name: this.personalDetailForm.controls['full_name'].value,
        email: this.personalDetailForm.controls['email'].value,
        dob: this.personalDetailForm.controls['dob'].value,
        address_type: this.personalDetailForm.controls['address_type'].value,
        ug: this.personalDetailForm.controls['ug'].value,
        major: this.personalDetailForm.controls['major'].value,
        passout_year: this.personalDetailForm.controls['passout_year'].value,
        univercity: this.personalDetailForm.controls['univercity'].value,
        name_of_company: this.personalDetailForm.controls['name_of_company'].value,
        nipc: this.personalDetailForm.controls['nipc'].value,
        billing_address: this.personalDetailForm.controls['billing_address'].value,
        iban: this.personalDetailForm.controls['iban'].value,
        identification_card: this.selectedidentification_card,
        billing_certificate: this.selectedonFileSelectCertificate,
        edu_certificate: this.selectedonFileSelectedu_certificate

      }


      const pipe = new DatePipe('en-US');
      const dt = new Date(this.personalDetailForm.controls['dob'].value);
      let myDate: any;
      myDate = pipe.transform(dt, 'yyyy-MM-dd');
      this.doctorSignUpData.full_name = this.personalDetailForm.controls['full_name'].value;
      this.doctorSignUpData.email = this.personalDetailForm.controls['email'].value;
      //this.doctorSignUpData.dob= this.personalDetailForm.controls['dob'].value;


      this.doctorSignUpData.dob = myDate;
      this.doctorSignUpData.address_type = this.personalDetailForm.controls['address_type'].value;
      this.doctorSignUpData.ug = this.personalDetailForm.controls['ug'].value;
      this.doctorSignUpData.major = this.personalDetailForm.controls['major'].value;
      this.doctorSignUpData.passout_year = this.personalDetailForm.controls['passout_year'].value;
      this.doctorSignUpData.univercity = this.personalDetailForm.controls['univercity'].value;
      this.doctorSignUpData.name_of_company = this.personalDetailForm.controls['name_of_company'].value;
      this.doctorSignUpData.nipc = this.personalDetailForm.controls['nipc'].value;
      this.doctorSignUpData.billing_address = this.personalDetailForm.controls['billing_address'].value;
      this.doctorSignUpData.iban = this.personalDetailForm.controls['iban'].value;
      this.doctorSignUpData.identification_card = this.selectedidentification_card;
      this.doctorSignUpData.billing_certificate = this.selectedonFileSelectCertificate;
      this.doctorSignUpData.edu_certificate = this.selectedonFileSelectedu_certificate;


      this.frm.append('full_name', this.personalDetailForm.controls['full_name'].value);
      this.frm.append('email', this.personalDetailForm.controls['email'].value);

      this.frm.append('dob', myDate.toString());
      this.frm.append('address_type', this.personalDetailForm.controls['address_type'].value);
      this.frm.append('ug', this.personalDetailForm.controls['ug'].value);
      this.frm.append('major', this.personalDetailForm.controls['major'].value);
      this.frm.append('passout_year', this.personalDetailForm.controls['passout_year'].value);
      this.frm.append('univercity', this.personalDetailForm.controls['univercity'].value);
      this.frm.append('company_name', this.personalDetailForm.controls['name_of_company'].value);
      this.frm.append('nipc', this.personalDetailForm.controls['nipc'].value);
      this.frm.append('billing_address', this.personalDetailForm.controls['billing_address'].value);
      this.frm.append('iban', this.personalDetailForm.controls['iban'].value);
      this.frm.append('identification_card', this.selectedidentification_card);
      this.frm.append('billing_certificate', this.selectedonFileSelectCertificate);
      this.frm.append('edu_certificate', this.selectedonFileSelectedu_certificate);

      this.errMsg = "";
      this.successMsg = "";
      this.step++;
    }
    else {
      this.errMsg = "Please enter all details.";
      return;
    }
  }
  professionalDetailFormSubmit() {
    if (this.professionalDetailForm.valid) {
      let data = {
        lic_no: this.professionalDetailForm.controls['lic_no'].value,
        total_exp: this.professionalDetailForm.controls['total_exp'].value,
        work_pos: this.professionalDetailForm.controls['work_pos'].value,
        bio: this.professionalDetailForm.controls['bio'].value,
        languages: this.professionalDetailForm.controls['languages'].value,
        cm_virtual_app: this.professionalDetailForm.controls['cm_virtual_app'].value,
        cm_instant_virtual_app: this.professionalDetailForm.controls['cm_instant_virtual_app'].value,
        cm_quick_query_que: this.professionalDetailForm.controls['cm_quick_query_que'].value,
        cm_in_person_app: this.professionalDetailForm.controls['cm_in_person_app'].value,
        pli_policy: this.professionalDetailForm.controls['pli_policy'].value,
        ins_company: this.professionalDetailForm.controls['ins_company'].value,
        reg_no: this.professionalDetailForm.controls['reg_no'].value,
        practice_address: this.professionalDetailForm.controls['practice_address'].value,
        website: this.professionalDetailForm.controls['website'].value,
        med_lic_doc: this.selectedonFileSelectmed_lic_doc,
        spec_lic_doc: this.selectedonFileSelectspec_lic_doc,
        cv_doc: this.selectedonFileSelectcv_doc,
        id_card: this.selectedonFileSelectid_card,
        ins_doc: this.selectedonFileSelectins_doc,
        signature: this.selectedonFileSelectsignature

      }

      this.doctorSignUpData.lic_no = this.professionalDetailForm.controls['lic_no'].value;
      this.doctorSignUpData.total_exp = this.professionalDetailForm.controls['total_exp'].value;
      this.doctorSignUpData.work_pos = this.professionalDetailForm.controls['work_pos'].value;
      this.doctorSignUpData.bio = this.professionalDetailForm.controls['bio'].value;
      this.doctorSignUpData.languages = this.professionalDetailForm.controls['languages'].value;
      this.doctorSignUpData.cm_virtual_app = this.professionalDetailForm.controls['cm_virtual_app'].value;
      this.doctorSignUpData.cm_instant_virtual_app = this.professionalDetailForm.controls['cm_instant_virtual_app'].value;
      this.doctorSignUpData.cm_quick_query_que = this.professionalDetailForm.controls['cm_quick_query_que'].value;
      this.doctorSignUpData.cm_in_person_app = this.professionalDetailForm.controls['cm_in_person_app'].value;
      this.doctorSignUpData.pli_policy = this.professionalDetailForm.controls['pli_policy'].value;
      this.doctorSignUpData.ins_company = this.professionalDetailForm.controls['ins_company'].value;
      this.doctorSignUpData.reg_no = this.professionalDetailForm.controls['reg_no'].value;
      this.doctorSignUpData.practice_address = this.professionalDetailForm.controls['practice_address'].value;
      this.doctorSignUpData.website = this.professionalDetailForm.controls['website'].value;
      this.doctorSignUpData.med_lic_doc = this.selectedonFileSelectmed_lic_doc;
      this.doctorSignUpData.spec_lic_doc = this.selectedonFileSelectspec_lic_doc;
      this.doctorSignUpData.cv_doc = this.selectedonFileSelectcv_doc;
      this.doctorSignUpData.id_card = this.selectedonFileSelectid_card;
      this.doctorSignUpData.ins_doc = this.selectedonFileSelectins_doc;
      this.doctorSignUpData.signature = this.selectedonFileSelectsignature;

      this.frm.append('lic_no', this.professionalDetailForm.controls['lic_no'].value);
      this.frm.append('total_exp', this.professionalDetailForm.controls['total_exp'].value);
      this.frm.append('work_pos', this.professionalDetailForm.controls['work_pos'].value);
      this.frm.append('bio', this.professionalDetailForm.controls['bio'].value);
      this.frm.append('languages', this.professionalDetailForm.controls['languages'].value);
      this.frm.append('cm_virtual_app', new Boolean(this.professionalDetailForm.controls['cm_virtual_app'].value).toString());
      this.frm.append('cm_instant_virtual_app', new Boolean(this.professionalDetailForm.controls['cm_instant_virtual_app'].value).toString());
      this.frm.append('cm_quick_query_que', new Boolean(this.professionalDetailForm.controls['cm_quick_query_que'].value).toString());
      this.frm.append('cm_in_person_app', new Boolean(this.professionalDetailForm.controls['cm_in_person_app'].value).toString());
      this.frm.append('pli_policy', this.professionalDetailForm.controls['pli_policy'].value);
      this.frm.append('ins_company', this.professionalDetailForm.controls['ins_company'].value);
      this.frm.append('reg_no', this.professionalDetailForm.controls['reg_no'].value);
      this.frm.append('practice_address', this.professionalDetailForm.controls['practice_address'].value);
      this.frm.append('website', this.professionalDetailForm.controls['website'].value);
      this.frm.append('med_lic_doc', this.selectedonFileSelectmed_lic_doc);
      this.frm.append('spec_lic_doc', this.selectedonFileSelectspec_lic_doc);
      this.frm.append('cv_doc', this.selectedonFileSelectcv_doc);
      this.frm.append('id_card', this.selectedonFileSelectid_card);
      this.frm.append('ins_doc', this.selectedonFileSelectins_doc);
      this.frm.append('signature', this.selectedonFileSelectsignature);

      this.errMsg = "";
      this.successMsg = "";
      this.step++;
    } else {
      this.errMsg = "Please enter all details.";
      return;
    }
  }
  accountDetailFormSubmit() {
    if (this.accountDetailForm.valid) {
      let data = {
        username: this.accountDetailForm.controls['username'].value,
        password: this.accountDetailForm.controls['password'].value

      }

      this.doctorSignUpData.username = this.accountDetailForm.controls['username'].value;
      this.doctorSignUpData.password = this.accountDetailForm.controls['password'].value;
      if(this.frm.has("username")){
        this.frm.set('username', this.doctorSignUpData.username);
        this.frm.set('password', this.doctorSignUpData.password);
      }else{
        this.frm.append('username', this.doctorSignUpData.username);
        this.frm.append('password', this.doctorSignUpData.password);
      }
      

      this.errMsg = "";
      this.successMsg = "";
      this.UserService.doctorSignUp(this.frm).subscribe((res: any) => {
        //console.log(res);
        if (res['status']) {
          let udata = res['data'];
          // console.log(udata);
          this.successMsg = "Sign up Sucessfully";
          //this.router.navigate(['/doctor-login'], { queryParams: { url: this.router.url }  });
          this.step = 5;
        } else {
          this.errMsg = res['message'];
          return;
        }
      })
    } else {
      this.errMsg = "Please enter all details.";
      return;
    }
  }

  setStep(index: number) {
    this.step = index;
  }


  onFileSelectedu_certificate(event: any) {
    if (event.target.files.length > 0) {
      this.selectedonFileSelectedu_certificate = event.target.files[0];
    }
  }
  onFileSelectIdentification(event: any) {
    if (event.target.files.length > 0) {
      this.selectedidentification_card = event.target.files[0];
    }
  }
  onFileSelectCertificate(event: any) {
    if (event.target.files.length > 0) {
      this.selectedonFileSelectCertificate = event.target.files[0];
    }
  }
  onFileSelectmed_lic_doc(event: any) {
    if (event.target.files.length > 0) {
      this.selectedonFileSelectmed_lic_doc = event.target.files[0];
    }
  }
  onFileSelectspec_lic_doc(event: any) {
    if (event.target.files.length > 0) {
      this.selectedonFileSelectspec_lic_doc = event.target.files[0];
    }
  }
  onFileSelectcv_doc(event: any) {
    if (event.target.files.length > 0) {
      this.selectedonFileSelectcv_doc = event.target.files[0];
    }
  }
  onFileSelectid_card(event: any) {
    if (event.target.files.length > 0) {
      this.selectedonFileSelectid_card = event.target.files[0];
    }
  }
  onFileSelectind_doc(event: any) {
    if (event.target.files.length > 0) {
      this.selectedonFileSelectid_card = event.target.files[0];
    }
  }
  onFileSelectsignature(event: any) {
    if (event.target.files.length > 0) {
      this.selectedonFileSelectsignature = event.target.files[0];
    }
  }


  nextStep() {

    this.step++;

  }

  prevStep() {
    this.step--;
  }
}
