import { Component, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { UserService } from 'src/app/services/user/user.service';
import { UserInfo } from 'src/app/_helpers/user-info';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  loggedUserName: any = null;
  user_photo = "";
  temporaryDisabled: boolean = false;
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );
  constructor(
    private breakpointObserver: BreakpointObserver    
    ) { }

  ngOnInit() {
    this.loggedUserName = localStorage.getItem('user_name');
    this.user_photo = "https://omabackend.aqdsoft.com/api/user/files/"+localStorage.getItem('photo');
  }

}
