import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  // url = "http://localhost:3000/api/";
  url = "https://omabackend.aqdsoft.com/api/";
  constructor() { }

  getApiUrl(){
    return this.url;
  }

}
