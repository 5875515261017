<mat-nav-list class="list-item">
    <a mat-list-item [routerLink]="[ '/patient/payment-details' ]" class="admin-sidebar-menu" routerLinkActive="doctor-active-link"  [routerLinkActiveOptions]="{exact: true}"
        (click)="toggleSideNav()">
        <img class="list-icon" src="../../../assets/images/OMA-wallet-icon.png"
        > Payment Details </a>
    
    <a mat-list-item [routerLink]="[ '/' ]" class="admin-sidebar-menu" routerLinkActive="doctor-active-link"  [routerLinkActiveOptions]="{exact: true}"
        (click)="toggleSideNav()">
        <img class="list-icon" src="../../../assets/images/oma-settings-icon.png"
        > Settings </a>
    
    <a mat-list-item [routerLink]="[ '/' ]" class="admin-sidebar-menu" routerLinkActive="doctor-active-link"  [routerLinkActiveOptions]="{exact: true}"
        (click)="toggleSideNav()">
        <img class="list-icon" src="../../../assets/images/OMA-favourites-icon.png"
        > My Favourites </a>

    <a mat-list-item [routerLink]="[ '/' ]" class="admin-sidebar-menu" routerLinkActive="doctor-active-link"  [routerLinkActiveOptions]="{exact: true}"
        (click)="toggleSideNav()">
        <img class="list-icon" src="../../../assets/images/OMA-support-icon.png"
        > Support </a>

        <a mat-list-item [routerLink]="[ '/' ]" class="admin-sidebar-menu" routerLinkActive="doctor-active-link"  [routerLinkActiveOptions]="{exact: true}"
        (click)="toggleSideNav()">
        <img class="list-icon" src="../../../assets/images/oma-send-icon.png"
        > Refer to a friend </a>
        
        <a mat-list-item [routerLink]="[ '/' ]" class="admin-sidebar-menu" routerLinkActive="doctor-active-link"  [routerLinkActiveOptions]="{exact: true}"
        (click)="toggleSideNav()">
        <img class="list-icon" src="../../../assets/images/OMA-raiting-star-icon.png"
        > Rating and Feedback </a>

        <a mat-list-item [routerLink]="[ '/' ]" class="admin-sidebar-menu" routerLinkActive="doctor-active-link"  [routerLinkActiveOptions]="{exact: true}"
        (click)="toggleSideNav()">
        <img class="list-icon" src="../../../assets/images/OMA-Shield-Done.png"
        > Privacy Policy </a>

        <a mat-list-item [routerLink]="[ '/' ]" class="admin-sidebar-menu" routerLinkActive="doctor-active-link"  [routerLinkActiveOptions]="{exact: true}"
        (click)="toggleSideNav()">
        <img class="list-icon" src="../../../assets/images/OMA-t_and_c_icon.png"
        > T&C </a>
        
        <a mat-list-item [routerLink]="[ '/patient/logout' ]" class="admin-sidebar-menu" routerLinkActive="doctor-active-link"  [routerLinkActiveOptions]="{exact: true}"
        (click)="toggleSideNav()">
        <img class="list-icon" src="../../../assets/images/OMA-logout-icon.png"
        > Log Out </a>
  </mat-nav-list>