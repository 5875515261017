import { Route, Routes } from '@angular/router';

export const DOCTOR_ROUTES: Routes = [
    {
        path: '',
        loadChildren: () => import('../doctor/doctor.module').then(m => m.DoctorModule)
    },
    // {
    //     path: 'clinic',
    //     loadChildren: () => import('../clinic/clinic.module').then(m => m.ClinicModule)
    // }
];