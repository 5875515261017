<div fxLayout="column" [ngClass]="{'publishing': publishing}" >
    <div #publisherDiv></div>
    <div fxLayout="row" fxFlex fxLayoutGap="15px" class="publisher-controller" fxLayoutAlign="center center">        
        <button mat-mini-fab (click)="cameraoff()" *ngIf="camera"><mat-icon>videocam</mat-icon></button>
        <button mat-mini-fab (click)="cameraon()" *ngIf="!camera"><mat-icon>videocam_off</mat-icon></button>
        <button mat-mini-fab (click)="dropCall()" color="warn"><mat-icon>close</mat-icon></button>
        <button mat-mini-fab (click)="micoff()" *ngIf="mic"><mat-icon>mic</mat-icon></button>
        <button mat-mini-fab (click)="micon()" *ngIf="!mic"><mat-icon>mic_off</mat-icon></button>        
    </div>
    
</div>

