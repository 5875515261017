<div fxLayout="row" class="wrapper" fxFlex>
    <div fxHide fxShow.gt-sm class="login-image" fxFlex="55" fxLayoutAlign="center center">
        <img src="../../../assets/images/OMA-patient-login-page-image.png" alt="">
    </div>
    <div class="login-div" fxFlex  fxFlex.gt-sm="45" 
    fxLayout="column"  
    fxLayoutGap="15px"
    >
        <div class="login-header" fxLayout="row" 
        fxLayoutAlign="start" fxLayoutAlign="space-between center" fxLayoutGap="15px">
            <div>
                <img title="OMA HEALTH LOGO" [routerLink]="['']"  ngStyle.md="width: 180px; height: 50px;" 
                src="../../../assets/images/oma-health-logo.svg" >
            </div>
           
        </div>
        <div class="login-form" *ngIf="!sucessfull">
            <div class="form-header">
                Reset Password
            </div>
            <form  [formGroup]="forgotForm" (ngSubmit)="forgotFormSubmit()" >
            <div class="oma-wrapper-content" fxLayout="column" fxLayoutGap="20px"
            ngStyle.gt-sm="width:60%;" >
            
            <div fxLayout="column">
                <div class="label">Email id :</div>
                <input type="text" matInput formControlName="email" readonly required>
                
                
            </div>
            <div fxLayout="column">
                <div class="label">Password :</div>
                <input type="password" matInput formControlName="password" required>
                
            </div>
              <!--  -->
            <div fxLayout="column">
                <div class="label">Confirm Password :</div>
                <input type="password" matInput formControlName="confirmPassword" required>
            </div>
            <!--  -->
            <div *ngIf="errMsg" class="error-message">
                {{errMsg}}
            </div>
            <div *ngIf="successMsg" class="success-message">{{successMsg}}</div>
            <div fxLayout="row">
                <button mat-raised-button type="submit" class="oma-btn-primary oma-btn" style="width: 133px;">Proceed</button>
            </div>
               
                
            </div>
        </form>
        </div>
        <div  class="footer-links" *ngIf="sucessfull">
            <!--  -->
            <br>
            <div class="success-img" fxLayoutAlign="center center"
            >
                <img src="../../../assets/images/oma-booking-confirmed-img.png" alt="">
            </div>
            <br>
            <br>
            <div fxLayout="column" fxLayoutAlign="center center">
            <div class="form-header-footer" >
                Your password updated successfully.
            </div>
            <div class="form-header-footer" *ngIf="user_category_id==2">
                <a href="#" [routerLink]="['/doctor-login']" >Click here</a> to Login.
               
            </div>
            <div class="form-header-footer" *ngIf="user_category_id!=2">
                <a href="#" [routerLink]="['/login']" >Click here</a> to Login.
               
            </div>
        </div>
        </div>
    </div>
</div>

