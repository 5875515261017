import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CmsService } from '../services/cms/cms.service';
import { OpentokService } from '../services/opentok.service';
import { StateService } from '../stateService';

@Component({
  selector: 'app-video-call-join',
  templateUrl: './video-call-join.component.html',
  styleUrls: ['./video-call-join.component.scss'],
  providers: [ OpentokService ]
})
export class VideoCallJoinComponent implements OnInit {
  session: OT.Session;
  streams: Array<OT.Stream> = [];
  changeDetectorRef: ChangeDetectorRef;

  // @ViewChild('publisherDiv', { static: false }) publisherDiv: ElementRef;
  // @ViewChild('subscriberHost', { read: ViewContainerRef, static: true }) subscriberHost: ViewContainerRef;
  // @Output() messageEvent = new EventEmitter<string>();  
  publisher: OT.Publisher;
  publishing: boolean = false;
  apiKey: string;
  token: string;
  sessionId: string;
  bookingId: any;
  constructor(
    private ref: ChangeDetectorRef, private opentokService: OpentokService,
    // private componentFactoryResolver: ComponentFactoryResolver,
    private stateService: StateService,
    private router: Router,
    private route: ActivatedRoute,
    private cmsService: CmsService
  ) {
    this.changeDetectorRef = ref;
   }

   getOT() {
    return OT;
  }
  ngOnInit() {
    this.bookingId = this.route.snapshot.paramMap.get('bookingId');
  

    this.cmsService.get_session_data(this.bookingId).subscribe((res: any)=>{
      var data = res['data'][0];
      //console.log(data);
      this.apiKey = data.api_key;
      this.token = data.token;
      this.sessionId = data.session_id;
      // console.log(this.apiKey);
        this.session = this.getOT().initSession(this.apiKey, this.sessionId);
        this.token = this.token;
        //return Promise.resolve(this.session);

        this.session.on('streamCreated', (event) => {
          this.streams.push(event.stream);
          this.changeDetectorRef.detectChanges();
        });
        this.session.on('streamDestroyed', (event) => {
          const idx = this.streams.indexOf(event.stream);
          if (idx > -1) {
            this.streams.splice(idx, 1);
            this.changeDetectorRef.detectChanges();
          }
        });

        this.connect();
    });

   
    //console.log("datta"+this.streams);
  }

  stopVideo(){



    // this.opentokService.initSession(this.bookingId).then((session: OT.Session) => {
    //   this.session = session;
      
     
    // })
    }

    connect() {
      return new Promise((resolve, reject) => {
        this.session.connect(this.token, (err) => {
          if (err) {
            reject(err);
          } else {
            resolve(this.session);
          }
        });
      });
    }

  publish() {
    let that = this;
    this.session.publish(this.publisher, (err) => {
      if (err) {
        console.log(err)
      }
      else {
        this.publishing = true;
        console.log("Subscribed");
      }
    }).on("streamCreated", function(event){      
      // that.onStreamCreated(event.stream);
    });
  }
  ngAfterViewInit(){

  }
 
}
