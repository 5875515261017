import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'app-quick-query-booking-confirmed',
  templateUrl: './quick-query-booking-confirmed.component.html',
  styleUrls: ['./quick-query-booking-confirmed.component.scss']
})
export class QuickQueryBookingConfirmedComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public reqData: any,
    public dialogRef: MatDialogRef<QuickQueryBookingConfirmedComponent>,
  ) { }

  ngOnInit(): void {
  }
  onClickBackToHome(){
    this.dialogRef.close({status: 0});
  }

}
