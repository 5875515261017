import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfigService } from '../config.service';
@Injectable({
  providedIn: 'root'
})
export class DoctorService {
  apiBaseUrl: string = "";
  constructor(
    private config: ConfigService,
    private http: HttpClient
  ) {
    this.apiBaseUrl = config.getApiUrl();
  }
  getHeader(){
    if(localStorage.getItem('drtoken')){
      let jsonptoken: any =  localStorage.getItem('drtoken');
      let token = JSON.parse(jsonptoken);
      let header = new HttpHeaders().set(
        "authorization",
        "Bearer "+ token.value
      );
      return header;
    }else{
      let header = new HttpHeaders().set(
        "authorization",
        "Bearer "
      );
      return header;
    }
    
  }
  getMyData(id: number) {
    return this.http.get(this.apiBaseUrl + 'doctor/' + id);
  }
  getDrData(id: Number) {
    return this.http.get(this.apiBaseUrl + 'doctor/' + id);
  }
  getMyProfDetails(id: number) {
    return this.http.get(this.apiBaseUrl + 'doctor/prof_details/' + id);
  }
  getDrProfDetails(id: Number) {
    return this.http.get(this.apiBaseUrl + 'doctor/prof_details/' + id);
  }
  updatePersonalDetails(id: number, data: any) {
    return this.http.put(this.apiBaseUrl + 'doctor/personal/' + id, data);
  }
  updateMyAvailibility(id: number, data: any) {
    return this.http.put(this.apiBaseUrl + 'doctor/updateMyAvailibility/' + id, data);
  }
  updateAppintmentTiming(data: any) {
    return this.http.put(this.apiBaseUrl + 'doctor/updateAppointmentTiming', data);
  }

  
  //  updateProfilePhoto
  updateProfilePhoto(id: any, fileToUpload: any) {
    let input = new FormData();
    input.append("file", fileToUpload);
    input.append("id", id);

    return this.http.post(this.apiBaseUrl + "user/photoUpdate", input);
  }
  add_dr_work_exp(data: any) {
    return this.http.post(this.apiBaseUrl + 'doctor/add_dr_work_exp/', data);
  }
  add_dr_profile_details(data: any) {
    return this.http.post(this.apiBaseUrl + 'doctor/add_dr_profile_details/', data);
  }
  addBank(data: any) {
    return this.http.post(this.apiBaseUrl + 'doctor/addbank/', data);
  }

  addInsurance(data: any) {
    return this.http.post(this.apiBaseUrl + 'doctor/add_insurance/', data);
  }
  add_dr_education(data: any) {
    return this.http.post(this.apiBaseUrl + 'doctor/add_dr_education/', data);
  }

  // recurring_timeslot_schedule  
  generate_recurring_timeslot_schedule(data: any) {
    return this.http.post(this.apiBaseUrl + 'doctor/dr_recurring_timeslot_schedule/', data);
  }

  // Get Partner Access Token
  getPartnerAccessToken(data: any) {
    return this.http.post(this.apiBaseUrl + 'invoice/GetPartnerAccessToken', data);
  }

  addToPartner(data: any) {
    return this.http.post(this.apiBaseUrl + 'invoice/add_partner2', data);
  }
  
  //dr_reset_timeslot_schedule
  reset_timeslot_schedule(data: any) {
    return this.http.post(this.apiBaseUrl + 'doctor/dr_reset_timeslot_schedule/', data);
  }

  add_dr_timeslot(data: any) {
    return this.http.post(this.apiBaseUrl + 'doctor/add_dr_timeslot/', data);
  }
  loadWeekMaster(week_no: number, year: number, dr_id: number) {
    return this.http.get(this.apiBaseUrl + 'doctor/get_week_master_data/' + week_no + '/' + year + '/' + dr_id);
  }
  rescheduleTimeslotWeekData(booking_id: Number, dr_id: Number) {
    return this.http.get(this.apiBaseUrl + 'doctor/get_reschedule_timeslot_week_data/' + booking_id + '/' + dr_id);
  }
  

  getAppointments(dr_id: number, status: string) {
    return this.http.get(this.apiBaseUrl + 'doctor/get_appointments/' + dr_id + '/' + status);
  }
  //  get Dr Dashboard Data
  getDrDashboardData(dr_id: number) {
    return this.http.get(this.apiBaseUrl + 'doctor/getDrDashboardData/' + dr_id);
  }

  // get_doctor_invoice_details
  getDoctorInvoiceDetails(dr_id: number) {
    return this.http.get(this.apiBaseUrl + 'doctor/get_doctor_invoice_details/' + dr_id);
  }

  getAppointmentDetails(bookin_id: Number, member_type: String) {
    return this.http.get(this.apiBaseUrl + 'doctor/get_appointment_details/' + bookin_id + "/" + member_type);
  }
  
  getDefaultSettings(id: Number) {
    return this.http.get(this.apiBaseUrl + 'doctor/getDefaultSettings/'+id, {headers: this.getHeader()});
  }
  updateDefaultSettings(data: any) {
    return this.http.post(this.apiBaseUrl + 'doctor/updateDefaultSettings', data, {headers: this.getHeader()});
  }

}
