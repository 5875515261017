<div fxLayout="row" fxLayoutAlign="center center" class="main-wrapper">
    <mat-card class="box">
        <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
            <img src="../../../assets/images/oma-logo-lg.PNG" width="150px">
        </div>
        <mat-card-header fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
            <mat-card-title class="">Sign Up</mat-card-title>
        </mat-card-header>

        <form class="example-form" [formGroup]="myData" (ngSubmit)="onSubmit()">

            <mat-card-content *ngIf="!accountCreated" fxLayout="column" fxLayoutGap="20px">

                <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign="space-between center" fxLayoutGap="20px">
                    <mat-form-field class="example-full-width">
                        <input matInput placeholder="First Name" formControlName="first_name">
                        <mat-error *ngIf="myData.get('first_name')?.hasError('required')">First name id required.</mat-error>
                    </mat-form-field>

                    <mat-form-field class="example-full-width">
                        <input matInput placeholder="Last Name" formControlName="last_name">
                        <mat-error *ngIf="myData.get('last_name')?.hasError('required')">Last name is required.</mat-error>
                    </mat-form-field>
                </div>
                <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign="space-between center" fxLayoutGap="20px">
                    <mat-form-field class="example-full-width">
                        <input matInput placeholder="Email Id" formControlName="email_id">
                        <mat-error *ngIf="myData.get('email_id')?.hasError('pattern')">Invalid Email Id.</mat-error>
                        <mat-error *ngIf="myData.get('email_id')?.hasError('required')">Email Id is required.</mat-error>
                        <mat-error *ngIf="myData.get('email_id')?.hasError('emailExist')">Email Id already exist.</mat-error>
                    </mat-form-field>
                    <div>
                        <button mat-raised-button color="primary">Verify</button>
                    </div>
                </div>
                <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign="space-between center" fxLayoutGap="20px">
                    <mat-form-field class="" fxFlex=25>
                        <mat-label>Country code</mat-label>
                        <mat-select formControlName="country_code">
                            <mat-option value="+91">+91</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="example-full-width">
                        <input matInput placeholder="Mobile No" [disabled]="mobileVerified" formControlName="mobile_no" maxlength="10">
                        <mat-error *ngIf="myData.get('mobile_no')?.hasError('required')">Mobile No is required.</mat-error>
                        <mat-error *ngIf="myData.get('mobile_no')?.hasError('minlength') || myData.get('mobile_no')?.hasError('maxlength')">Mobile No is should be 10 digits.</mat-error>
                        <mat-error *ngIf="myData.get('mobile_no')?.hasError('mobileExist')">Mobile No already exist.</mat-error>
                        <mat-error *ngIf="myData.get('mobile_no')?.hasError('mobileRequired')">Please enter mobile no.</mat-error>
                        <mat-error *ngIf="myData.get('mobile_no')?.hasError('mobileNotVerified')">Please verifiy your mobile no.</mat-error>
                    </mat-form-field>
                    <div>
                        <button type="button" *ngIf="!mobileVerified" mat-raised-button color="primary" (click)="verifyMobileNo()">Verify</button>
                        <button type="button" *ngIf="mobileVerified" mat-raised-button  color="" class="btn-mobile-verified"><mat-icon>check</mat-icon> Verified</button>
                    </div>
                </div>
                <div fxLayout="column" *ngIf="confirmOTPdiv"  fxLayout.gt-sm="row" fxLayoutAlign="space-between center" fxLayoutGap="20px">
                    <mat-form-field class="example-full-width">
                        <input matInput placeholder="Enter OTP" formControlName="txtOtp" maxlength="6">
                        <mat-error *ngIf="myData.get('txtOtp')?.hasError('otpRequired')">Please enter valid OTP.</mat-error>                        
                    </mat-form-field>
                    <div>
                        <button type="button" mat-raised-button color="primary" (click)="confirmMobileNo()">Confirm OTP</button>
                    </div>
                </div>
                <div fxLayout="column"  fxLayout.gt-sm="row" fxLayoutAlign="space-between center" fxLayoutGap="20px">
                    <mat-form-field class="example-full-width">
                        <input matInput placeholder="Password" type="password" formControlName="password">
                        <mat-error *ngIf="myData.get('password')?.hasError('required')">Password is required.</mat-error>
                        <mat-error *ngIf="myData.get('password')?.hasError('minlength')">Password should be min 6 digits.</mat-error>
                    </mat-form-field>
                    <mat-form-field class="example-full-width">
                        <input matInput placeholder="Confirm Password" type="password" formControlName="confirmPassword">
                            <mat-error *ngIf="myData.get('confirmPassword')?.hasError('required')">Confirm password is required.</mat-error>
                        <mat-error *ngIf="myData.get('confirmPassword')?.hasError('mustMatch')">Confirm password not matched.</mat-error>
                    </mat-form-field>
                </div>
                <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign="space-between center" fxLayoutGap="20px">
                   
                        <!-- <mat-label>Gender</mat-label> -->
                        <mat-radio-group aria-label="Gender" color="primary" fxFlex formControlName="gender">
                            <mat-radio-button value="Male">Male</mat-radio-button>
                            <mat-radio-button value="Female" class="m-l-10">Female</mat-radio-button>
                        </mat-radio-group>
                   
                    <mat-form-field class="example-full-width">
                        <mat-label>Register As</mat-label>
                        <mat-select formControlName="user_cat">
                            <mat-option [value]="user.id" *ngFor="let user of User">{{user.cat}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div fxLayout="column" fxLayoutAlign="center center">
                    <button type="submit" mat-raised-button color="primary" *ngIf="btnRegisterDisable" class="btn-block"
                        [disabled]="myData.invalid">Register</button>
                    <button *ngIf="!btnRegisterDisable" [disabled]="!btnRegisterDisable" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px" mat-raised-button class="btn-block" color="accent"><span fxFlex>Please wait...</span><mat-spinner fxFlex [diameter]="30" color="primary"></mat-spinner></button>
                    <p></p>
                    <p>Already have an account?<a [routerLink]="[ '/login',  ]" mat-raised-button color="accent">Login
                            Now</a></p>
                </div>
            </mat-card-content>
            <mat-card-content *ngIf="accountCreated" fxLayout="column" fxLayout.gt-sm="column" fxLayoutAlign="space-between center" fxLayoutGap="20px">
                <span class="account-created-msg">
                    <h3>Your Account Is Created Successfully.</h3>
                </span>
                <p><a [routerLink]="[ '/login',  ]" mat-raised-button color="accent">Login to your account</a></p>
            </mat-card-content>
        </form>
    </mat-card>
</div>