import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CancellationConfirmationComponent } from '../cancellation-confirmation/cancellation-confirmation.component';

@Component({
  selector: 'app-cancellation-confirmed',
  templateUrl: './cancellation-confirmed.component.html',
  styleUrls: ['./cancellation-confirmed.component.scss']
})
export class CancellationConfirmedComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public reqData: any,
    public dialogRef: MatDialogRef<CancellationConfirmationComponent>,
    private router: Router
  ) { }

  ngOnInit() {
  }
  onNoClick(): void {
    this.dialogRef.close({status: 0});
  }

  onSubmit(){
    this.dialogRef.close({status: 0});
  }

  onClickBackToHome(){
    this.dialogRef.close({status: 1});
    this.router.navigate(['/patient/my-bookings']);
  }

}
