import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { HelperClass } from 'src/app/helper/helper-class';
import { CmsService } from 'src/app/services/cms/cms.service';
import { UserService } from 'src/app/services/user/user.service';
import { MustMatch } from 'src/app/_helpers/must-match.validator';
@Component({
  selector: 'app-patient-sign-up',
  templateUrl: './patient-sign-up.component.html',
  styleUrls: ['./patient-sign-up.component.scss']
})
export class PatientSignUpComponent implements OnInit {
  frm = new FormData();
  errMsg: string = "";
  photoUrl: string = "../../../assets/images/Profile-Default-icon.png";
  photoUrlObj: any;
  @ViewChild("fileInput") fileInput: any;
  successMsg: string = "";
  signUpForm: FormGroup = this._fb.group({});
  aboutPatientForm: FormGroup = this._fb.group({});
  personalDetailsForm: FormGroup = this._fb.group({});
  lifeStyleDetailsForm: FormGroup = this._fb.group({});
  redirectTo: any;
  doctorLoginLink: boolean = false;
  habbitsFilled: boolean = false;
  habbitsSkipped: boolean = false;
  activeStep: Number = 1;
  t_and_cChecked = false;
  userSignUpData: any = {
    full_name: "",
    email:"",
    mobile:"",
    password:"",
    fiscal_number:"",
    health_number:"",
    dob:"",
    gender:"Male",
    height:"",
    weight:"",
    user_cat:"1",
    country_code:"",
    status:"1",
    photo:"",
    photo_url:"",
    blood_group: "",
    smoking_habits: "Don’t smoke",
    drinking_habits:"Non Drinker",
    physical_activity:"Low",
    diet:"Veg",
    step:"First"
  };
  emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";

  SmokingHabits: any = [];
  DrinkingHabits: any = []; 
  PhysicalActivity: any = [];
  Diet: any = [];   
  selectedSmokingHabits: String = "";
  selectedDrinkingHabits: String = "";
  selectedPhysicalActivity: String = "";
  selectedDiet: String = "";
  myUserId: any;
  myJsonToken: any;
  myData: any;
  habitMaster: any = [];
  
  constructor(
    private TitleService: Title,
    private _fb: FormBuilder,
    private UserService: UserService,
    private cmsService: CmsService,
    private router: Router,
    private Activatedroute: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.TitleService.setTitle("OMA HEalth : Patient Login");
    this.redirectTo = <String>this.Activatedroute.snapshot.queryParamMap.get('url');
    this.getHabitMaster(); 
    // this.getPatientLifestyleDetails();
    this.createForm();
    this.createFormAboutPatient();
    this.createPersonalDetailsForm();
    if (!this.redirectTo) {
      this.redirectTo = "/patient/personal-details";
    }
  }
  createForm() {

    this.signUpForm = this._fb.group({
      email_id: new FormControl('', [Validators.required, Validators.pattern(this.emailPattern)]),
      password: new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(20)]),
      confirmPassword: new FormControl('', [Validators.required]),
      t_and_c: new FormControl('', [Validators.required])
      
    }, {
      validator: MustMatch('password', 'confirmPassword')
    });
  }
  createFormAboutPatient() {

    this.aboutPatientForm = this._fb.group({
      full_name: new FormControl('', [Validators.required]),
      fiscal_number: new FormControl('', [Validators.required]),
      health_number: new FormControl('', [Validators.required]),
      dob: new FormControl('', [Validators.required]),
      phone_no: new FormControl('', [Validators.required, Validators.pattern("^[0-9]*$")]),
      gender: new FormControl('', [Validators.required])
    });
  }

  createPersonalDetailsForm(){
    this.personalDetailsForm = this._fb.group({
      height: new FormControl('', [Validators.required, Validators.pattern("^[0-9]*$")]),
      weight: new FormControl('', [Validators.required, Validators.pattern("^[0-9]*$")])
    });
  }
  // convenience getter for easy access to form fields
  get f() { return this.signUpForm.controls; }
  async getHabitMaster(){    
    await this.cmsService.getHabitMaster().subscribe((res: any) => {
      this.habitMaster = res['habits'];   
      this.filterSmokingHabitsByCategory();   
    });
  }
  async filterSmokingHabitsByCategory(){
    let cat = "Smoking Habits";
    for(let habit of this.habitMaster){
      if(habit.category == cat){
        this.SmokingHabits.push({
          habit_id: habit.habit_id,
          habit_name: habit.habit_name
        });
      }else if(habit.category == "Drinking Habits"){
        
        this.DrinkingHabits.push({
          habit_id: habit.habit_id,
          habit_name: habit.habit_name
        });
      }else if(habit.category == "Physical Activity"){
        
        this.PhysicalActivity.push({
          habit_id: habit.habit_id,
          habit_name: habit.habit_name
        });
      }else if(habit.category == "Diet"){
        
        this.Diet.push({
          habit_id: habit.habit_id,
          habit_name: habit.habit_name
        });
      }
    }
}
  async getPatientLifestyleDetails(){
    let id: any = localStorage.getItem('pid');
    await this.UserService.getPatientLifestyleDetails(id).subscribe((res: any) => {
      // console.log(res['data'][0]);
      if(res['status']){
        this.myData = res['data'][0];
        this.selectedSmokingHabits = this.myData.smoking_habits;
        this.selectedDrinkingHabits = this.myData.drinking_habits;
        this.selectedPhysicalActivity = this.myData.physical_activity;
        this.selectedDiet = this.myData.diet;
      }else{
        if(res['message']=="Invalid token"){
          console.log("Session expired..");
          this.router.navigate(['/login']);
        }else{
          console.log("Error while fething user data...");
        }
      }
    })
  }
  onSelectSmokingHabits(selected: String){
    this.selectedSmokingHabits = selected;
    this.isHabitsFilled();
  }
  onSelectDrinkingHabits(selected: String){
    this.selectedDrinkingHabits = selected;
    this.isHabitsFilled();
  }
  onSelectPhysicalActivity(selected: String){
    this.selectedPhysicalActivity = selected;
    this.isHabitsFilled();
  }
  onSelectDiet(selected: String){
    this.selectedDiet = selected;
    this.isHabitsFilled();
  }
  isHabitsFilled(){
    this.habbitsFilled = false;
    if(this.selectedSmokingHabits && this.selectedDrinkingHabits && this.selectedPhysicalActivity && this.selectedDiet){
      this.habbitsFilled = true;
    }
  }
  onChecktAndc(completed: boolean){
    this.t_and_cChecked = completed;
  }
  signUpFormSubmit() {
    this.errMsg = "";
    this.successMsg = "";
    if (this.signUpForm.valid) {
      let data = {
        email: this.signUpForm.controls['email_id'].value,
        password: this.signUpForm.controls['password'].value
      }
      if(!this.t_and_cChecked){
        this.errMsg = "Please check terms and conditions.";
        return;
      }else{
        this.userSignUpData.email = this.signUpForm.controls['email_id'].value;
        this.userSignUpData.password = this.signUpForm.controls['password'].value;
        if(this.frm.has("email")){
          this.frm.set("email", this.signUpForm.controls['email_id'].value);
          this.frm.set("password", this.signUpForm.controls['password'].value);
        }else{
          this.frm.append("email", this.signUpForm.controls['email_id'].value);
          this.frm.append("password", this.signUpForm.controls['password'].value);
        }
        
        this.frm.append("step", "First");
        
        this.UserService.patientSignUp(this.frm).subscribe((res: any) => {
          // console.log(res);  
          if (res['status']==0) {
            // const err = res['errors'];
            // err.forEach((ele: any) => {
            //   if (ele['param'] == "email") {
            //     const matchingControl = this.signUpForm.controls['email_id'];
            //     matchingControl.setErrors({ emailExist: true, message: ele['msg'] });
            //   }

            // });
            const matchingControl = this.signUpForm.controls['email_id'];
                matchingControl.setErrors({ emailExist: true, message: res['message'] });
          }
          else if(res['status']){
            let udata = res['data'];
            // console.log(udata);
            // this.successMsg = "Login successful.";
            this.activeStep = 2;
          }else {
            this.errMsg = res['message'];
          }
        });
      }
    } else {
      return;
    }
    
    return;
    
  }
  aboutPatientFormSubmit() {
    this.errMsg = "";
    this.successMsg = "";
    if (this.aboutPatientForm.valid) {
      this.userSignUpData.full_name = this.aboutPatientForm.controls['full_name'].value;
      this.userSignUpData.fiscal_number = this.aboutPatientForm.controls['fiscal_number'].value;
      this.userSignUpData.health_number = this.aboutPatientForm.controls['health_number'].value;
      this.userSignUpData.mobile = this.aboutPatientForm.controls['phone_no'].value;
      this.userSignUpData.dob = this.aboutPatientForm.controls['dob'].value;
      this.userSignUpData.gender = this.aboutPatientForm.controls['gender'].value;
      this.userSignUpData.step = "Third";
      //this.activeStep = 3;
      this.frm.append("full_name", this.aboutPatientForm.controls['full_name'].value);
      this.frm.append("fiscal_number", this.aboutPatientForm.controls['fiscal_number'].value);
      this.frm.append("health_number", this.aboutPatientForm.controls['health_number'].value);
      this.frm.append("mobile", this.aboutPatientForm.controls['phone_no'].value);
      this.frm.append("dob", this.aboutPatientForm.controls['dob'].value);
      this.frm.append("gender", this.aboutPatientForm.controls['gender'].value);
      
      this.frm.set("step", "Third");
      this.UserService.patientSignUp(this.frm).subscribe((res: any) => {
        // console.log(res);  
        if (!res['status']) {
          //const err = res['errors'];
          // err.forEach((ele: any) => {
          //   if (ele['param'] == "mobile") {
          //     const matchingControl = this.aboutPatientForm.controls['phone_no'];
          //     matchingControl.setErrors({ mobileExist: true, message: ele['msg'] });
          //   }

          // });
          //const matchingControl = this.aboutPatientForm.controls['phone_no'];
          //matchingControl.setErrors({ mobileExist: true, message: res['message'] });
        }
        else if(res['status']){          
          if(res['message']){
            // this.errMsg = res['message'];
          }          
          this.activeStep = 3;
        }else {
          this.errMsg = res['message'];
        }
      });
    }else{
      return;
    }
    
    
  }
  personalDetailsFormSubmit(){
    this.errMsg = "";
    this.successMsg = "";
    if (this.personalDetailsForm.valid) {
      this.userSignUpData.height = this.personalDetailsForm.controls['height'].value;
      this.userSignUpData.weight = this.personalDetailsForm.controls['weight'].value;
      this.userSignUpData.step = "Forth";

      this.frm.append("height", this.personalDetailsForm.controls['height'].value);
      this.frm.append("weight", this.personalDetailsForm.controls['weight'].value);
      this.frm.set("step", "Forth");

      //this.activeStep = 3;
      this.UserService.patientSignUp(this.frm).subscribe((res: any) => {
        // console.log(res);  
        if (!res['status']) {
          console.log("Error: while submitting personal details form.");
        }
        else if(res['status']){          
          if(res['message']){
            this.successMsg = res['message'];
          }          
          this.activeStep = 4;
        }else {
          this.errMsg = res['message'];
        }
      });
    }else{
      return;
    }
  }

  lifeStyleDetailsFormSubmit(){
    this.errMsg = "";
    this.successMsg = "";
    if (this.habbitsFilled) {
      this.userSignUpData.smoking_habits = this.selectedSmokingHabits;
      this.userSignUpData.drinking_habits = this.selectedDrinkingHabits;
      this.userSignUpData.physical_activity = this.selectedPhysicalActivity;
      this.userSignUpData.diet = this.selectedDiet;
      this.userSignUpData.step = "Final";
      //this.activeStep = 3;
      this.frm.append("photo", this.photoUrlObj);
      this.frm.append("smoking_habits", this.userSignUpData.smoking_habits);
      this.frm.append("drinking_habits", this.userSignUpData.drinking_habits);
      this.frm.append("physical_activity", this.userSignUpData.physical_activity);
      this.frm.append("diet", this.userSignUpData.diet);
      this.frm.set("step", "Final");

      this.UserService.patientSignUp(this.frm).subscribe((res: any) => {
        // console.log(res);  
        if(res['status']){          
          if(res['message']){
            this.successMsg = res['message'];
          }          
          this.activeStep = 5;
        }else {
          this.errMsg = res['message'];
        }
      });
    }else{
      return;
    }
  }
  loginFormSubmit1() {
    this.doctorLoginLink = false;
    if (this.signUpForm.valid) {
      let data = {
        email: this.signUpForm.controls['email'].value,
        password: this.signUpForm.controls['password'].value
      }
      this.errMsg = "";
      this.successMsg = "";
      this.UserService.login(data).subscribe((res: any) => {
        // console.log(res);  
        if (res['status']) {
          let udata = res['data'];
          // console.log(udata);
          this.successMsg = "Login successful.";
          if (udata.id > 1) {
            // localStorage.setItem('webtoken', res['token']);     
            // Doctor Login       
            if (udata.user_category_id == "2") {
              // localStorage.setItem('uid', udata.id);          
              // localStorage.setItem('user_cat_id', udata.user_category_id);
              // localStorage.setItem('full_name', udata.full_name);
              // localStorage.setItem('photo', udata.photo);

              // const helper = new HelperClass();
              // // 1 min = 60000
              // helper.setWithExpiry('token', res['token'], 3600000);

              // this.router.navigate(['doctor']);
              this.successMsg = "";
              this.doctorLoginLink = true;
              this.errMsg = "You are registered as an Doctor.";
              return;
              console.log("Please login from doctor UI.");
            } else if (udata.user_category_id == "3") {
              localStorage.setItem('pid', udata.id);
              localStorage.setItem('puser_cat_id', udata.user_category_id);
              localStorage.setItem('pfull_name', udata.full_name);

              const helper = new HelperClass();
              helper.setWithExpiry('ptoken', res['token'], 3600000);

              this.router.navigate([this.redirectTo]);
              // this.router.navigateByUrl(this.redirectTo);
            }


          } else {
            // localStorage.setItem('webtoken', res['token']);
            localStorage.setItem('uid', udata.id);
            localStorage.setItem('user_cat_id', udata.user_category_id);
            localStorage.setItem('full_name', udata.full_name);
            localStorage.setItem('photo', udata.photo);

            this.router.navigate(['admin']);
            const helper = new HelperClass();
            helper.setWithExpiry('token', res['token'], 3600000);
          }


        } else {
          this.errMsg = res['message'];
          return;
        }
      })
    } else {
      return;
    }
  }

  selectPhoto(){
    const userPhotoFile = window.document.getElementById("userPhotoFile");
    if(userPhotoFile!==null)
      userPhotoFile.click();
  }
  onSelectUserPhoto(event: any){
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]); // read file as data url
      this.photoUrlObj = event.target.files[0];
      reader.onload = (event: any) => { // called once readAsDataURL is completed
        this.photoUrl = event.target.result;
      }

    }
  }

}
