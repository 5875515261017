<div class="dialog-booking-confirmed" fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="20px">
    <div>
        <img src="../../../../assets/images/oma-booking-confirmed-img.png" alt="">
    </div>
    <div class="oma-card-header">Your Booking has been confirmed.</div>
    <div fxLayout="row" fxLayoutGap="8px">
        <div fxLayout="row" fxLayoutGap="10px">
            <div class="oma-booking-confirmed-icon">
                <img src="../../../../assets/images/OMA-Calendar-Icon-Blue.png" alt="">
            </div>
            <span style="padding-top: 5px">{{selectedDrAndTimeSlotInfo._date + ' ' + selectedDrAndTimeSlotInfo.timeslot |  date: "EEEE, d MMMM"}}</span>
        </div>
        <div fxLayout="row" fxLayoutGap="10px">
            <div class="oma-booking-confirmed-icon">
                <img src="../../../../assets/images/OMA-time-icon.png" alt="">
            </div>
            <span style="padding-top: 5px">{{selectedDrAndTimeSlotInfo._date + ' ' + selectedDrAndTimeSlotInfo.timeslot |  date: "hh:mm a"}}</span>
        </div>
        
    </div>
    <div fxLayout="column" fxLayoutAlign="center start">
        <br>
        <br>
        <button mat-raised-button (click)="onClickBackToHome()"  class="oma-btn-primary oma-btn">Back To Home</button>
        
    </div>
    
</div>