import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { DoctorService } from 'src/app/services/doctor/doctor.service';
import { UserService } from 'src/app/services/user/user.service';
import { MustMatch } from 'src/app/_helpers/must-match.validator';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  errMsg: string = "";
  successMsg: string = "";
  sucessfull:boolean=false;
  forgotForm: FormGroup = this._fb.group({});
  userObj: any; 
  emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";
  selectedDrId:any;
  user_category_id:any;
  dataReset: any = {
    uid: "",
    new_password:""
  }
  constructor( private TitleService: Title,
    private _fb: FormBuilder,
    
    private UserService: UserService,
    private router: Router,
    private Activatedroute: ActivatedRoute,
    public DoctorService: DoctorService) { }

  ngOnInit(): void {
    this.selectedDrId = this.Activatedroute.snapshot.paramMap.get('id');
    this.createForm();

    this.initilizePersonalDetValue();
  }

  initilizePersonalDetValue()
  {
   
    this.DoctorService.getMyData(this.selectedDrId).subscribe((res: any) => {
      this.userObj = res['data'][0];
      this.user_category_id=this.userObj['user_category_id'];

     this.forgotForm.patchValue({
      email: this.userObj['email_id']
     });
       
      });
  }
  createForm()
  {
    this.forgotForm = this._fb.group({
      email: new FormControl('', [Validators.required, Validators.pattern(this.emailPattern)]),
      password: new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(20)]),
      confirmPassword: new FormControl('', [Validators.required]),
    }, {
      validator: MustMatch('password', 'confirmPassword')
    });
  }
  forgotFormSubmit(){ 
    
    if(this.forgotForm.valid){      
      let data = {
        email: this.forgotForm.controls['email'].value
      }
      this.errMsg = "";
      this.successMsg = "";
      this.dataReset.uid = this.selectedDrId;
      this.dataReset.new_password = this.forgotForm.controls['password'].value;
      this.UserService.DrPetientReset(this.dataReset).subscribe( (res: any) => {
         console.log(res);  
        if(res['status']){
         
          this.successMsg = res['message'];
          this.sucessfull=true;
         // this.router.navigate(['']);
        }else{
          this.errMsg = res['message'];
          return;
        }
      })
    }else{
      this.errMsg = "Please check entered password";
      return;
    }    
  }
}
