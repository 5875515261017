<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer class="sidenav" fixedInViewport="false" 
      [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
      [mode]="(isHandset$ | async) ? 'over' : 'side'"
      [opened]="(isHandset$ | async) === false">
    <mat-toolbar class="admin-logo-text">OMA Admin</mat-toolbar>
    <mat-nav-list>
      <a mat-list-item [routerLink]="[ '/admin' ]" class="admin-sidebar-menu"><mat-icon>dashboard</mat-icon> Dashboard</a>
      <a mat-list-item [routerLink]="[ '/admin/users' ]" routerLinkActive="active-nav" class="admin-sidebar-menu"><mat-icon>supervised_user_circle</mat-icon> Doctors</a>
      <a mat-list-item [routerLink]="[ '/admin/patients' ]" routerLinkActive="active-nav" class="admin-sidebar-menu"><mat-icon>supervised_user_circle</mat-icon> Patients</a>
      <!-- <a mat-list-item [routerLink]="[ '/admin/org' ]" routerLinkActive="active-nav">Organization</a> -->
      <!-- <a mat-list-item [routerLink]="[ '/admin/clinic' ]" routerLinkActive="active-nav" class="admin-sidebar-menu"><mat-icon>local_hospital</mat-icon> Clinic's</a> -->
      <a mat-list-item [routerLink]="[ '/admin/appointments' ]" routerLinkActive="active-nav" class="admin-sidebar-menu"><mat-icon>date_range</mat-icon> Appointments</a>
      <a mat-list-item class="admin-sidebar-menu" [matMenuTriggerFor]="masterMenu"><mat-icon>settings</mat-icon> Master</a>
      
      <mat-menu #masterMenu="matMenu">
        <button mat-menu-item [routerLink]="[ '/admin/master/speciality' ]" routerLinkActive="active-nav"  >Speciality</button>        
      </mat-menu>
      <a mat-list-item [routerLink]="[ '/admin/logout' ]" routerLinkActive="active-nav" class="admin-sidebar-menu"><mat-icon>logout</mat-icon> Logout</a>
    
    <!-- <mat-menu #animals="matMenu">
      <button mat-menu-item [matMenuTriggerFor]="vertebrates">Vertebrates</button>
      <button mat-menu-item>Invertebrates</button>
    </mat-menu>
    <mat-menu #vertebrates="matMenu">
      <button mat-menu-item >Fishes</button>
      <button mat-menu-item>Amphibians</button>
      <button mat-menu-item >Reptiles</button>
      <button mat-menu-item>Birds</button>
      <button mat-menu-item>Mammals</button>
    </mat-menu> -->
    <!-- https://stackblitz.com/edit/material-sidenav-example?file=app%2Fsidenav-autosize-example.html -->
  </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar color="primary" fxLayout="row" fxLayoutAlign="space-between center" >
      <div >
      <button
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer.toggle()"
        *ngIf="isHandset$ | async">
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      <span><img src="../../assets/images/oma-web-logo.png" class="logo"> </span>
    </div>
    <div fxLayoutAlign="end">
      <a mat-icon-button><mat-icon>notifications</mat-icon></a>
      <a mat-icon-button [routerLink]="[ '/admin/logout' ]"><mat-icon>logout</mat-icon></a>
    </div>
    </mat-toolbar>
    <!-- Add Content Here -->
    <ng-content></ng-content>
  </mat-sidenav-content>
</mat-sidenav-container>
