<mat-toolbar class="toolbar-wrapper" fxLayoutAlign="space-between center" onload="onHeaderLoad()">
    <span fxFlex=20 [routerLink]="['']" class="logo"><img ngStyle.md="width: 180px; height: 50px;" src="../../../assets/images/oma-web-logo.png" ></span>
    <!-- <span class="example-spacer"></span> -->
    <div fxFlex fxHide fxShow.gt-md >
        <div fxLayout="row"  fxLayoutAlign="space-between center">
            <div class="toolbar-main-menu" fxLayoutGap="20px" >        
                <button class="toolbar-menu" mat-button color="primary" [routerLink]="[ '/virtual-consultation']">Bookings</button>
                <button class="toolbar-menu" mat-button color="primary" [routerLink]="[ '#' ]">Health Journal</button>
                <button class="toolbar-menu" mat-button color="primary" [routerLink]="[ '/articles' ]">Oma Community</button>
            </div>
            
            <div fxLayout="row" fxLayoutGap="20px">
                
            </div>
        </div>            
    </div>
    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px">
        <button *ngIf="!myUserId" class="toolbar-menu" mat-button color="primary" [routerLink]="[ 'doctor-login' ]">I’m a Doctor</button>
        <button *ngIf="!myUserId" class="toolbar-menu" mat-button color="primary" [routerLink]="[ 'login' ]">Sign up / Log in</button>
        <button *ngIf="myUserId" mat-icon-button class="toolbar-menu profile-photo"
            [routerLink]="[ '/patient/personal-details' ]">
            <img src="../../../assets/images/oma-patient-avator-medium.png" alt="">
        </button>
        <!--  -->
        <!-- fxHide.gt-sm -->
        <!-- <button mat-icon-button [routerLink]="['patient/personal-details']" class="header-menu-btn"><mat-icon class="menu-icon">menu</mat-icon></button> -->
        <button mat-icon-button class="header-menu-btn"
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="openSideNav()"
        >
        <mat-icon class="menu-icon">menu</mat-icon></button>
    </div>
</mat-toolbar>
<mat-sidenav-container class="sidenav-container">
    <mat-sidenav #drawer class="sidenav cms-sidebar" fixedInViewport="false" 
        [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
        [mode]="(isHandset$ | async) ? 'over' : 'over'"
        position="end"
        >
        <mat-nav-list class="list-item">
            <a mat-list-item [routerLink]="[ '/patient/payment-details' ]" class="admin-sidebar-menu" routerLinkActive="doctor-active-link"  [routerLinkActiveOptions]="{exact: true}"
                (click)="drawer.toggle()">
                <img class="list-icon" src="../../../assets/images/OMA-wallet-icon.png"
                > Payment Details </a>
            
            <a mat-list-item [routerLink]="[ '/patient/settings' ]" class="admin-sidebar-menu" routerLinkActive="doctor-active-link"  [routerLinkActiveOptions]="{exact: true}"
                (click)="drawer.toggle()">
                <img class="list-icon" src="../../../assets/images/oma-settings-icon.png"
                > Settings </a>
            
            <a mat-list-item [routerLink]="[ '/patient/my-favourites-dr' ]" class="admin-sidebar-menu" routerLinkActive="doctor-active-link"  [routerLinkActiveOptions]="{exact: true}"
                (click)="drawer.toggle()">
                <img class="list-icon" src="../../../assets/images/OMA-favourites-icon.png"
                > My Favourites </a>
        
            <a mat-list-item [routerLink]="[ '/patient/support' ]" class="admin-sidebar-menu" routerLinkActive="doctor-active-link"  [routerLinkActiveOptions]="{exact: true}"
                (click)="drawer.toggle()">
                <img class="list-icon" src="../../../assets/images/OMA-support-icon.png"
                > Support </a>
        
                <a mat-list-item [routerLink]="[ '/patient/refer-friend' ]" class="admin-sidebar-menu" routerLinkActive="doctor-active-link"  [routerLinkActiveOptions]="{exact: true}"
                (click)="drawer.toggle()">
                <img class="list-icon" src="../../../assets/images/oma-send-icon.png"
                > Refer to a friend </a>
                
                <a mat-list-item [routerLink]="[ '/patient/rating-and-feedback' ]" class="admin-sidebar-menu" routerLinkActive="doctor-active-link"  [routerLinkActiveOptions]="{exact: true}"
                (click)="drawer.toggle()">
                <img class="list-icon" src="../../../assets/images/OMA-raiting-star-icon.png"
                > Rating and Feedback </a>
        
                <a mat-list-item [routerLink]="[ '/patient/privacy-policy' ]" class="admin-sidebar-menu" routerLinkActive="doctor-active-link"  [routerLinkActiveOptions]="{exact: true}"
                (click)="drawer.toggle()">
                <img class="list-icon" src="../../../assets/images/OMA-Shield-Done.png"
                > Privacy Policy </a>
        
                <a mat-list-item [routerLink]="[ '/patient/terms-and-conditions' ]" class="admin-sidebar-menu" routerLinkActive="doctor-active-link"  [routerLinkActiveOptions]="{exact: true}"
                (click)="drawer.toggle()">
                <img class="list-icon" src="../../../assets/images/OMA-t_and_c_icon.png"
                > T&C </a>
                
                <a mat-list-item [routerLink]="[ '/patient/logout' ]" class="admin-sidebar-menu" routerLinkActive="doctor-active-link"  [routerLinkActiveOptions]="{exact: true}"
                (click)="drawer.toggle()">
                <img class="list-icon" src="../../../assets/images/OMA-logout-icon.png"
                > Log Out </a>
          </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content>
        
        <div class="page-content">
            <ng-content></ng-content>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>


