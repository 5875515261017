import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from '../config.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  url: string = "";
  myJsonToken: any;
  constructor(
    private config: ConfigService,
    private http: HttpClient
  ) {
    this.url = config.getApiUrl();
    const jsontoken: any =  localStorage.getItem('token');
    this.myJsonToken = JSON.parse(jsontoken);
  }

  getHeader(){
    if(localStorage.getItem('ptoken')){
      let jsonptoken: any =  localStorage.getItem('ptoken');
      let token = JSON.parse(jsonptoken);
      let header = new HttpHeaders().set(
        "authorization",
        "Bearer "+ token.value
      );
      return header;
    }else{
      let header = new HttpHeaders().set(
        "authorization",
        "Bearer "
      );
      return header;
    }
    
  }

  login(data: any) {
    return this.http.post(this.url + 'auth/user', data);
  }
  DrLogin(data: any){
    return this.http.post(this.url + 'auth/dr-login', data);
  }

  sendOtp(data: any) {
    return this.http.post(this.url + 'auth/verifyMobileNo', data);
  }

  getUser(id: number) {
    return this.http.get(this.url + 'user/' + id);
  }
  getUsers() {
    return this.http.get(this.url + 'user');
  }
  getUserCatWiseCount() {
    return this.http.get(this.url + 'user/CatWiseCount');
  }

  createUser(data: any) {
    return this.http.post(this.url + 'user/create', data);
  }
  // patient sign up
  patientSignUp(data: any) {
    return this.http.post(this.url + 'user/signup', data);
  }
  doctorSignUp(data: any) {
    return this.http.post(this.url + 'user/signup_dr', data);
  }
  doctorSignUpCheckMailId(data: any) {
    return this.http.post(this.url + 'user/checkUserExistByEmail', data);
  }
  doctorSignUpCheckUserName(data: any) {
    return this.http.post(this.url + 'user/checkUserExistByUsername', data);
  }
  update(data: any) {
    return this.http.put(this.url + 'user/' + data.id, data);
  }
  
  updateUserPhoto(data: any) {
    return this.http.post(this.url + 'user/updatePhoto/' + data.id, data);
  }

  cancelAppointment(data: any){
    return this.http.post(this.url + 'cms/cancel_appointment/' , data);
  }

  upload(fileToUpload: any) {
    let input = new FormData();
    input.append("file", fileToUpload);

    return this.http.post(this.url + "user/upload", input);
  }
  //  PATIENT API's
  getPatientInfo(id: number) {
    return this.http.get(this.url + 'patient/' + id, {headers: this.getHeader()});
  }
  
  updatePatientInfo(data: any) {
    return this.http.put(this.url + 'patient/personal', data, {headers: this.getHeader()});
  }

  // add new family member
  addNewFamilyMember(data: any) {
    return this.http.post(this.url + 'patient/addNewFamilyMember', data, {headers: this.getHeader()});
  }

  changePassword(data: any) {
    return this.http.post(this.url + 'patient/changePassword', data, {headers: this.getHeader()});
  }

  getDefaultSettings(id: Number) {
    return this.http.get(this.url + 'patient/getDefaultSettings/'+id, {headers: this.getHeader()});
  }
  updateDefaultSettings(data: any) {
    return this.http.post(this.url + 'patient/updateDefaultSettings', data, {headers: this.getHeader()});
  }

  // Lifestyle Details
  getPatientLifestyleDetails(id: number) {  
    return this.http.get(this.url + 'patient/lifestyle_details/' + id, {headers: this.getHeader()});
  }
  getLifestyleData(id: number) {  
    return this.http.get(this.url + 'patient/lifestyle_master/', {headers: this.getHeader()});
  }

  updatePatientLifestyleDetails(data: any) {
    return this.http.post(this.url + 'patient/lifestyle_details', data, {headers: this.getHeader()});
  }

   // GET Insurance Details
   getPatientInsuranceDetails(id: number) {  
    return this.http.get(this.url + 'patient/insurance_details/' + id, {headers: this.getHeader()});
  }
  // GET Insurance company lists
  getInsuranceCompanyLists() {  
    return this.http.get(this.url + 'patient/insurance/company_list', {headers: this.getHeader()});
  }
  
  getHabitMaster(){
    return this.http.get(this.url + 'patient/habits_master/0', {headers: this.getHeader()});
  }
  
  // get_session_data
  get_session_data(id: any) {  
    return this.http.get(this.url + 'patient/get_session_data/' + id, {headers: this.getHeader()});
  }

  // add / update Insurance details
  addPatientInsuranceDetails(data: any) {
    return this.http.post(this.url + 'patient/insurance_details', data, {headers: this.getHeader()});
  }
  //
  updatePatientInsuranceDetails(data: any) {
    return this.http.put(this.url + 'patient/insurance_details', data, {headers: this.getHeader()});
  }
  DrPetientForgot(data: any){
    return this.http.post(this.url + 'auth/resetPassword', data);
  }
  DrPetientReset(data: any){
    return this.http.post(this.url + 'auth/resetPasswordUpdate', data);
  }
  
}
