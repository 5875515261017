<div fxLayout="row" fxLayoutAlign="center center" class="main-wrapper">
        <mat-card class="box">
            <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
                <img src="../../../assets/images/oma-logo-lg.PNG" width="200px">
            </div>
            <mat-card-header fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">            
                <mat-card-title class="">Login</mat-card-title>
            </mat-card-header>
        
            <form class="example-form" [formGroup]="loginForm" (ngSubmit)="loginFormSubmit()" fxLayout="column" fxLayoutAlign="center center" >
              <mat-card-content fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
                <mat-form-field class="example-full-width">
                  <input matInput placeholder="Email or Mobile" formControlName="email" required>
                </mat-form-field>
        
                <mat-form-field class="example-full-width">
                  <input matInput placeholder="Password" type="password" formControlName="password" required>
                </mat-form-field>

                <div *ngIf="errMsg" class="error-message">
                    {{errMsg}}
                </div>
                <div *ngIf="successMsg" class="success-message">{{successMsg}}</div>

                <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="30px">
                    <button mat-raised-button type="submit" color="primary" class="btn-block">Login</button>
                    <p>New user?<a [routerLink]="[ '/signup',  ]" mat-raised-button color="accent" >Create Your Account</a></p>
                </div>
              </mat-card-content>
              
            </form>
          </mat-card>
</div>