import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { HelperClass } from 'src/app/helper/helper-class';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-patient-login',
  templateUrl: './patient-login.component.html',
  styleUrls: ['./patient-login.component.scss']
})
export class PatientLoginComponent implements OnInit {
  errMsg: string = "";
  successMsg: string = "";
  loginForm: FormGroup = this._fb.group({});
  redirectTo: any;
  doctorLoginLink: boolean = false;
  constructor(
    private TitleService: Title,
    private _fb: FormBuilder,
    private UserService: UserService,
    private router: Router,
    private Activatedroute: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.TitleService.setTitle("OMA HEalth : Patient Login");
     this.redirectTo = <String> this.Activatedroute.snapshot.queryParamMap.get('url');
    this.createForm();
    if(!this.redirectTo){
      this.redirectTo = "/patient/personal-details";
    }
  }

  createForm(){
    this.loginForm = this._fb.group({
      email: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required])
    });
  }

  loginFormSubmit(){
    this.doctorLoginLink = false;
    if(this.loginForm.valid){      
      let data = {
        email: this.loginForm.controls['email'].value,
        password: this.loginForm.controls['password'].value
      }
      this.errMsg = "";
      this.successMsg = "";
      this.UserService.login(data).subscribe( (res: any) => {
        // console.log(res);  
        if(res['status']){
          let udata = res['data'];
          // console.log(udata);
          this.successMsg = "Login successful.";
          if(udata.id>1){
            // localStorage.setItem('webtoken', res['token']);     
            // Doctor Login       
            if(udata.user_category_id=="2"){
              // localStorage.setItem('uid', udata.id);          
              // localStorage.setItem('user_cat_id', udata.user_category_id);
              // localStorage.setItem('full_name', udata.full_name);
              // localStorage.setItem('photo', udata.photo);
              
              // const helper = new HelperClass();
              // // 1 min = 60000
              // helper.setWithExpiry('token', res['token'], 3600000);
              
              // this.router.navigate(['doctor']);
              this.successMsg = "";
              this.doctorLoginLink = true;
              this.errMsg = "You are registered as an Doctor.";
              return;
              console.log("Please login from doctor UI.");
            }else if(udata.user_category_id=="3"){
              localStorage.setItem('pid', udata.id);          
              localStorage.setItem('puser_cat_id', udata.user_category_id);
              localStorage.setItem('pfull_name', udata.full_name);
              
              const helper = new HelperClass();
              helper.setWithExpiry('ptoken', res['token'], 3600000);

              this.router.navigate([this.redirectTo]);
              // this.router.navigateByUrl(this.redirectTo);
            }
            
            
          }else{
            // localStorage.setItem('webtoken', res['token']);
            localStorage.setItem('uid', udata.id);          
            localStorage.setItem('user_cat_id', udata.user_category_id);            
            localStorage.setItem('full_name', udata.full_name);
            localStorage.setItem('photo', udata.photo);
            
            this.router.navigate(['admin']);
            const helper = new HelperClass();
            helper.setWithExpiry('token', res['token'], 3600000);
          }
          
          
        }else{
          this.errMsg = res['message'];
          return;
        }
      })
    }else{
      return;
    }    
  }

}
