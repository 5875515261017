<mat-card fxLayout="column">
    <form [formGroup]="myData" fxLayout="column" (ngSubmit)="onSubmit()">
        <h1 mat-dialog-title>
            <mat-icon>person_add</mat-icon> Add User
        </h1>
        <div mat-dialog-content>
            <div class="input-row" fxLayout="column" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px" class="m-b-0">
                <mat-radio-group color="primary" aria-label="User Type" fxFlex formControlName="user_cat">
                    <mat-radio-button value="1" >Admin</mat-radio-button>
                    <mat-radio-button value="2"  class="m-l-10">Doctor</mat-radio-button>
                </mat-radio-group>
                <div fxLayout="row" fxLayoutAlign="space-between center" class="m-b-0">
                    <mat-form-field appearance="legacy" fxFlex class="m-b-0">
                        <mat-label>First Name</mat-label>
                        <input matInput placeholder="enter first name" formControlName="first_name">
                        <!-- <div *ngIf="submitted && f.first_name.errors" class="invalid-feedback">
                            <div *ngIf="f.first_name.errors.required">First Name is required</div>
                        </div> -->
                        <!-- <mat-error *ngIf="f.first_name.invalid">First name is required.</mat-error>                         -->
                        <mat-error *ngIf="myData.get('first_name')?.hasError('required')">First name id required.</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="legacy" fxFlex class="m-l-10 m-b-0">
                        <mat-label>Last Name</mat-label>
                        <input matInput placeholder="enter last name" formControlName="last_name">
                        <mat-error *ngIf="myData.get('last_name')?.hasError('required')">Last name is required.</mat-error>
                    </mat-form-field>
                </div>
                <mat-form-field appearance="legacy" fxFlex class="m-b-0">
                    <mat-label>Email Id</mat-label>
                    <input matInput placeholder="enter email id" formControlName="email_id">
                    <mat-error *ngIf="myData.get('email_id')?.hasError('pattern')">Invalid Email Id.</mat-error>
                    <mat-error *ngIf="myData.get('email_id')?.hasError('required')">Email Id is required.</mat-error>
                    <mat-error *ngIf="myData.get('email_id')?.hasError('emailExist')">Email Id already exist.</mat-error>
                </mat-form-field>

                <mat-form-field fxFlex>
                    <mat-label>Mobile No</mat-label>
                    <input matInput placeholder="enter mobile no" formControlName="mobile_no" maxlength="10">
                    <mat-error *ngIf="myData.get('mobile_no')?.hasError('required')">Mobile No is required.</mat-error>
                    <mat-error *ngIf="myData.get('mobile_no')?.hasError('minlength') || myData.get('mobile_no')?.hasError('maxlength')">Mobile No is should be 10 digits.</mat-error>
                    <mat-error *ngIf="myData.get('mobile_no')?.hasError('mobileExist')">Mobile No already exist.</mat-error>
                    
                    <!-- <mat-error *ngIf="myData.get('mobile_no')?.hasError('maxlength')">Invalid Mobile No.</mat-error> -->
                </mat-form-field>

                <mat-label>Gender</mat-label>
                <mat-radio-group aria-label="Gender" color="primary" fxFlex formControlName="gender">
                    <mat-radio-button value="Male" >Male</mat-radio-button>
                    <mat-radio-button value="Female"  class="m-l-10">Female</mat-radio-button>
                </mat-radio-group>

                
            <div fxLayout="row" fxLayoutAlign="space-between center">
                <mat-form-field fxFlex style="margin-bottom: 0px;">
                    <mat-label>Password</mat-label>
                    <input matInput type="password" placeholder="enter password" formControlName="password">
                    <mat-error *ngIf="myData.get('password')?.hasError('required')">Password is required.</mat-error>
                    <mat-error *ngIf="myData.get('password')?.hasError('minlength')">Password should be min 6 digits.</mat-error>
                </mat-form-field>
                <mat-form-field fxFlex class="m-l-10">
                    <mat-label>Confirm Password</mat-label>
                    <input matInput type="password" placeholder="enter password" formControlName="confirmPassword">
                    <mat-error *ngIf="myData.get('confirmPassword')?.hasError('required')">Confirm password is required.</mat-error>
                    <mat-error *ngIf="myData.get('confirmPassword')?.hasError('mustMatch')">Confirm password not matched.</mat-error>
                </mat-form-field>
            </div>
        </div>
        <p></p>
        </div>
        <div mat-dialog-actions>
            <button mat-raised-button type="submit" color="primary" [disabled]="myData.invalid">
                <mat-icon>add</mat-icon> Add
            </button>
            <button mat-raised-button mat-dialog-close>Cancel</button>
        </div>
        <!-- {{myData.get('mobile_no')?.hasError('required')
        ? 'Username Required'
        : myData.get('mobile_no')?.getError('minLength')
        }} -->
    </form>
</mat-card>