import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { ModalManagerService } from 'src/app/services/modal-manager.service';
import { Title } from '@angular/platform-browser';
import { UserService } from 'src/app/services/user/user.service';
import { MatTableDataSource } from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import { Router } from '@angular/router';

export interface UserElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}


@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

  dataSource = new MatTableDataSource<UserElement>();
  usersObj =  <any>[];
  displayedColumns: string[] = ['id','name', 'email', 'mobile', 'gender', 'category', 'active', 'add_to_partner','edit'];
  Doctors: string = "";
  Patients: string = "";
  @ViewChild(MatPaginator) paginator: any = MatPaginator;
  @ViewChild(MatSort) sort: any= MatSort;

  constructor(
    private TitleService: Title,
    private mms: ModalManagerService,
    private UserService: UserService,
    private router:Router
  ) { }

  ngOnInit() {
    this.TitleService.setTitle("OMA Users");
    this.getUsers();
    // this.dataSource.data =  this.usersObj;
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  AddNew(){
    this.mms.addUser({name: 'Rahul'}).subscribe(res => {
      this.getUsers();   
    })
  }

  EditUserModal(id: number){
    this.mms.editUser({id: id}).subscribe(res => {
      // console.log(res); 
      this.getUsers();       
    })
  }
  

  ViewUserModal(id: number){
    this.mms.viewUser({id: id}).subscribe(res => {
      // console.log(res); 
      this.getUsers();       
    })
  }

  addToPartnerModal(id: number){
    this.mms.addToPartnerModal({id: id}).subscribe(res => {
      // console.log(res); 
      this.getUsers();       
    })
  }
  getUsers(){
    this.UserService.getUsers().subscribe((res: any) => {
      const users = res['data'];
      this.usersObj = [];
      for(let user of users){
        if(user.user_category_id==2){
          this.usersObj.push(user);
        }
      }
      this.dataSource.data =  this.usersObj;
      // console.log(this.usersObj);
    });
    // this.UserService.getUserCatWiseCount().subscribe((res: any) => {
    //   // this.usersObj = res['data'];
    //   console.log(res);
    // });
    
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  userFilterCheck(type: String){
    let user_category_id = [];

    if(type==="Doctors"){
      
    }else if(type==="Doctors"){

    }
  }
  

}
