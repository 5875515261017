import { Router } from "@angular/router";
import { HelperClass } from "./helper-class";

export class AuthClass {

    first_name: string = "Manish";

    public getLoggedUserData(){
        let data = {
            first_name: this.first_name
        }
        return data;
    }

    constructor(private router: Router){

    }

    public checkTokenExpired(){
        const helper = new HelperClass();
        if(!helper.getWithExpiry('token')){
            this.router.navigate(['login']);
        }
    }

    public checkDoctorTokenExpired(){
        const helper = new HelperClass();
        if(!helper.getWithExpiry('drtoken')){
            this.router.navigate(['doctor-login']);
        }
    }

    public removeToken(key: string){
        localStorage.removeItem(key);
    }

    public logout(){
        localStorage.removeItem('token');
        localStorage.removeItem('uid');
        localStorage.removeItem('user_cat_id');
        localStorage.removeItem('user_name');
        localStorage.removeItem('photo');
        this.router.navigate(['admin-login']);
        
    }

    public drLogout(){
        localStorage.removeItem('token');
        localStorage.removeItem('uid');
        localStorage.removeItem('user_cat_id');
        localStorage.removeItem('user_name');
        localStorage.removeItem('photo');
        this.router.navigate(['doctor-login']);
    }

}
