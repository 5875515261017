import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SideNavComponent } from './side-nav/side-nav.component';
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DashboardComponent } from './admin/dashboard/dashboard.component';
import { OrganizationComponent } from './admin/organization/organization.component';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { AddDevisionComponent } from './admin/modal/add-devision/add-devision.component';
import { MatFormFieldControl, MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { HttpClientModule } from '@angular/common/http';
import { MatTableModule } from '@angular/material/table';
import { UsersComponent } from './admin/users/users.component';
import { AddUserComponent } from './admin/modal/add-user/add-user.component';
import { MatRadioModule } from '@angular/material/radio';
import { MatCardModule } from '@angular/material/card';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatPaginatorModule } from '@angular/material/paginator';
import { EditUserComponent } from './admin/modal/edit-user/edit-user.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSortModule } from '@angular/material/sort';
import {MatStepperModule} from '@angular/material/stepper';
import {MatDividerModule} from '@angular/material/divider';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { DoctorLayoutComponent } from './layouts/doctor-layout/doctor-layout.component';
import { LoginComponent } from './auth/login/login.component';
import { SignupComponent } from './auth/signup/signup.component';
import { CmsLayoutComponent } from './layouts/cms-layout/cms-layout.component';
import { HeaderComponent } from './cms/header/header.component';
import { MatSelectModule } from '@angular/material/select';
import { SidebarComponent } from './layouts/doctor-layout/sidebar/sidebar.component';
import {  MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {  MatMenuModule} from '@angular/material/menu';
import { NgxMatFileInputModule } from '@angular-material-components/file-input';
import { FooterComponent } from './cms/footer/footer.component';
import { VideoCallComponent } from './video-call/video-call.component';
import { JoinVideoCallComponent } from './join-video-call/join-video-call.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { AddDrToPartnerComponent } from './admin/modal/add-dr-to-partner/add-dr-to-partner.component';
import { DoctorLoginComponent } from './auth/doctor-login/doctor-login.component';
import { RightSideMenuComponent } from './cms/right-side-menu/right-side-menu.component';
import { PatientLoginComponent } from './auth/patient-login/patient-login.component';
import { PatientSignUpComponent } from './auth/patient-sign-up/patient-sign-up.component';
import { DoctorSignUpComponent } from './auth/doctor-sign-up/doctor-sign-up.component';
import { PublisherComponent } from './video-call/publisher/publisher.component';
import { SubscriberComponent } from './video-call/subscriber/subscriber.component';
import { VideoCallJoinComponent } from './video-call-join/video-call-join.component';
import { PublisherJoinComponent } from './video-call-join/publisher-join/publisher-join.component';
import { SubscriberJoinComponent } from './video-call-join/subscriber-join/subscriber-join.component';
import { DoctorForgotComponent } from './auth/doctor-forgot/doctor-forgot.component';
import { PatientForgotComponent } from './auth/patient-forgot/patient-forgot.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';


@NgModule({
  declarations: [
    AppComponent,
    SideNavComponent,
    DashboardComponent,
    OrganizationComponent,
    AddDevisionComponent,
    UsersComponent,
    AddUserComponent,
    EditUserComponent,
    AdminLayoutComponent,
    DoctorLayoutComponent,
    LoginComponent,
    SignupComponent,
    CmsLayoutComponent,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    VideoCallComponent,
    JoinVideoCallComponent, AddDrToPartnerComponent,DoctorLoginComponent,
    RightSideMenuComponent, PatientLoginComponent,
    PatientSignUpComponent,
    DoctorSignUpComponent,
    PublisherComponent,
    SubscriberComponent,
    VideoCallJoinComponent,
    PublisherJoinComponent, ResetPasswordComponent,
    SubscriberJoinComponent, DoctorForgotComponent, PatientForgotComponent,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule, 
    MatListModule, FormsModule, ReactiveFormsModule,
    BrowserAnimationsModule, RouterModule, FlexLayoutModule,
    MatDialogModule, MatFormFieldModule, MatInputModule,
    HttpClientModule, MatTableModule, MatRadioModule, MatCardModule,
    MatSnackBarModule, MatPaginatorModule, MatExpansionModule,
    MatSortModule, MatStepperModule, MatDividerModule,
    MatDatepickerModule, MatNativeDateModule, MatSlideToggleModule,MatCheckboxModule,
    MatSelectModule, MatProgressSpinnerModule, MatMenuModule, NgxMatFileInputModule
  ],
  exports: [
    HeaderComponent,
    FooterComponent
  ],
  providers: [Title,{provide: LocationStrategy, useClass: HashLocationStrategy}],
  bootstrap: [AppComponent]
})
export class AppModule { }
