import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import * as OT from '@opentok/client';
import config from '../../config';
import { CmsService } from './cms/cms.service';
import { ConfigService } from './config.service';

@Injectable({
  providedIn:'root'
})
export class OpentokService {

  session: OT.Session;
  token: string;
  apiKey: string;
  sessionId: string;
  constructor(
    private http: HttpClient, private config: ConfigService,
    private cmsService: CmsService
    ) { }

  getOT() {
    return OT;
  }

  initSession(bookingId: any) {
    // this.apiKey = this.stateService.apiKey$;
    // this.token = this.stateService.token$;
    // this.sessionId = this.stateService.sessionId$;
    

    this.cmsService.get_session_data(bookingId).subscribe((res: any)=>{
      var data = res['data'];
      console.log(data);
      this.apiKey = data.api_key;
      this.token = data.token;
      this.sessionId = data.session_id;
      if (this.apiKey && this.token && this.sessionId) {
        this.session = this.getOT().initSession(this.apiKey, this.sessionId);
        this.token = this.token;
        return Promise.resolve(this.session);
      } else {
        return fetch(config.SAMPLE_SERVER_BASE_URL + 'patient/get_session_data/'+bookingId)
          .then((data) => data.json())
          .then((json) => {
            console.log(json);
            this.session = this.getOT().initSession(json.apiKey, json.sessionId);
            this.token = json.token;
            return this.session;
          });
      }
    });

    

    

  }

  connect() {
    return new Promise((resolve, reject) => {
      this.session.connect(this.token, (err) => {
        if (err) {
          reject(err);
        } else {
          resolve(this.session);
        }
      });
    });
  }
}
