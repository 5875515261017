<div mat-dialog-title class="dialog-title">Appintment Cancellation Confirmation</div>
<div >
    <div mat-dialog-content class="modal-body">
        <p>Are you sure you want to cancel this appointment!</p>        
    </div>
    <div mat-dialog-actions fxLayout="row" fxLayoutGap="20px">
        <br>
        <button mat-raised-button class="oma-gray-btn" color="primary" (click)="onNoClick()">No Thanks</button>
        <button mat-raised-button class="oma-default-btn" color="primary" (click)="onSubmit()" cdkFocusInitial>
            Yes
        </button>
    </div>
</div>
