<div mat-dialog-title class="dialog-title">Add New Member</div>
<div [formGroup]="memberForm">
    <div mat-dialog-content class="modal-body">
        <div fxLayoutGap="15px" fxLayout="column">
            <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="15px">
                <div fxFlex fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="15px">
                    <div class="form-group" fxFlex>
                        <label for="nameInput">Name</label>
                        <input type="text" class="form-control" id="nameInput" formControlName="memberName" aria-describedby="nameInputHelp"
                            placeholder="Type Here" autocomplete="off">

                    </div>
                    <div class="form-group" fxFlex>
                        <label for="inputEmail">Age</label>
                        <input type="text" class="form-control" id="inputAge" formControlName="memberAge" aria-describedby="ageHelp"
                            placeholder="Type Here">
                    </div>
                </div>
            </div>
            <!--  -->
            <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="15px">
                <div fxFlex fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="15px">
                    <div class="form-group" fxFlex>
                        <label for="nameInput">Gender</label>
                        <mat-radio-group aria-label="Gender" color="primary" fxLayout="row"
                            fxLayoutGap="20px" formControlName="gender">
                            <mat-radio-button value="Male">Male</mat-radio-button>
                            <mat-radio-button value="Female" class="m-l-10">Female</mat-radio-button>
                        </mat-radio-group>

                    </div>
                    <div class="form-group" fxFlex>
                        <label for="inputEmail">DOB (YYYY/DD/MM)</label>
                        <input type="text" class="form-control" id="inputAge" formControlName="dob" aria-describedby="ageHelp"
                            placeholder="YYYY/DD/MM">
                    </div>
                </div>
            </div>
            <!--  -->
            <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="15px">
                <div fxFlex fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="15px">
                    <div class="form-group" fxFlex>
                        <label for="relationInput">Relation</label>
                        <select formControlName="openTimeHH" formControlName="relation" class="form-control" placeholder="Select Here">
                            <option value="">Select Relation</option>
                            <option *ngFor="let rel of ['Father','Mother','Spouse','Son','Daughter']">
                                {{rel}}</option>
                        </select>

                    </div>
                    <div class="form-group" fxFlex>
                        <label for="inputEmail">Photo</label>
                        <input type="file" accept='image/*' (change)="onSelectSignatureFile($event)" required>
                    <img [src]="imgURL" height="120" width="100" *ngIf="imgURL"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div mat-dialog-actions fxLayout="row" fxLayoutGap="20px">
        <br>
        <button mat-raised-button class="oma-gray-btn" color="primary" (click)="onNoClick()">No Thanks</button>
        <button mat-raised-button [disabled]="memberForm.invalid" class="oma-default-btn" color="primary" (click)="onSubmit()" cdkFocusInitial>Save
            Changes</button>
    </div>
</div>