import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DoctorService } from 'src/app/services/doctor/doctor.service';

@Component({
  selector: 'app-modal-update-profile',
  templateUrl: './modal-update-profile.component.html',
  styleUrls: ['./modal-update-profile.component.scss'],
  providers: [{
    provide: STEPPER_GLOBAL_OPTIONS, useValue: { showError: true }
  }]
})
export class ModalUpdateProfileComponent implements OnInit {
  photoUrl: any;
  userObj: any; 
  serverUrl: string = "";
  @ViewChild("fileInput") fileInput: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public reqData: any,
    public dialogRef: MatDialogRef<ModalUpdateProfileComponent>,
    private _formBuilder: FormBuilder,
    private doctorService: DoctorService,
  ) { }

  ngOnInit() {
    this.photoUrl = "../../../../assets/images/doctor-icon-avatar.jpg";
    this.getDoctorPersonalData();
  }
  async getDoctorPersonalData() {
    await this.doctorService.getMyData(this.reqData.myUserId).subscribe((res: any) => {
      
        this.userObj = res['data'][0];
        this.serverUrl = res['base_url'];
        this.photoUrl =  this.serverUrl +"api/user/files/"+ this.userObj.photo;
      
    });
  }

  onNoClick(): void {
    this.dialogRef.close({status: 0});
  }

  async onSubmit(){
    let fileName = "";
    let fi = this.fileInput.nativeElement;
    if (fi.files && fi.files[0]) {
      let fileToUpload = fi.files[0];
      await this.doctorService.updateProfilePhoto(this.reqData.myUserId, fileToUpload).subscribe((res: any) => {
        fileName = res.fileName;
        // console.log(res);
      })
      this.dialogRef.close({status: 1, fileName: fileName});
    }
  }

  onSelectFile(event: any) { // called each time file input changes
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      // this.selectedFile = (event.target.files[0]);
      reader.readAsDataURL(event.target.files[0]); // read file as data url

      reader.onload = (event: any) => { // called once readAsDataURL is completed
        this.photoUrl = event.target.result;
      }     
      

    }
  }

}
