<div mat-dialog-title class="dialog-title"><b>Add To MagniFinance Partner</b></div>
<div mat-dialog-content>
    <div class="add-to-partner">
        <div >
            <div class="tr-row" fxLayout="row">
                <div class="head">Company Legal Name: </div>
                <div class="data">
                    <input type="text"  [(ngModel)]="drData.CompanyLegalName">
                </div>
            </div>
            <!--  -->
            <div class="tr-row" fxLayout="row">
                <div class="head">User Email: </div>
                <div class="data">
                    <input type="text"  [(ngModel)]="drData.UserEmail">
                </div>
            </div>
            <!--  -->
            <div class="tr-row" fxLayout="row">
                <div class="head">User Name: </div>
                <div class="data">
                    <input type="text"  [(ngModel)]="drData.UserName">
                </div>
            </div>
            <!--  -->
            <div class="tr-row" fxLayout="row">
                <div class="head">Company Tax Id: </div>
                <div class="data" fxLayoutGap="20px" fxLayout="row">
                    <div>
                        <input type="text"  [(ngModel)]="drData.CompanyTaxId">
                        <div class="text-danger" *ngIf="partnerAccessTokenErrorMsg">{{partnerAccessTokenErrorMsg}}</div>
                    </div>
                    <!-- <div>
                        <button mat-raised-button color="primary" *ngIf="!loaderAccessToken" 
                        (click)="getPartnerAccessToken()">Get Partner Access Token</button>
                        <button mat-raised-button 
                        *ngIf="loaderAccessToken" fxLayout="row"><mat-spinner diameter="30"></mat-spinner></button>
                    </div> -->
                </div>
            </div>
            <!-- <div class="tr-row" fxLayout="row">
                <div class="head">Partner Access Token: </div>
                <div class="data">
                    <input type="text"  [(ngModel)]="drData.partnerAccessToken">
                    <div class="text-success" *ngIf="partnerAccessTokenLoadedFlag">Partner Acceess Token Is Verified.</div>
                </div>
            </div> -->
            
            <!--  -->
            <div class="tr-row" fxLayout="row">
                <div class="head">User Phone: </div>
                <div class="data">
                    <input type="text"  [(ngModel)]="drData.UserPhone">
                </div>
            </div>
            <!--  -->
            <div class="tr-row" fxLayout="row">
                <div class="head">Company Address: </div>
                <div class="data">
                    <input type="text"  [(ngModel)]="drData.CompanyAddress">
                </div>
            </div>
            <!--  -->
            <div class="tr-row" fxLayout="row">
                <div class="head text-danger" >Company City: </div>
                <div class="data">
                    <input type="text"  [(ngModel)]="drData.CompanyCity">
                </div>
            </div>
            <!--  -->
            <div class="tr-row" fxLayout="row">
                <div class="head">Company Postal Code: </div>
                <div class="data">
                    <input type="text"  [(ngModel)]="drData.CompanyPostCode">
                </div>
            </div>
            <!--  -->
            <div class="tr-row" fxLayout="row">
                <div class="head text-danger">Company Country: </div>
                <div class="data">
                    <input type="text"  [(ngModel)]="drData.CompanyCountry">
                </div>
            </div>
        </div>
    </div>
</div>
<div mat-dialog-actions>
    <div fxLayout="column">
        <div fxLayout="row">
            <button mat-raised-button *ngIf="loaderAddtoPartner">
                <mat-spinner diameter="30"></mat-spinner>
            </button>
            <button mat-raised-button type="submit" *ngIf="!loaderAddtoPartner"
            color="primary" (click)="onSubmitfrm()">
                Update
            </button>
            <button mat-raised-button color="primary" (click)="onNoClick()">Cancel</button>
        </div>
        <div>
            <div class="text-danger" *ngIf="addPartnerErrorMsg">{{addPartnerErrorMsg}}</div>
        </div>
    </div>
</div>