<div class="" fxLayout="row" style="height: 90vh;">
    <div fxFlex *ngIf="session" class="video-call-container">
        <app-subscriber-join class="subscriber" *ngFor="let stream of streams" [stream]="stream" [session]="session">
        </app-subscriber-join>
        <app-publisher-join class="publisher" [session]="session"></app-publisher-join>
    </div>
    <div fxFlex="30" class="chat-div">
        <h2 class="oma-page-title">Chat</h2>
    </div>

</div>