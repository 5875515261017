import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CmsService } from 'src/app/services/cms/cms.service';

@Component({
  selector: 'app-right-side-menu',
  templateUrl: './right-side-menu.component.html',
  styleUrls: ['./right-side-menu.component.scss']
})
export class RightSideMenuComponent implements OnInit {
    
  constructor(
    private cmsService: CmsService
    // @Inject(MAT_DIALOG_DATA) public reqData: any,
    // public dialogRef: MatDialogRef<RightSideMenuComponent>,
  ) { }

  ngOnInit() {

  }
  

  toggleSideNav(){
    this.cmsService.toggle();
  }

}
