<div class="dialog-booking-confirmed" fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="20px">
    <div>   
        <button mat-icon-button (click)="closeDialog()">
            <img src="../../../../assets/images/OMA-back-arrow.png" alt="">
        </button>
    </div>
    <div class="page-title">New Time Confirmed</div>   
    
    <div fxLayout="column" fxLayoutGap="15px" class="time-slot-div">
        <img src="../../../../assets/images/OMA-new-time-confirmed-image.png" alt="">
        
    </div>
    <div fxLayout="column"  class="dialog-bottom">
        <br><br>
        <button mat-raised-button (click)="closeDialog()" [routerLink]="['/doctor']" 
        class=" oma-btn btn-confirm-slot">Go to Dashboard</button>        
    </div>
    
</div>