<mat-sidenav-container class="sidenav-container">
    <mat-sidenav #drawer class="sidenav oma-sidenav" fixedInViewport="false" 
        [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
        [mode]="(isHandset$ | async) ? 'over' : 'side'"
        [opened]="(isHandset$ | async) === false">
      <mat-toolbar class="oma-toolbar" fxLayout="column" fxLayoutAlign="space-around center"><!--<span>Doctor</span>--></mat-toolbar>
      <mat-list class="oma-mat-list toolbar-user" fxLayout="column" fxLayoutAlign="space-around center" fxLayoutGap="20px">
        <mat-list-item >
            <img class="mat-avatar" src="../../../../assets/images/OMA-Logo-sm.png">            
        </mat-list-item>
        <!-- <mat-list-item class="oma-mat-list-item">
            <span>{{loggedUserName}}</span>
        </mat-list-item> -->
      </mat-list>
      <br>
      <mat-nav-list class="list-item">
        <a mat-list-item [routerLink]="[ '/doctor' ]" class="admin-sidebar-menu" routerLinkActive="doctor-active-link"  [routerLinkActiveOptions]="{exact: true}"><mat-icon>dashboard</mat-icon> Home</a>
        <a mat-list-item [routerLink]="[ '/doctor/appointments' ]" routerLinkActive="doctor-active-link" class="admin-sidebar-menu"><mat-icon>date_range</mat-icon> Appointments</a>
        <a mat-list-item [routerLink]="[ '/doctor/my-invoices' ]" routerLinkActive="doctor-active-link" class="admin-sidebar-menu"><mat-icon>article</mat-icon> My Invoices</a>
        <a mat-list-item [routerLink]="[ '/doctor/patient-list' ]" routerLinkActive="doctor-active-link" class="admin-sidebar-menu"><mat-icon>persons</mat-icon> Patient List</a>
        <a mat-list-item [routerLink]="[ '/doctor/clinic' ]" routerLinkActive="doctor-active-link" class="admin-sidebar-menu"><mat-icon>local_hospital</mat-icon> Clinic</a>
        <a mat-list-item [routerLink]="[ '/doctor/profile' ]" routerLinkActive="doctor-active-link" class="admin-sidebar-menu"><mat-icon>manage_accounts</mat-icon> Profile</a>                
        <a mat-list-item [routerLink]="[ '/doctor/settings' ]" routerLinkActive="doctor-active-link" class="admin-sidebar-menu"><mat-icon>settings</mat-icon> Settings</a>                
        <a mat-list-item [routerLink]="[ '/doctor/logout' ]" routerLinkActive="doctor-active-link" class="admin-sidebar-menu"><mat-icon>logout</mat-icon> Logout</a>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content>
      <mat-toolbar class="oma-toolbar-header" fxLayout="row" fxLayoutAlign="space-between center" >
        <div fxLayout="row">
        <button
          type="button"
          aria-label="Toggle sidenav"
          mat-icon-button
          (click)="drawer.toggle()"
          *ngIf="isHandset$ | async">
          <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
        </button>
        <!-- <span><img src="../../assets/images/oma-dark-logo.png" class="logo"> </span> -->
        <div class="search-bar" fxLayout="row" fxLayoutGap="20px" >
          <input type="text" name="search" class="form-control" placeholder="Search">
          <button class="btn-search" mat-raised-button>Search</button>
        </div>
      </div>
      <div class="oma-toolbar-right-menu" fxLayout="row" fxLayoutAlign="end" fxLayoutGap="20px">
        <a mat-icon-button><mat-icon>notifications</mat-icon></a>
        <div>
          <img  class="oma-toolbar-profile" [matMenuTriggerFor]="menu" src="{{user_photo}}">
          <!-- <button mat-mini-fab [matMenuTriggerFor]="menu" class="mat-fab"></button> -->
          <mat-menu #menu="matMenu">
            <button mat-menu-item>Item 1</button>
            <button mat-menu-item [routerLink]="[ '/doctor/logout' ]">Log Out</button>
          </mat-menu></div>
        <!-- <a mat-icon-button [routerLink]="[ '/doctor/logout' ]"><mat-icon>logout</mat-icon></a> -->
      </div>
      </mat-toolbar>
      <!-- Add Content Here -->
      <div class="page-content">
      <ng-content></ng-content>
    </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
  